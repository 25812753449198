import JSON from "circular-json";
import Const from "../const";
import {v4 as uuidv4} from "uuid";
//import * as Sentry from "../sentry.init";

export default class Analytics {
    constructor(vendorKey, widget) {
        let self = this;

        this.vendorKey = vendorKey;
        this.widget = widget;
        this.pageType = widget.getPageSource();

        this.statUrl = widget.statUrl;
        this.apiKey = widget.key;
        this.updateWidgetParams();
        this.alreadySendedArray = [];
    }

    updateWidgetParams() {
        let widgetPrams = localStorage.getItem(Const.widgetParamsKey);
        if (widgetPrams) {
            widgetPrams = JSON.parse(widgetPrams);
            this.isSendEvents = widgetPrams['send_events'] == true;
            this.isSendFullData = !(widgetPrams['send_full_data'] == false);
            this.isSendCartsData = !(widgetPrams['send_carts_data'] == false);
            this.isSendPurchasesData = !(widgetPrams['send_purchases_data'] == false);
            this.isSendViewProductEvents = widgetPrams['send_view_product_events'] == true;
            this.fetchField = widgetPrams['analytics_fetch_field'];
        }
    }

    sendEventClick(product_id, widget_source, page_source, price) {
        if (!product_id)
            return false;

        this.updateWidgetParams();

        let urlParams = new URLSearchParams(window.location.search);
        if (!widget_source)
            widget_source = urlParams.get('g_widget') || '';
        if (!page_source)
            page_source = urlParams.get('g_page') || '';

        let data = {
            'event_name': 'product_click',
            'target_id': product_id
        };

        if (widget_source)
            data['widget_source'] = widget_source;
        if (page_source)
            data['page_source'] = page_source;
        if (price)
            data['price'] = price;

        if (this.isSendFullData || widget_source)
            this.sendEvent(data);
    }

    sendEventWidgetClick(product_id, widget_source, page_source, look_id, price, old_price) {
        let data = {
            'event_name': 'widget_click',
            'target_id': product_id,
            'widget_source': widget_source,
            'page_source': page_source
        };

        if (look_id)
            data['look_id'] = look_id;
        if (price)
            data['price'] = price;
        if (old_price)
            data['old_price'] = old_price;

        this.sendEvent(data);
    }

    sendEventView(widget_source, page_source, looks, event_name) {
        if (this.isSendFullData) {
            if (looks) {
                for (let i = 0; i < looks.length; i++) {
                    let data = {};
                    data['event_name'] = event_name ? event_name : 'view_widget';
                    data['widget_source'] = widget_source;
                    data['page_source'] = page_source;
                    data['look_id'] = looks[i].look_id;
                    if (data['look_id'])
                        this.sendEvent(data);
                }
            } else {
                let data = {};
                data['event_name'] = event_name ? event_name : 'view_widget';
                data['widget_source'] = widget_source;
                data['page_source'] = page_source;
                this.sendEvent(data);
            }
        }
    }

    sendEventViewLooks(widget_source, page_source, looks) {
        if (this.isSendViewProductEvents) {
            let products = [];
            for (var i = 0; i < looks.length; i++) {
                for (var j = 0; j < looks[i].products.length; j++) {
                    products.push(looks[i].products[j]);
                }
            }

            this.sendEventViewProducts(widget_source, page_source, products)
        }
    }

    sendEventViewProducts(widget_source, page_source, products, look_id) {
        if (this.isSendViewProductEvents) {
            for (var i = 0; i < products.length; i++) {
                let data = {};
                data['event_name'] = 'view_product_in_widget';
                data['widget_source'] = widget_source;
                data['page_source'] = page_source;
                data['target_id'] = products[i].wareId;
                if (look_id)
                    data['look_id'] = look_id;
                data['price'] = products[i].price;
                if (products[i].old_price)
                    data['old_price'] = products[i].old_price;

                this.sendEvent(data);
            }
        }
    }

    sendEventAddToCartFromPopup(product_id, widget_source, page_source, price, old_price, look_id) {
        try {
            this.updateWidgetParams();

            let data = {
                'event_name': 'add_to_cart_from_popup',
                'target_id': product_id,
                'price': price,
                'widget_source': widget_source,
                'page_source': page_source
            };

            if (look_id)
                data['look_id'] = look_id;

            if (old_price)
                data['old_price'] = old_price;

            this.sendEvent(data);
        } catch (e) {
            console.error(e);
        }
    }

    sendEventAddToCart(yml_id) {
        this.updateWidgetParams();

        if (this.isSendCartsData) {
            let data = {}
            data['event_name'] = 'add_to_cart';
            if (this.fetchField) {
                data['fetch_field'] = this.fetchField;
                data['fetch_id'] = yml_id + '';
            } else
                data['yml_id'] = yml_id;
            this.sendEvent(data);
        }
    }

    sendEventAction(action, target_id, look_id, widget_source, page_source, price, old_price) {
        this.updateWidgetParams();

        let data = {}
        data['event_name'] = 'action_' + action;
        if (target_id)
            data['target_id'] = target_id;

        if (look_id)
            data['look_id'] = look_id;
        if (page_source)
            data['page_source'] = page_source;
        if (widget_source)
            data['widget_source'] = widget_source;
        if (price)
            data['price'] = price;
        if (old_price)
            data['old_price'] = old_price;

        this.sendEvent(data);
    }

    sendEventOpenPage(page_name) {
        let data = {}
        data['event_name'] = 'open_' + page_name;
        if (this.isSendFullData)
            this.sendEvent(data);
    }

    sendEventOpenCategory(category_id) {
        let data = {}
        data['event_name'] = 'open_category';
        data['target_id'] = category_id;
        if (this.isSendFullData)
            this.sendEvent(data);
    }

    sendEventPurchase(yml_id, transactionId) {
        this.updateWidgetParams();

        if (this.isSendPurchasesData) {
            let data = {};
            data['event_name'] = 'purchase';
            data['yml_id'] = yml_id + '';
            data['transaction_id'] = transactionId;
            this.sendEvent(data);
        }
    }

    sendEvent(data) {
        if (this.isSendEvents) {
            data['version'] = 7;
           // data['timestamp'] = Date.now();
            data['session_key'] = localStorage.getItem(Const.widgetSessionKey);
           // data['event_id'] = uuidv4().replace(/-/g, '');
            data['device_type'] = this.getDeviceType();
            if (this.widget.user_id)
                data['user_id'] = this.widget.user_id;
            data['stat_url'] = this.statUrl;
            data['api_key'] = this.apiKey;
            if (data['target_id'])
                data['target_id'] = data['target_id'] + '';

            if (data['yml_id']) {
                data['fetch_field'] = "yml_id";
                data['fetch_id'] = data['yml_id'] + '';
            }

            if (data['price'])
                data['price'] = data['price'] * 100;

            if (data['old_price'])
                data['old_price'] = data['old_price'] * 100;

            const urlParams = new URLSearchParams(window.location.search);
            const utmParams = ['utm_source', 'utm_medium', 'utm_campaign'];

            utmParams.forEach(param => {
                if (urlParams.has(param)) {
                    data[param] = urlParams.get(param);
                }
            });

            let formData = new URLSearchParams();
            for (var key in data) {
                formData.append(key, data[key]);
            }

            if (!data['event_name'].startsWith('action') && this.alreadySendedArray.some(item => JSON.stringify(item) === JSON.stringify(data)))
                return false;
            this.alreadySendedArray.push(data);

            var success = navigator.sendBeacon(data['stat_url'], formData);

            if (success) {
                console.log('Data successfully queued for background send.');
            } else {
                console.log('Data could not be queued for background send.');
            }
        }
    }

    getDeviceType() {
        const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        if (screenWidth < 768) {
            return 'Mobile';
        } else if (screenWidth >= 768 && screenWidth < 1024) {
            return 'Tablet';
        } else {
            return 'Desktop';
        }
    }
}