import {Texts} from "../const";
import ProductPopup from './product-popup.js';

class Collage {
    constructor(products, container, page, { useCustomTemplate, customCssClass, tuneProductSize, customTemplateCode, lookId, customTemplatePatterns }) {
        this.page = page;
        this.tuneProductSize = tuneProductSize;
        this.customTemplateCode = customTemplateCode;
        this.container = container;
        this.lookId = lookId;
        this.customTemplatePatterns = customTemplatePatterns;

        // =======================================================> for test
        // products = products.map((product) => {
        //     // if (product.category_group === 'layer-1') {
        //     //     product.category_group += '_vest';
        //     // }
        //     // if (product.category_group === 'bottom') {
        //     //     product.category_group += '_shorts';
        //     // }
        //     // if (product.category_group === 'shoes') {
        //     //     product.category_group += '_heels';
        //     // }
        //     if (product.is_main_product === 1) {
        //         product.category_group = 'scarf';
        //     }
        //     return product;
        // });
        //console.log('___initial', products.map((p) => p.category_group).join());

        if((customTemplateCode !== 'grid-platform') || (customTemplateCode !== 'grid-2-bottom') || (customTemplateCode !== 'grid-2') && (customTemplateCode !== 'grid')) {
            this.products = this.preprocessProducts(products);
        } else {
            this.products = products;
        }

        let template = '';
        this.productContainer = document.createElement('div');
        this.productContainer.classList.add('garderobo-widget-look-container');
        if (customTemplateCode !== 'grid-platform') {
            if ((customTemplateCode !== 'cards') && (customTemplateCode !== 'grid-2-bottom') && (customTemplateCode !== 'grid-2'))
                 template = this.findTemplate(this.products);
            if (customCssClass)
                this.productContainer.classList.add(customCssClass);
        }

        // для отрисовки не более одного предмета в одну позицию
        const acceptedProducts = {};

        if ((customTemplateCode !== 'cards') && (customTemplateCode !== 'grid-2-bottom') && (customTemplateCode !== 'grid-2') && (customTemplateCode !== 'grid') && (customTemplateCode !== 'grid-platform')) {
            /********** OLD VERSION ************/
            if (useCustomTemplate && template) {
                const templateId = template[0].id;
                this.productContainer.classList.add(`garderobo-widget-look-container--template-${templateId}`);
                this.products.forEach((product, i) => {
                    const splitCat = this.getSplitCat(product);
                    if (!acceptedProducts[splitCat]) {
                        this.drawProduct(product, Collage.classesMap[templateId], null, template, i);
                        acceptedProducts[splitCat] = true;
                    }
                });

                container.appendChild(this.productContainer);
                return this.productContainer;
            }

            // else {
            //     this.productContainer.classList.add(`garderobo-widget-look-container--template-default`);
            //     this.drawDefaultTemplate(this.products);
            // }

            return {};

        } else if ((customTemplateCode === 'grid-2') || (customTemplateCode === 'grid-2-bottom')) {
            this.drawCollageByGridType2(products);

            container.appendChild(this.productContainer);
            return this.productContainer;
        } else if (customTemplateCode == 'grid-platform') {
            this.drawCollageByGridTypePlatform(products);

            container.appendChild(this.productContainer);
            return this.productContainer;
        } else if (customTemplateCode === 'cards') {
            this.drawCollageByCards(products);

            container.appendChild(this.productContainer);
            return this.productContainer;
        } else {
            /********** NEW GRID VERSION ************/
            const categoriesList = [];
            let layersCount = 0;
            let sortedLayerNum = 0;
            let sortedLayerNumSecond = 0;
            const layersClasses = ["layer-top-left", "layer-middle-left", "layer-top-right"];
            const layersClassesSecond = ["layer-top-left", "layer-top-right"];
            const newLayersSorted = [];
            const mainProduct = template && template[0] && template[0].mainProduct;

            //this.productContainer.classList.add('garderobo-grid-look', `garderobo-grid-look--${templateId}`);
            this.productContainer.classList.add('garderobo-grid-look');

            let productsLayersName = this.products.map((product) => {
                if (product.category_group.includes("layer-") && !product.category_group.includes("layer-full") && !product.category_group.includes("0layer-")) {
                    product.category_group = '0' + product.category_group;
                }

                return product;
            });

            // console.log("this.products------", this.products);
            // productsLayersName.sort((a, b) => {
            //     let sorted = a.category_group < b.category_group ? -1 : 1;
            //     return sorted;
            // });

            //console.log("productsLayersName", productsLayersName);

            productsLayersName = productsLayersName.reverse();

            //console.log("productsLayersName-reverse", productsLayersName);

            products.forEach((product) => {
                if (product.category_group.includes("layer-") && !product.category_group.includes("layer-full")) {
                    newLayersSorted.push(product.category_group);
                    newLayersSorted.sort();
                }
            });

            productsLayersName.forEach((product) => {
                if (product.category_group.includes("layer-") && !product.category_group.includes("layer-full")) {
                    if (newLayersSorted.length === 3) {
                        categoriesList.push(layersClasses[sortedLayerNum]);
                        sortedLayerNum++;
                    }

                    if (newLayersSorted.length === 2 || newLayersSorted.length === 1) {
                        categoriesList.push(layersClassesSecond[sortedLayerNumSecond]);
                        sortedLayerNumSecond++;
                    }
                    layersCount++;
                } else {
                    categoriesList.push(product.category_group);
                }

            });

            if (layersCount === 0) {
                this.productContainer.classList.add('garderobo-grid-look__layer_count_0');
            }

            if (layersCount === 1) {
                this.productContainer.classList.add('garderobo-grid-look__layer_count_1');
            }

            if (layersCount === 2) {
                this.productContainer.classList.add('garderobo-grid-look__layer_count_2');
            }

            if (layersCount === 3) {
                this.productContainer.classList.add('garderobo-grid-look__layer_count_3');
            }

            productsLayersName.forEach((product, i) => {
                this.drawProductGrid(product, i, categoriesList, layersCount);
            });


            container.appendChild(this.productContainer);
            return this.productContainer;

            //return {};
        }
    }

    isCategoryInLook(products, category) {
        return products.find((product) => product.category_group === category) !== undefined;
    }

    drawCollageByGridType2Product(product, position, layerType, position_name = '', isMainProduct = false, template_name = '', isByLayout = 0) {
        let productImgWrap = document.createElement('div');
        let productImg = document.createElement('img');

        if (product.prev_picture) {
            productImg.src = product.prev_picture;
        } else
            productImg.src = product.picture;
        productImg.style.objectPosition = position.position;
        productImgWrap.appendChild(productImg);

        let productImgIcon = document.createElement('div');
        productImgIcon.classList.add('garderobo-grid-look__product-icon');
        productImgIcon.style.display = 'none';
        productImgWrap.appendChild(productImgIcon);

        let productImgIconPlus = document.createElement('div');
        productImgIconPlus.classList.add('garderobo-grid-look__product-icon-plus');
        productImgIconPlus.innerHTML = '+';
        productImgIcon.appendChild(productImgIconPlus);

        productImgIcon.addEventListener('click', (event) => {
            event.stopPropagation(); 
            
            const popup = new ProductPopup(product, this.page);
            popup.openPopup();
        })


        if (isByLayout) {
            productImgWrap.style.gridColumn = position.x;
            productImgWrap.style.gridRow =  position.y;
            productImgWrap.classList.add('garderobo-grid-look__product_img_with_positions');
        } else {
            productImgWrap.style.gridColumn = this.page.getUpdatedGridSize(product, position.x, layerType, isMainProduct, 'x', template_name);
            productImgWrap.style.gridRow = this.page.getUpdatedGridSize(product, position.y, layerType, isMainProduct, 'y', template_name);
        }
        productImgWrap.setAttribute('data-grid-row', position.y);
        productImgWrap.setAttribute('data-grid-column', position.x);
        productImgWrap.setAttribute('data-category-collage', product.category_group);
        productImgWrap.setAttribute('data-product-id', product.id);
        productImgWrap.setAttribute('data-position', position_name);
        productImgWrap.setAttribute('data-template', template_name);
        productImgWrap.setAttribute('data-product-type', layerType.category);
        if (product.isAlreadyInCollage)
            productImgWrap.setAttribute('data-already-in-collage', 1);

        if (layerType.subcategory)
            productImgWrap.setAttribute('data-product-subtype', layerType.subcategory);

        productImgWrap.style.zIndex = position.zindex;
        /*if (product.waist_length)
            productImgWrap.style.border = '1px solid #0f0';
        else
            productImgWrap.style.border = '1px solid #f00';*/
        //productImgWrap.style.backgroundColor = this.getRandomHexColor();
        productImgWrap.classList.add('garderobo-grid-look__product_img');

        if (product.prev_picture) {
            //let productImgDisabledLayout = document.createElement('div');
            //productImgDisabledLayout.classList.add('garderobo-grid-look__product-disabled-layout');
            //productImgWrap.appendChild(productImgDisabledLayout);

            productImgWrap.classList.add('garderobo-grid-look__product-disabled-layout');
            productImgWrap.setAttribute('data-message', this.page.getText(Texts.NOT_AVAILABLE));
        }

        this.productContainer.appendChild(productImgWrap);
    }

    drawCollageProductLabels(product, text, position) {
        let productTitleDiv = document.createElement('div');
        productTitleDiv.style.gridColumn = position.x;
        productTitleDiv.style.gridRow = position.y;
        //productTitleWrap.style.border = '1px solid #f00';
        productTitleDiv.style.display = 'flex';
        productTitleDiv.style.alignItems = 'center';
        productTitleDiv.style.justifyContent = 'center';

        let productTitleWrap = document.createElement('p');

        //productTitleWrap.style.fontSize = '0.8vw';
        productTitleWrap.style.margin = '0';
        productTitleWrap.innerHTML = text;
        productTitleWrap.setAttribute('data-product-id', product.id);

        productTitleDiv.appendChild(productTitleWrap);
        this.productContainer.appendChild(productTitleDiv);
    }

    getRandomHexColor() {
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    getCollageByGridType(products) {
        let topLayerCounter = 0;
        let mainProduct;
        let prevType;
        let mainProductCandidate;
        let result = {};
        let productLayerType;

        for (var i=(products.length - 1); i >= 0; i--) {
            let product = products[i];

            if (prevType == 'bottom') {
                mainProductCandidate = products[i];
            }

            productLayerType = this.page.getLayerType(product.category_group);
            prevType = productLayerType.category;
            let isCloth = (prevType == 'top') || (prevType == 'full') || (prevType == 'bottom');

            if (productLayerType.layer == 3) {
                if (productLayerType.subcategory != 'vest') {
                    if (product.crop_wh) {
                        if ((!product.is_main_product) && ((product.crop_wh[0] / product.crop_wh[1]) < 0.63)) {
                            result['additional'] = '_and_long_top';
                        }
                    }
                }
            }

            if (prevType == 'top')
                topLayerCounter += 1;
            if ((product.is_main_product && isCloth) || (isCloth && !mainProduct)) {
                mainProduct = product;
            }
        }

        let mainProductLayer = null;
        if (mainProduct) {
            mainProductLayer = this.page.getLayerType(mainProduct.category_group);
            if ((mainProductLayer.category != 'top') && (mainProductLayer.category != 'full') && (mainProductLayer.category != 'bottom')) {
                result['mainProduct'] = mainProductCandidate;
                mainProductLayer = this.page.getLayerType(mainProductCandidate.category_group);
            } else
                result['mainProduct'] = mainProduct;

            result['template'] = mainProductLayer.category;
        }

        if (this.customTemplatePatterns) {
            if (this.customTemplatePatterns[result['template']])
                result['template'] = this.customTemplatePatterns[result['template']];
        }

        result['topsCount'] = topLayerCounter;
        return result;
    }

    drawCollageByCardsItem(product, isMainItem) {
        let item = document.createElement('div');
        item.style.backgroundImage = `url('${product.picture}')`;
        item.setAttribute('data-product-id', product.id);
        item.classList.add('garderobo-widget-look-container-cards__item');
        if (isMainItem)
            item.classList.add('garderobo-widget-look-container-cards__main-item');

        let textWrapper = document.createElement('div');
        textWrapper.classList.add('garderobo-widget-look-container-cards__item__texts');

        let title = document.createElement('span');
        title.innerText = product.name;
        title.classList.add('garderobo-widget-look-container-cards__item__title');

        let price = document.createElement('span');
        if (product.old_price)
            price.innerHTML = '<span class="garderobo-widget-look-container-cards__item__price-new-price">' + this.page.priceFormatter(product.price) + '</span><span class="garderobo-widget-look-container-cards__item__price-old-price">' + this.page.priceFormatter(product.old_price) + '</span>';
        else
            price.innerText = this.page.priceFormatter(product.price);
        price.classList.add('garderobo-widget-look-container-cards__item__price');

        textWrapper.appendChild(title);
        textWrapper.appendChild(price);
        item.appendChild(textWrapper);

        return item;
    }

    drawCollageByCards(products) {
        let lookContainer = document.createElement('div');
        lookContainer.classList.add('garderobo-widget-look-container-cards');

        let lookContainerTopLine = document.createElement('div');
        lookContainerTopLine.classList.add('garderobo-widget-look-container-cards__top-line');

        let lookContainerBottomLine = document.createElement('div');
        lookContainerBottomLine.classList.add('garderobo-widget-look-container-cards__bottom-line');

        let bottomItemsCount = 0;

        for (let i=0; i < products.length; i++) {
            let product = products[i];

            let layerType = this.page.getLayerType(product.category_group);

            //if (product.is_main_product) {
            //    lookContainerTopLine.appendChild(this.drawCollageByCardsItem(product));
            //} else

            if (i == 0)
                lookContainerTopLine.appendChild(this.drawCollageByCardsItem(product, true));

            if (layerType.category == 'shoes') {
                bottomItemsCount += 1;
                lookContainerBottomLine.appendChild(this.drawCollageByCardsItem(product));
            }
        }

        let counter = 0;
        let lookContainerRightCol = document.createElement('div');
        lookContainerRightCol.classList.add('garderobo-widget-look-container-cards__right-col');

        for (let i=1; i < products.length; i++) {
            let product = products[i];

            let layerType = this.page.getLayerType(product.category_group);

            if (layerType.category != 'shoes') {
                counter += 1;
                if (counter <= 2)
                    lookContainerRightCol.appendChild(this.drawCollageByCardsItem(product));
                else {
                    lookContainerBottomLine.appendChild(this.drawCollageByCardsItem(product));
                    bottomItemsCount += 1;
                }
            }

            if (bottomItemsCount >= 3)
                break;
        }

        lookContainerTopLine.appendChild(lookContainerRightCol);
        lookContainer.appendChild(lookContainerTopLine);
        lookContainer.appendChild(lookContainerBottomLine);
        this.productContainer.appendChild(lookContainer);
    }

    drawCollageByGridType2(products) {
        let places = {
            "top": {
                "center": {"x": "14/30", "y": "10/26", "position": "center", "zindex": 3},
                "top": {"x": "26/41", "y": "1/18", "position": "left", "zindex": 2},
                "top_and_long_top": {"x": "4/18", "y": "1/18", "position": "right", "zindex": 2},
                "top_with_2_tops": {"x": "4/18", "y": "1/18", "position": "right", "zindex": 2},
                "top2": {"x": "4/18", "y": "1/18", "position": "right", "zindex": 2},
                "top2_and_long_top": {"x": "26/41", "y": "1/18", "position": "left", "zindex": 2},
                "full": {"x": "29/41", "y": "7/33", "position": "right", "zindex": 2},
                "full_with_2_tops": {"x": "29/41", "y": "15/41", "position": "right", "zindex": 2},
                "bottom": {"x": "26/38", "y": "21/41", "position": "left top", "zindex": 1},
                "shoes": {"x": "16/25", "y": "28/41", "position": "left bottom", "zindex": 4},
                "hat": {"x": "17/27", "y": "1/7", "position": "center top", "zindex": 2},
                "socks": {"x": "6/15", "y": "22/30", "position": "center bottom", "zindex": 2},
                "socks_and_long_top": {"x": "6/15", "y": "24/32", "position": "center bottom", "zindex": 2},
                "bag": {"x": "4/15", "y": "31/41", "position": "center bottom", "zindex": 4},
                "accessory": {"x": "8/14", "y": "14/22", "position": "center bottom", "zindex": 3},
                "accessory_and_long_top": {"x": "8/14", "y": "23/31", "position": "center bottom", "zindex": 3},
                //"accessory2": {"x": "2/8", "y": "12/20", "position": "center bottom", "zindex": 3},
                //"accessory2_and_long_top": {"x": "2/8", "y": "23/31", "position": "center bottom", "zindex": 3},
                "gloves": {"x": "1/8", "y": "18/26", "position": "center bottom", "zindex": 3},
                "accessory_head_with_2_tops": {"x": "11/17", "y": "4/11", "position": "center", "zindex": 3},
                "accessory_head": {"x": "1/6", "y": "1/7", "position": "center", "zindex": 3},
                "accessory_head_with_2_tops_and_long_top": {"x": "27/33", "y": "4/11", "position": "center", "zindex": 3},
                "accessory_head_with_1_tops": {"x": "11/17", "y": "4/11", "position": "center", "zindex": 3},
                "accessory_tie": {"x": "1/7", "y": "17/25", "position": "center", "zindex": 4},
                "accessory_tie_with_2_tops": {"x": "11/16", "y": "5/12", "position": "center", "zindex": 4},
                "accessory_tie_and_long_top": {"x": "1/7", "y": "21/29", "position": "center", "zindex": 4},
                "accessory_earring": {"x": "37/41", "y": "1/8", "position": "center top", "zindex": 3},
                "accessory_earring_with_1_tops": {"x": "30/34", "y": "8/14", "position": "center top", "zindex": 3},
                "belt": {"x": "34/41", "y": "19/23", "position": "center", "zindex": 3},
                "accessory_glasses": {"x": "1/7", "y": "19/25", "position": "center bottom", "zindex": 3},
                "accessory_watch": {"x": "35/41", "y": "24/30", "position": "center bottom", "zindex": 3},
            },
            "bottom_style_2": {
                "center": {"x": "15/25", "y": "19/41", "position": "center top", "zindex": 2},
                "top": {"x": "22/39", "y": "5/22", "position": "left", "zindex": 1},
                "top_with_3_tops": {"x": "23/41", "y": "5/22", "position": "left", "zindex": 1},
                "top2": {"x": "3/20", "y": "5/22", "position": "right", "zindex": 3},
                "top2_with_3_tops": {"x": "1/18", "y": "5/22", "position": "right", "zindex": 2},
                "top3_with_3_tops": {"x": "13/29", "y": "1/17", "position": "center top", "zindex": 1},
                "shoes": {"x": "4/13", "y": "28/41", "position": "left bottom", "zindex": 4},
                "hat": {"x": "17/27", "y": "1/6", "position": "center top", "zindex": 2},
                //"hat_with_1_tops": {"x": "27/37", "y": "1/6", "position": "center top", "zindex": 2},
                "hat_with_3_tops": {"x": "1/10", "y": "1/6", "position": "left top", "zindex": 3},
                "socks": {"x": "5/14", "y": "26/34", "position": "center bottom", "zindex": 2},
                "bag": {"x": "28/39", "y": "31/41", "position": "center bottom", "zindex": 3},
                "accessory": {"x": "9/16", "y": "19/26", "position": "center bottom", "zindex": 3},
                //"accessory2": {"x": "2/9", "y": "17/24", "position": "center bottom", "zindex": 3},
                "accessory_head": {"x": "1/6", "y": "2/9", "position": "center bottom", "zindex": 3},
                "accessory_head_with_1_tops": {"x": "16/22", "y": "2/9", "position": "center bottom", "zindex": 3},
                "accessory_glasses": {"x": "29/36", "y": "24/30", "position": "center bottom", "zindex": 3},
                "accessory_earring": {"x": "37/41", "y": "2/9", "position": "center bottom", "zindex": 3},
                "gloves": {"x": "1/8", "y": "18/26", "position": "center bottom", "zindex": 3},
                "accessory_tie": {"x": "1/7", "y": "19/27", "position": "center", "zindex": 4},
                "accessory_watch": {"x": "35/41", "y": "26/32", "position": "center bottom", "zindex": 5},
                "accessory_tie_with_2_tops": {"x": "1/7", "y": "1/7", "position": "center", "zindex": 4},
            },
            "bottom_style_3": {
                "center": {"x": "15/25", "y": "17/39", "position": "center top", "zindex": 2},
                "top": {"x": "22/39", "y": "5/22", "position": "left", "zindex": 1},
                "top_with_1_tops": {"x": "23/36", "y": "1/18", "position": "bottom", "zindex": 1},
                "top_with_2_tops": {"x": "23/41", "y": "1/18", "position": "left", "zindex": 1},
                "top_with_3_tops": {"x": "23/41", "y": "1/18", "position": "left", "zindex": 2},
                "top2": {"x": "3/20", "y": "5/22", "position": "right", "zindex": 3},
                "top2_with_3_tops": {"x": "1/18", "y": "5/22", "position": "right", "zindex": 3},
                "top3_with_3_tops": {"x": "13/29", "y": "1/17", "position": "center top", "zindex": 1},
                "shoes": {"x": "7/16", "y": "28/41", "position": "left bottom", "zindex": 4},
                "hat": {"x": "17/27", "y": "1/6", "position": "center top", "zindex": 2},
                "hat_with_1_tops": {"x": "14/24", "y": "1/6", "position": "center top", "zindex": 2},
                "hat_with_3_tops": {"x": "1/10", "y": "1/6", "position": "left top", "zindex": 3},
                "socks": {"x": "5/14", "y": "26/34", "position": "center bottom", "zindex": 2},
                "bag": {"x": "28/39", "y": "31/41", "position": "center bottom", "zindex": 3},
                "accessory": {"x": "9/16", "y": "19/26", "position": "center bottom", "zindex": 3},
                //"accessory2": {"x": "2/9", "y": "17/24", "position": "center bottom", "zindex": 3},
                "accessory_head": {"x": "1/6", "y": "2/9", "position": "center bottom", "zindex": 3},
                "accessory_head_with_1_tops": {"x": "16/22", "y": "2/9", "position": "center bottom", "zindex": 3},
                "accessory_glasses": {"x": "29/36", "y": "24/30", "position": "center bottom", "zindex": 3},
                "accessory_earring": {"x": "37/41", "y": "2/9", "position": "center bottom", "zindex": 3},
                "gloves": {"x": "1/8", "y": "18/26", "position": "center bottom", "zindex": 3},
                "accessory_tie": {"x": "1/7", "y": "19/27", "position": "center", "zindex": 4},
                "accessory_watch": {"x": "35/41", "y": "26/32", "position": "center bottom", "zindex": 5},
                "accessory_tie_with_2_tops": {"x": "1/7", "y": "1/7", "position": "center", "zindex": 4},
            },
            "bottom": {
                "center": {"x": "15/25", "y": "19/41", "position": "center top", "zindex": 1},
                "top": {"x": "22/39", "y": "4/21", "position": "left", "zindex": 1},
                "top_with_3_tops": {"x": "23/41", "y": "4/21", "position": "left", "zindex": 2},
                "top2": {"x": "2/19", "y": "4/21", "position": "right", "zindex": 1},
                "top2_with_3_tops": {"x": "1/18", "y": "4/21", "position": "right", "zindex": 2},
                "top3_with_3_tops": {"x": "13/29", "y": "1/17", "position": "center top", "zindex": 1},
                "shoes": {"x": "4/13", "y": "28/41", "position": "left bottom", "zindex": 4},
                "hat": {"x": "17/27", "y": "1/6", "position": "center top", "zindex": 2},
                //"hat_with_1_tops": {"x": "27/37", "y": "1/6", "position": "center top", "zindex": 2},
                "hat_with_3_tops": {"x": "1/10", "y": "1/6", "position": "left top", "zindex": 3},
                "socks": {"x": "5/14", "y": "26/34", "position": "center bottom", "zindex": 2},
                "bag": {"x": "28/39", "y": "31/41", "position": "center bottom", "zindex": 3},
                "accessory": {"x": "9/16", "y": "19/26", "position": "center bottom", "zindex": 3},
                //"accessory2": {"x": "2/9", "y": "17/24", "position": "center bottom", "zindex": 3},
                "accessory_head": {"x": "1/6", "y": "2/9", "position": "center bottom", "zindex": 3},
                "accessory_head_with_1_tops": {"x": "16/22", "y": "2/9", "position": "center bottom", "zindex": 3},
                "accessory_glasses": {"x": "29/36", "y": "24/30", "position": "center bottom", "zindex": 3},
                "accessory_earring": {"x": "37/41", "y": "2/9", "position": "center bottom", "zindex": 3},
                "gloves": {"x": "1/8", "y": "18/26", "position": "center bottom", "zindex": 3},
                "accessory_tie": {"x": "1/7", "y": "19/27", "position": "center", "zindex": 4},
                "accessory_watch": {"x": "35/41", "y": "26/32", "position": "center bottom", "zindex": 5},
                "accessory_tie_with_2_tops": {"x": "1/7", "y": "1/7", "position": "center", "zindex": 4},
            },
            "full": {
                "center": {"x": "14/30", "y": "10/38", "position": "center top", "zindex": 2},
                "top": {"x": "26/41", "y": "6/22", "position": "left top", "zindex": 1},
                "top2": {"x": "1/18", "y": "6/22", "position": "right", "zindex": 1},
                "top3": {"x": "14/30", "y": "1/17", "position": "center", "zindex": 1},
                "shoes": {"x": "6/15", "y": "28/41", "position": "left bottom", "zindex": 3},
                "hat": {"x": "17/27", "y": "1/6", "position": "center top", "zindex": 2},
                "socks": {"x": "4/13", "y": "26/34", "position": "center bottom", "zindex": 2},
                "bag": {"x": "29/40", "y": "31/41", "position": "center bottom", "zindex": 3},
                "accessory": {"x": "9/16", "y": "19/26", "position": "center bottom", "zindex": 3},
                //"accessory2": {"x": "2/9", "y": "17/24", "position": "center bottom", "zindex": 3},
                "accessory_head": {"x": "1/6", "y": "2/9", "position": "center bottom", "zindex": 3},
                "accessory_head_with_1_tops": {"x": "11/17", "y": "4/11", "position": "center", "zindex": 3},
                "accessory_head_with_0_tops": {"x": "11/17", "y": "4/11", "position": "center", "zindex": 3},
                "accessory_glasses": {"x": "29/36", "y": "24/30", "position": "center bottom", "zindex": 3},
                "accessory_earring": {"x": "37/41", "y": "2/9", "position": "center bottom", "zindex": 3},
                "gloves": {"x": "1/8", "y": "18/26", "position": "center bottom", "zindex": 3},
            }
        }

        this.productContainer.classList.add('garderobo-grid-look-2');
        let placeIndexes = {};

        let collageData = this.getCollageByGridType(products);
        if (collageData.mainProduct && (!collageData.mainProduct.layout || !collageData.mainProduct.layout.x)) {
            let layerType = this.page.getLayerType(collageData.mainProduct.category_group);
            this.drawCollageByGridType2Product(collageData.mainProduct, places[collageData.template]["center"], layerType, 'center', true, collageData.template);
        }

        if (products.length && products[0].layout && products[0].layout.name) {
            const lookName = document.createElement('div');
            lookName.classList.add('garderobo-widget-look__label-look-name');
            lookName.type = 'div';
            lookName.style.display = 'None';
            lookName.innerHTML = products[0].layout.name;
            this.container.appendChild(lookName);
        }

        if (products.length && products[0].layout && products[0].layout.link) {
            const lookLink = document.createElement('div');
            lookLink.classList.add('garderobo-widget-look__label-look-link');
            lookLink.type = 'div';
            lookLink.style.display = 'None';
            lookLink.innerHTML = products[0].layout.link;
            this.container.appendChild(lookLink);
        }

        for (var i = 0; i < products.length; i++) {
            let product = products[i];

            if (this.page.productsInCollage.includes(product.wareId)) {
                product.isAlreadyInCollage = true;
            } else if (!product.is_main_product)
                this.page.productsInCollage.push(product.wareId);

            product['category-name'] = product.category_group;
            let layerType = this.page.getLayerType(product.category_group);

            try {
                if (product.layout && product.layout.x) {
                    let position = {};
                    position.x = product.layout.x[0] + '/' + product.layout.x[1];
                    position.y = product.layout.y[0] + '/' + product.layout.y[1];
                    position.position = 'center';
                    position.zindex = product.layout.zindex ? product.layout.zindex : 1;

                    this.drawCollageByGridType2Product(products[i], position, layerType, '', 0, '', 1);

                    if (product.layout.captions) {
                        for (var j = 0; j < product.layout.captions.length; j++) {
                            position.x = product.layout.captions[j].x[0] + '/' + product.layout.captions[j].x[1];
                            position.y = product.layout.captions[j].y[0] + '/' + product.layout.captions[j].y[1];
                            this.drawCollageProductLabels(product, product.layout.captions[j].text, position);
                        }
                    }
                } else if (product != collageData.mainProduct) {
                    let currentIndex = placeIndexes[layerType.category];
                    if (currentIndex)
                        currentIndex += 1;
                    else
                        currentIndex = 1;

                    if ((layerType.category == 'top') || (!layerType.subcategory))
                        placeIndexes[layerType.category] = currentIndex;

                    let templateItemKey = layerType.category;
                    let templateItemIndex = '';
                    if (currentIndex > 1)
                        templateItemIndex = currentIndex;

                    let templatePossibleKeys = [
                        layerType.category + templateItemIndex + '_' + layerType.subcategory + '_with_' + collageData['topsCount'] + '_tops',
                        layerType.category + '_' + layerType.subcategory + '_with_' + collageData['topsCount'] + '_tops',
                        layerType.category + templateItemIndex + '_with_' + collageData['topsCount'] + '_tops',
                        layerType.category + templateItemIndex + '_' + layerType.subcategory,
                        templateItemKey + templateItemIndex
                    ]

                    for (var k=0; k < templatePossibleKeys.length; k++) {
                        let key = '';
                        if (places[collageData.template][templatePossibleKeys[k] + collageData['additional']])
                            key = templatePossibleKeys[k] + collageData['additional'];
                        else if (places[collageData.template][templatePossibleKeys[k]])
                            key = templatePossibleKeys[k];
                        if (key) {
                            this.drawCollageByGridType2Product(products[i], places[collageData.template][key], layerType, key, false, collageData.template);
                            break;
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    findCenterCoordinates(x1, y1, width, height, rotationDegrees) {
        var rotationRadians = rotationDegrees * Math.PI / 180;
        var centerX = x1 + (width / 2) * Math.cos(rotationRadians) - (height / 2) * Math.sin(rotationRadians);
        var centerY = y1 + (width / 2) * Math.sin(rotationRadians) + (height / 2) * Math.cos(rotationRadians);

        return { x: centerX, y: centerY };
    }

    drawCollagePlatformProduct(product) {
        let productImgWrap = document.createElement('div');
        let productImg = document.createElement('img');

        if (product.prev_picture) {
            productImg.src = product.prev_picture;
        } else {
            if (product.layout.imgUrl)
                productImg.src = product.layout.imgUrl;
            else
                productImg.src = product.picture;
        }
        productImgWrap.appendChild(productImg);
        productImgWrap.style.position = 'absolute';
        productImgWrap.classList.add('garderobo-grid-look__platform-product');
        productImgWrap.setAttribute('data-category-collage', product.category_group);
        productImgWrap.setAttribute('data-product-id', product.id);

        let sizeKoeff = 500 / product.layout.canvasWidth;

        productImgWrap.style.left = product.layout.left / 5 * sizeKoeff + '%';
        productImgWrap.style.top = product.layout.top / 5 * sizeKoeff + '%';
        productImgWrap.style.width = product.layout.pixelWidth / 5 * sizeKoeff + '%';
        productImgWrap.style.height = product.layout.pixelHeight / 5 * sizeKoeff + '%';
        productImgWrap.style.zIndex = product.layout.zIndex;
        if (product.layout.isShadow)
            productImg.style.filter = 'drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5))';

        if (product.layout.angle) {
            productImgWrap.style.transform = 'rotate(' + product.layout.angle + 'deg)';
            let coords = this.findCenterCoordinates(product.layout.left, product.layout.top, product.layout.pixelWidth, product.layout.pixelHeight, product.layout.angle);

            productImgWrap.style.left = (coords.x - product.layout.pixelWidth / 2) / 5 * sizeKoeff + '%';
            productImgWrap.style.top = (coords.y - product.layout.pixelHeight / 2) / 5 * sizeKoeff + '%';
        }

        if (product.layout.flipX && product.layout.flipY)
            productImgWrap.style.transform = 'scaleX(-1) scaleY(-1)';
        else {
            if (product.layout.flipX)
                productImgWrap.style.transform = 'scaleX(-1)';
            if (product.layout.flipY)
                productImgWrap.style.transform = 'scaleY(-1)';
        }

        if (product.prev_picture) {
            productImgWrap.classList.add('garderobo-grid-look__product-disabled-layout');
            productImgWrap.setAttribute('data-message', this.page.getText(Texts.NOT_AVAILABLE));
        }

        this.productContainer.appendChild(productImgWrap);
    }

    drawCollageByGridTypePlatform(products) {
        for (let i=0; i < products.length; i++) {
            this.drawCollagePlatformProduct(products[i]);
        }
    }

    preprocessProducts(_products) {
        // Иногда приходят несколько товаров layer-2, надо делать следующие замены. Вот возможные варианты:
        // 2, 2, 3 => 1, 2, 3
        // 1, 2, 2 => 1, 2, 3
        // 2, 2, 2 => 1, 2, 3
        // 2, 2 => 2, 3
        const products = [..._products];
        const productsLayer2 = products.filter((product) => product.category_group === 'layer-2');
        const renameLayer2 = (_productsLayer2, newName) => {
            for (let product of _productsLayer2) {
                if (product.is_main_product !== 1) {
                    product.category_group = newName;
                    return; // нужно переименовать только первый попавшийся
                }
            }
        };
        if (productsLayer2.length >= 2) {
            if (this.isCategoryInLook(products, 'layer-3')) {
                renameLayer2(productsLayer2, 'layer-1');
            } else if (this.isCategoryInLook(products, 'layer-1')) {
                renameLayer2(productsLayer2, 'layer-3');
            } else if (productsLayer2 === 3) {
                const notMainProducts = productsLayer2.filter((p) => p.is_main_product !== 1);
                notMainProducts[0].category_group = 'layer-1';
                notMainProducts[1].category_group = 'layer-3';
            } else {
                renameLayer2(productsLayer2, 'layer-3');
            }
        }

        return products;
    }

    isSubCategory(category) {
        return category.indexOf('_') !== -1;
    }

    drawProduct(product, classesMap, category, template, productIndex) {
        const _category = category || product.category_group;
        product['category-name'] = _category;

        if (template && !this.isProductInTemplate(product, template) && product.is_main_product !== 1) {
            return;
        }

        const mapClasses = classesMap && (classesMap[product.category_group] || classesMap[this.getSplitCat(product)]);
        const categories = mapClasses ? [...mapClasses] : [_category];
        if (mapClasses && this.isSubCategory(product.category_group)) {
            categories.push(_category);
        }

        let productImg = document.createElement('div');
        productImg.classList.add('garderobo-widget-look-product');
        categories.forEach((_cat) => productImg.classList.add(`garderobo-widget-look-product--${_cat}`));
        productImg.style['background-image'] = `url(${product.picture})`;
        productImg.setAttribute('data-category-collage', _category);

        this.page.setProductAttributes(productImg, product, productIndex);

        /**
         * 1) Используем только в случаях, когда в луке настроено "auto_scale_collages": 1
         * 2) Работаем только с товарами из групп: layer-1, layer-2, layer-3, layer-full, bottom
         * 3) Ширина-высота тебе будут приходить в разделе "products"[] -> "crop_wh": [100,200]
         */

        const mainProduct = template && template[0] && template[0].mainProduct;
        const mainProductPic = this.productContainer.querySelector('.garderobo-widget-look-product--center');

        if (
            this.tuneProductSize &&
            template &&
            mainProduct &&
            mainProduct.crop_wh &&
            // mainProduct != product &&
            product.crop_wh &&
            ['layer-1', 'layer-2', 'layer-3', 'layer-full', 'bottom'].some((layer) => product.category_group.includes(layer))
        ) {
            setTimeout(() =>
                this.setProductSizes(
                    productImg,
                    mainProduct,
                    product.category_group,
                    product.crop_wh[0],
                    product.crop_wh[1],
                    mainProduct.crop_wh[0],
                    mainProduct.crop_wh[1]
                )
            );
        }

        this.productContainer.appendChild(productImg);
    }
    /************ GRID DRAWW PRODUCT ************/
    drawProductGrid(product, productIndex, categoriesList, layersCount) {
        const _category = product.category_group;
        product['category-name'] = _category;

        let productImgWrap = document.createElement('div');
        let productImg = document.createElement('img');

        let mainProduct = product.is_main_product && 'garderobo-grid-look__main';

        //console.log("_category----------", _category);
        productImgWrap.classList.add('garderobo-grid-look__product_img', `garderobo-grid-look__${categoriesList[productIndex]}`, mainProduct);

        productImg.src = product.picture;
        productImgWrap.appendChild(productImg);
        productImgWrap.setAttribute('data-category-collage', _category);
        productImgWrap.setAttribute('data-product-id', product.id);

        this.page.setProductAttributes(productImgWrap, product, productIndex);

        this.productContainer.appendChild(productImgWrap);

        if (product.crop_wh) {
            this.setLeftRightImageScale(product.crop_wh, product.category_group, productImg, categoriesList[productIndex], layersCount);
        }
    }

    setLeftRightImageScale(image, productGroup, productImg, imageClass, layersCount) {

        if ((imageClass === 'layer-top-right') && (productGroup === '0layer-3')) {
            if (image[0] / image[1] < 0.62) {
                productImg.style.transform = 'scale(1.3)';
            } else if (image[0] / image[1] > 0.62 && image[0] / image[1] < 0.72) {
                productImg.style.transform = 'scale(1.2)';
            } else if (image[0] / image[1] > 0.95) {
                productImg.style.transform = 'scale(1.1)';
            }
        }

        if (layersCount === 1) {
            if ((imageClass === 'layer-top-left') && (productGroup === '0layer-3')) {
                if (image[0] / image[1] < 0.68) {
                    productImg.style.transform = 'scale(1.3)';
                } else if (image[0] / image[1] > 0.68 && image[0] / image[1] < 0.72) {
                    productImg.style.transform = 'scale(1.2)';
                } else if (image[0] / image[1] > 0.95) {
                    productImg.style.transform = 'scale(1.1)';
                }
            }
        }

        if (productGroup === 'gloves_head') {
            if (image[0] / image[1] > 2.4) {
                productImg.style.transform = 'scale(1.5)';
                productImg.style.objectPosition = '0 15px';
            }
        }

        if (productGroup === 'layer-full') {
            if (image[0] / image[1] < 0.6) {
                productImg.style.transform = 'scale(0.9)';
            }
        }

        if (productGroup === '0layer-1_top' && imageClass !== 'layer-middle-left') {
            if (image[0] / image[1] > 0.8) {
                productImg.style.transform = 'scale(0.9)';
            }
        }

        if (productGroup === '0layer-1' && imageClass === 'layer-middle-left') {
            if (image[0] / image[1] > 0.8) {
                productImg.style.transform = 'scale(0.8)';
            }
        }

        if (productGroup === '0layer-1' && imageClass !== 'layer-middle-left') {
            if (image[0] / image[1] > 0.88 && image[0] / image[1] < 1) {
                productImg.style.transform = 'scale(0.9)';
            }
        }

        if (productGroup === 'hat') {
            if (image[0] / image[1] < 0.65) {
                productImg.style.transform = 'scale(1.2)';
            }
        }

    }

    setProductSizes(productPic, mainProduct, fullCategory, realWidth, realHeight, mainProductRealWidth, mainProductRealHeight) {
        const lookId = productPic.closest('.garderobo-widget-feed-item').getAttribute('data-look');
        const mainProductPic = this.productContainer.querySelector('.garderobo-widget-look-product--center');

        if (!mainProductPic.offsetWidth) return;

        mainProductPic.category_group = mainProductPic.getAttribute('data-category-collage');

        if (mainProductPic.category_group.includes('bottom') || mainProductPic.category_group.includes('layer-full')) {
            for (let i = 0; i < this.products.length; i++) {
                console.log(this.products[i]);
                if (this.products[i].category_group.includes('bottom') || this.products[i].category_group.includes('layer-full')) {
                    mainProductRealWidth = this.products[i].crop_wh[0];
                    mainProductRealHeight = this.products[i].crop_wh[1];
                    console.log(mainProductRealWidth, mainProductRealHeight);
                    if (mainProductPic == productPic) {
                        console.log('main = product');
                        return;
                    }
                    break;
                }
            }
        }

        const scale = this.getScaleForProductSizeTune(lookId, mainProductPic, mainProductRealWidth, mainProductRealHeight, realWidth, realHeight, fullCategory);
        const mainProductPercentWidth = Math.round((100 * mainProductPic.offsetWidth) / this.productContainer.offsetWidth);
        const mainProductProportion = Math.round((mainProductRealWidth / mainProductPercentWidth) * 100);

        const percent = Math.round((100 * (realWidth * scale)) / mainProductProportion);

        productPic.setAttribute('data-mainProductPercentWidth', mainProductPercentWidth);
        productPic.setAttribute('data-mainProductProportion', mainProductProportion);
        productPic.setAttribute('data-realWidth', realWidth);
        productPic.setAttribute('data-realHeight', realHeight);
        productPic.setAttribute('data-percent', percent);
        productPic.setAttribute('data-fullCategory', fullCategory);
        productPic.setAttribute('data-mainProductRealWidth', mainProductRealWidth);
        productPic.setAttribute('data-mainProductRealHeight', mainProductRealHeight);

        const products = document.querySelectorAll('div[data-look="' + lookId + '"] .' + productPic.classList[1]);
        products.forEach((product) => {
            product.style.width = `${percent}%`;

            if (Array.from(product.classList).some((className) => className.includes('garderobo-widget-look-product--left-top'))) {
                product.style.left = 'auto';
                product.style.right = '55%';
                if (parseInt(product.style.width.replace('%', '')) > 45) {
                    product.style.width = '45%';
                }
            }

            if (Array.from(product.classList).some((className) => className.includes('garderobo-widget-look-product--right-top'))) {
                product.style.right = 'auto';
                product.style.left = '55%';
                if (parseInt(product.style.width.replace('%', '')) > 45) {
                    product.style.width = '45%';
                }
            }
        });
    }

    drawDefaultTemplate(products) {
        // маппинг кастомных категорий на дефолтные
        products = products.map((_p) => {
            switch (_p.category_group) {
                case 'layer-1':
                case 'layer-2':
                    _p.category_group = 'top';
                    break;
                case 'layer-3':
                case 'layer-3-vest':
                case 'layer-2-vest':
                    _p.category_group = 'vest';
                    break;
                case 'bag':
                case 'hat':
                    _p.category_group = 'accessory';
                    break;
                case 'layer-full':
                    _p.category_group = 'bottom';
                    break;
            }
            return _p;
        });

        let hasTop = false;
        let hasAccessory = false;
        let hasWear = products.find((product) => product.category_group === 'wear');

        const categories = {};

        const tops = [...products.filter((product) => product.category_group == 'top')];
        const wear = [...products.filter((product) => product.category_group == 'wear')];
        const others = [...products.filter((product) => product.category_group != 'top')].filter((product) => {
            if (categories[product.category_group]) return false;
            if (tops.length >= 2 && wear.length >= 1 && product.category_group === 'accessory') return false;

            categories[product.category_group] = product;
            return true;
        });

        const productsSorted = [...tops, ...others].slice(0, 5);

        productsSorted.forEach((product, i) => {
            // Для каждого товара в ответе теперь тебе прилетает category_group
            // могут быть top, bottom, wear, accessory, shoes

            // Расположение следующее:

            // правый верхний угол - wear
            // левый верхний угол - top
            // центр - bottom
            // правый низ - shoes
            // // левый низ - либо второй top (если есть), либо accessory - так раньше было
            // левй низ чисто под "accessory - Брать 1й аксессуар, который попадется, а если его нет - то ничего туда не выводить

            // + доп условие, если wear нет среди товаров, то в правый верхний угол идет второй top (если он есть)
            if (hasAccessory && product.category_group === 'accessory') {
                //отбрасываем все accessory кроме 1-го
                return;
            }

            const category = product.category_group === 'top' && hasTop ? (hasWear ? null : 'top-2-no-wear') : product.category_group;
            if (!category) {
                return;
            }

            this.drawProduct(product, null, category, null, i);

            if (product.category_group === 'top') {
                hasTop = true;
            }
            if (product.category_group === 'accessory') {
                hasAccessory = true;
            }
        });
    }

    getSplitCat(product) {
        return product.category_group.split('_')[0];
    }

    isProductInTemplate(product, template) {
        return template.find((category) => category === product.category_group || category === this.getSplitCat(product)) !== undefined;
    }

    getTemplatesByCategory(product) {
        if (!product) {
            return null;
        }
        const templates = Collage.allTemplates[product.category_group] || Collage.allTemplates[this.getSplitCat(product)];
        return templates && templates.length ? templates : null;
    }

    findMainProduct(products, mainProduct) {
        for (let cat of ['layer-full', 'layer-1', 'layer-2', 'layer-3']) {
            let suggestedMainProduct = products.find((product) => product.category_group === cat);
            if (suggestedMainProduct) {
                return this.setMainProductIfAppropriate(suggestedMainProduct, mainProduct);
            }
        }

        for (let product of products) {
            return this.setMainProductIfAppropriate(product);
        }

        return { mainProduct: null, templates: null };
    }

    setMainProductIfAppropriate(product, mainProduct) {
        const _templates = this.filterTemplatesByVendor(this.getTemplatesByCategory(product));

        if (_templates) {
            if (mainProduct) {
                delete mainProduct.is_main_product;
            }
            product.is_main_product = 1;
            return { mainProduct: product, templates: _templates };
        }

        return { mainProduct: null, templates: null };
    }

    filterTemplatesByVendor(templates) {
        if (!templates || !templates.length) {
            return templates;
        }

        if (!this.customTemplateCode) {
            return templates.filter((template) => !template[0].code);
        }

        return templates.filter((template) => !template[0].code || template[0].code === this.customTemplateCode);
    }

    findTemplate(_products) {
        // игнорируем accessory, т/к шаблонов с ними нет
        const products = _products.filter((p) => p.category_group !== 'accessory');

        let mainProduct = products.find((product) => product.is_main_product === 1);
        let templates = this.filterTemplatesByVendor(this.getTemplatesByCategory(mainProduct));

        if (!mainProduct || !templates) {
            const result = this.findMainProduct(products, mainProduct);
            mainProduct = result.mainProduct;
            templates = result.templates;
        }
        //console.log('mainProduct, templates', mainProduct, templates);

        if (!templates) {
            return null;
        }

        //let selectedTemplate = null;
        let selectedTemplates = [];

        const categories = products.reduce((result, product) => {
            if (product === mainProduct) {
                return result;
            }
            result[product.category_group] = true;
            return result;
        }, {});

        const allCategories = [];

        const justCategories = products.filter((p) => p.is_main_product !== 1).map((p) => p.category_group);
        const subCategories = justCategories.filter((cat) => this.isSubCategory(cat));
        const restCategories = justCategories.filter((cat) => !this.isSubCategory(cat));

        if (subCategories && subCategories.length) {
            const subCatSet = new Set();
            const mark = (mainItems, items) => {
                if (items.length === 0) {
                    subCatSet.add(mainItems.sort().join());
                    return;
                }
                for (let item of items) {
                    mark(
                        [...mainItems, item],
                        items.filter((_item) => _item !== item)
                    );
                    mark(
                        [...mainItems, item.split('_')[0]],
                        items.filter((_item) => _item !== item)
                    );
                }
            };
            mark([], subCategories);

            for (let item of subCatSet) {
                const newCats = [...restCategories, ...item.split(',')].reduce((result, cat) => {
                    if (cat === mainProduct.category_group) {
                        return result;
                    }
                    result[cat] = true;
                    return result;
                }, {});

                allCategories.push(newCats);
            }
        }

        if (!allCategories.length) {
            allCategories.push(categories);
        }

        // ищем полное вхождение шаблона в лук
        for (let categories of allCategories) {
            for (let template of templates) {
                const isTemplateFit = template.reduce((result, category) => {
                    if (typeof category !== 'string') {
                        return result;
                    }
                    return result && categories[category];
                }, true);

                if (isTemplateFit) {
                    const pointsFit = template.length - 1; // don't count id
                    // точное совпадение
                    if (pointsFit === Object.keys(categories).length) {
                        return template;
                    }
                    selectedTemplates.push({ template, pointsFit, pointsDontFit: Object.keys(categories).length - pointsFit }); // продуктов больше, чем позиций в шаблоне
                }
            }
        }

        //console.log('selectedTemplates', selectedTemplates);

        // ищем полное вхождение лука в шаблон
        for (let categories of allCategories) {
            for (let template of templates) {
                //console.log('test template______________' + template[0].id, template, 'for', Object.keys(categories));
                const isTemplateFit = Object.keys(categories).reduce((result, cat) => {
                    return result && template.find((category) => category === cat) !== undefined;
                }, true);

                if (isTemplateFit) {
                    const pointsFit = products.length - 1; // don't count main product
                    // точное совпадение
                    if (pointsFit === template.length - 1) {
                        return template;
                    }
                    selectedTemplates.push({ template, pointsFit, pointsDontFit: template.length - 1 - pointsFit }); // шаблон шире, чем кол-во продуктов
                }
            }
        }

        //console.log('selectedTemplates2', selectedTemplates);
        //console.log(categories, selectedTemplates);

        if (selectedTemplates.length) {
            selectedTemplates.sort((b, a) => {
                if (this.customTemplateCode) {
                    if (a.template[0].code === this.customTemplateCode) {
                        return 1;
                    }
                    if (b.template[0].code === this.customTemplateCode) {
                        return -1;
                    }
                }

                if (a.pointsFit > b.pointsFit) {
                    return 1;
                }
                if (a.pointsFit < b.pointsFit) {
                    return -1;
                }

                if (b.pointsDontFit > a.pointsDontFit) {
                    return 1;
                }
                if (b.pointsDontFit < a.pointsDontFit) {
                    return -1;
                }

                return 0;
            });
            //console.log('+++++++++++++++results+++++++++++', selectedTemplates);

            if (selectedTemplates[0].template) {
                selectedTemplates[0].template[0].mainProduct = mainProduct;
            }

            return selectedTemplates[0].template;
        }

        return null;
    }

    getScaleForProductSizeTune(lookId, mainProduct, mainProductWidth, mainProductHeight, width, height, fullCategory) {
        var aspect_ratio = width / height;
        var new_width = width;
        var new_height = height;
        if (aspect_ratio > 1) new_width = height * aspect_ratio;
        else new_height = width / aspect_ratio;

        if (mainProduct.category_group && mainProduct.category_group.includes('vest')) {
        } else if (mainProduct.category_group && (mainProduct.category_group.includes('layer-2') || mainProduct.category_group.includes('layer-1'))) {
            console.log(mainProductWidth / mainProductHeight);
            if (mainProductWidth / mainProductHeight > 0.95) {
                mainProductHeight = mainProductHeight / 1.2;
                const elements = document.querySelectorAll('div[data-look="' + lookId + '"] .garderobo-widget-look-product--center');
                for (let i = 0; i < elements.length; i++) {
                    elements[i].style.minWidth = '40%';
                    elements[i].style.left = '30%';
                }
            }
        } else if (mainProduct.category_group && mainProduct.category_group.includes('layer-full')) {
            mainProductHeight = mainProductHeight / 1.3;
        } else if (mainProduct.category_group && mainProduct.category_group.includes('layer-3')) {
            console.log(mainProductWidth / mainProductHeight);
            if (mainProductWidth / mainProductHeight < 0.45) mainProductHeight = mainProductHeight / 2.5;
            else if (mainProductWidth / mainProductHeight < 0.6) mainProductHeight = mainProductHeight / 1.5;
            else if (mainProductWidth / mainProductHeight < 0.9) mainProductHeight = mainProductHeight / 1.2;
            else if (mainProductWidth / mainProductHeight > 1.4) {
                const elements = document.querySelectorAll('div[data-look="' + lookId + '"] .garderobo-widget-look-product--center');
                for (let i = 0; i < elements.length; i++) {
                    elements[i].style.minWidth = '50%';
                    elements[i].style.left = '25%';
                }
            } else if (mainProductWidth / mainProductHeight > 0.95) {
                const elements = document.querySelectorAll('div[data-look="' + lookId + '"] .garderobo-widget-look-product--center');
                for (let i = 0; i < elements.length; i++) {
                    elements[i].style.minWidth = '40%';
                    elements[i].style.left = '30%';
                }
            }
        } else if (mainProduct.category_group && mainProduct.category_group.includes('bottom')) {
            console.log(mainProductWidth / mainProductHeight);
            if (mainProductWidth / mainProductHeight > 1) mainProductHeight = mainProductHeight * 1.5;
            else if (mainProductWidth / mainProductHeight < 0.65) mainProductHeight = mainProductHeight / 1.3;
        }

        if (mainProduct.category_group == 'layer-1_top') if (mainProductWidth / mainProductHeight > 0.8) mainProductWidth = mainProductWidth * 1.2;

        if (fullCategory && fullCategory.includes('bottom')) {
            if (new_width / new_height < 0.5) new_height = new_height * 1.7;
            else if (new_width / new_height > 0.8) new_width = new_width * 1.7;
        } else if (fullCategory == 'layer-1_top') {
            if (new_width / new_height > 0.8) new_height = new_height * 1.7;
        }

        return (
            (Math.min(mainProductWidth, mainProductHeight) / Math.min(new_width, new_height) +
                Math.max(mainProductWidth, mainProductHeight) / Math.max(new_width, new_height)) /
            2
        );
    }
}

Collage.allTemplates = {
    'layer-1': [
        [{ id: 'puma-sa-1', code: 'puma-sa' }, 'bottom', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head'],
        [{ id: 'puma-sa-1-2', code: 'puma-sa' }, 'layer-2', 'bottom', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head'],
        [{ id: 'puma-sa-1-3', code: 'puma-sa' }, 'layer-3', 'bottom', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head'],
        [{ id: 'puma-sa-1-2-3', code: 'puma-sa' }, 'layer-2', 'layer-3', 'bottom', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head'],
        [{ id: 'standard-1', code: 'standard' }, 'bottom', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head', 'accessory'],
        [{ id: 'standard-1-2', code: 'standard' }, 'layer-2', 'bottom', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head', 'accessory'],
        [{ id: 'standard-1-3', code: 'standard' }, 'layer-3', 'bottom', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head', 'accessory'],
        [{ id: 'standard-1-2-3', code: 'standard' }, 'layer-2', 'layer-3', 'bottom', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head', 'accessory'],
        [{ id: 'standard-1-2-2v-3', code: 'standard' }, 'layer-2', 'layer-3', 'bottom', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head', 'accessory'],
        [{ id: 'standard-1-full', code: 'standard' }, 'layer-full', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head', 'accessory'],
        [{ id: 'standard-1-2-full', code: 'standard' }, 'layer-2', 'layer-full', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head', 'accessory'],
        [{ id: 'standard-1-3-full', code: 'standard' }, 'layer-3', 'layer-full', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head', 'accessory'],
        [{ id: 'standard-1-2-3-full', code: 'standard' }, 'layer-2', 'layer-3', 'layer-full', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head', 'accessory'],
        [{ id: '1-1' }, 'layer-3', 'bottom', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '1-2' }, 'layer-2', 'layer-3', 'bottom', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '1-3' }, 'layer-3', 'hat', 'bottom', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '1-4' }, 'layer-2', 'hat', 'bottom', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '1-5' }, 'bag', 'wallet-bag', 'hat', 'bottom', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '1-5-2' }, 'bag', 'wallet-bag', 'hat', 'bottom', 'bottom_shorts', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '1-6' }, 'layer-2', 'bag', 'wallet-bag', 'bottom', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '1-7' }, 'layer-2', 'layer-3', 'bag', 'wallet-bag', 'hat', 'bottom', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '1-8' }, 'layer-2', 'layer-3', 'hat', 'bottom', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '1-9' }, 'layer-3', 'hat', 'bag', 'wallet-bag', 'bottom', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '1-10' }, 'layer-2', 'hat', 'bag', 'wallet-bag', 'bottom', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '1-11' }, 'layer-full', 'layer-3', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'hat', 'socks', 'accessory'],
        [{ id: '1-12' }, 'layer-full', 'layer-2', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'hat', 'socks', 'accessory'],
    ],
    'layer-2': [
        [{ id: 'puma-sa-2', code: 'puma-sa' }, 'bottom', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head'],
        [{ id: 'puma-sa-1-2', code: 'puma-sa' }, 'layer-1', 'bottom', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head'],
        [{ id: 'puma-sa-2-3', code: 'puma-sa' }, 'layer-3', 'bottom', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head'],
        [{ id: 'puma-sa-1-2-3', code: 'puma-sa' }, 'layer-1', 'layer-3', 'bottom', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head'],
        [{ id: 'standard-2', code: 'standard' }, 'bottom', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head', 'accessory'],
        [{ id: 'standard-1-2', code: 'standard' }, 'layer-1', 'bottom', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head', 'accessory'],
        [{ id: 'standard-2-3', code: 'standard' }, 'layer-3', 'bottom', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head', 'accessory'],
        [{ id: 'standard-2-2-3', code: 'standard' }, 'layer-2', 'layer-3', 'bottom', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head', 'accessory'],
        [{ id: 'standard-1-2-3', code: 'standard' }, 'layer-1', 'layer-3', 'bottom', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head', 'accessory'],
        [{ id: 'standard-2-full', code: 'standard' }, 'layer-full', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head', 'accessory'],
        [{ id: 'standard-1-2-full', code: 'standard' }, 'layer-1', 'layer-full', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head', 'accessory'],
        [{ id: 'standard-2-3-full', code: 'standard' }, 'layer-3', 'layer-full', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head', 'accessory'],
        [{ id: 'standard-1-2-3-full', code: 'standard' }, 'layer-1', 'layer-3', 'layer-full', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'gloves_glass', 'gloves_head', 'accessory'],
        [{ id: '2-1' }, 'layer-1', 'bottom', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'hat', 'socks', 'accessory'],
        [{ id: '2-2' }, 'layer-1', 'layer-3', 'bottom', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '2-3' }, 'layer-3', 'hat', 'bottom', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '2-4' }, 'layer-1', 'hat', 'bottom', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '2-5' }, 'layer-full', 'hat', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '2-6' }, 'layer-1', 'layer-3', 'bag', 'wallet-bag', 'hat', 'bottom', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '2-7' }, 'layer-1', 'layer-3', 'hat', 'bottom', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '2-8' }, 'layer-3', 'bag', 'wallet-bag', 'bottom', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '2-8-2' }, 'layer-2', 'bag', 'wallet-bag', 'bottom', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '2-9' }, 'hat', 'bag', 'wallet-bag', 'bottom', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '2-10' }, 'layer-3', 'layer-full', 'hat', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
    ],
    'layer-3': [
        [
            { id: 'puma-sa-1-3', code: 'puma-sa' },
            'layer-1',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'puma-sa-2-3', code: 'puma-sa' },
            'layer-2',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'puma-sa-1-2-3', code: 'puma-sa' },
            'layer-1',
            'layer-2',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'standard-1-3', code: 'standard' },
            'layer-1',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
            'accessory',
        ],
        [
            { id: 'standard-2-3', code: 'standard' },
            'layer-2',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
            'accessory',
        ],
        [
            { id: 'standard-1-2-3', code: 'standard' },
            'layer-1',
            'layer-2',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'standard-1-3-full', code: 'standard' },
            'layer-1',
            'layer-full',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'standard-2-3-full', code: 'standard' },
            'layer-2',
            'layer-full',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'standard-1-2-3-full', code: 'standard' },
            'layer-1',
            'layer-2',
            'layer-full',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [{ id: '3-1' }, 'layer-1', 'layer-2', 'bottom', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '3-2' }, 'layer-2', 'bottom', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '3-3' }, 'layer-2', 'bottom', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '3-4' }, 'layer-1', 'bottom', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '3-5' }, 'layer-1', 'bottom', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '3-6' }, 'layer-full', 'hat', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '3-7' }, 'layer-full', 'layer-2', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'hat', 'socks', 'accessory'],
        [{ id: '3-8' }, 'layer-full', 'layer-1', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'hat', 'socks', 'accessory'],
        [{ id: '3-9' }, 'layer-1', 'layer-2', 'bag', 'wallet-bag', 'hat', 'bottom', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '3-10' }, 'layer-1', 'layer-2', 'hat', 'bottom', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
    ],
    'layer-full': [
        [
            { id: 'standard-1-full', code: 'standard' },
            'layer-1',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'standard-1-2-full', code: 'standard' },
            'layer-1',
            'layer-2',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'standard-1-3-full', code: 'standard' },
            'layer-1',
            'layer-3',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'standard-1-2-3-full', code: 'standard' },
            'layer-2',
            'layer-3',
            'layer-1',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'standard-2-full', code: 'standard' },
            'layer-2',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'standard-2-3-full', code: 'standard' },
            'layer-3',
            'layer-2',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [{ id: '4-1' }, 'layer-2', 'hat', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '4-2' }, 'layer-1', 'layer-2', 'bag', 'hat', 'wallet-bag', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '4-3' }, 'layer-2', 'layer-3', 'bag', 'hat', 'wallet-bag', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '4-4' }, 'layer-1', 'layer-3', 'bag', 'hat', 'wallet-bag', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '4-5' }, 'layer-2', 'layer-3', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '4-6' }, 'layer-1', 'hat', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '4-7' }, 'hat', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '4-8' }, 'layer-3', 'hat', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
    ],
    bottom: [
        [
            { id: 'puma-sa-1-2', code: 'puma-sa' },
            'layer-1',
            'layer-2',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'puma-sa-1-3', code: 'puma-sa' },
            'layer-1',
            'layer-3',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'puma-sa-2-3', code: 'puma-sa' },
            'layer-2',
            'layer-3',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'puma-sa-2', code: 'puma-sa' },
            'layer-2',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'puma-sa-1', code: 'puma-sa' },
            'layer-1',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'puma-sa-1-2-3', code: 'puma-sa' },
            'layer-1',
            'layer-2',
            'layer-3',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],

        [
            { id: 'standard-2', code: 'standard' },
            'layer-2',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'standard-1', code: 'standard' },
            'layer-1',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'standard-1-2', code: 'standard' },
            'layer-1',
            'layer-2',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'standard-1-3', code: 'standard' },
            'layer-1',
            'layer-3',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'standard-2-3', code: 'standard' },
            'layer-2',
            'layer-3',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'standard-1-2-3', code: 'standard' },
            'layer-1',
            'layer-2',
            'layer-3',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],

        [{ id: '5-1' }, 'layer-1', 'layer-3', 'hat', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '5-2' }, 'layer-1', 'layer-3', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '5-3' }, 'layer-1', 'layer-2', 'hat', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '5-4' }, 'layer-2', 'layer-3', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'accessory'],
        [{ id: '5-4-2' }, 'layer-2', 'layer-3', 'bag', 'wallet-bag', 'shoes', 'hat', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '5-5' }, 'layer-1', 'layer-2', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '5-6' }, 'layer-2', 'layer-3', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '5-7' }, 'layer-1', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '5-8' }, 'layer-2', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '5-9' }, 'layer-1', 'layer-2', 'layer-3', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'hat', 'socks', 'accessory'],
        [{ id: '5-11' }, 'layer-1', 'layer-2', 'hat', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '5-12' }, 'layer-2', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
    ],
    bottom_shorts: [
        [
            { id: 'puma-sa-1-2', code: 'puma-sa' },
            'layer-1',
            'layer-2',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'puma-sa-1-3', code: 'puma-sa' },
            'layer-1',
            'layer-3',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'puma-sa-2-3', code: 'puma-sa' },
            'layer-2',
            'layer-3',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'puma-sa-2', code: 'puma-sa' },
            'layer-2',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'puma-sa-1', code: 'puma-sa' },
            'layer-1',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'puma-sa-1-2-3', code: 'puma-sa' },
            'layer-1',
            'layer-2',
            'layer-3',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],

        [
            { id: 'standard-2', code: 'standard' },
            'layer-2',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'standard-1', code: 'standard' },
            'layer-1',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'standard-1-2', code: 'standard' },
            'layer-1',
            'layer-2',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'standard-1-3', code: 'standard' },
            'layer-1',
            'layer-3',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'standard-2-3', code: 'standard' },
            'layer-2',
            'layer-3',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],
        [
            { id: 'standard-1-2-3', code: 'standard' },
            'layer-1',
            'layer-2',
            'layer-3',
            'bottom',
            'bag',
            'wallet-bag',
            'hat',
            'shoes',
            'belt',
            'gloves',
            'socks',
            'gloves_glass',
            'gloves_head',
            'accessory',
        ],

        [{ id: '6-1' }, 'layer-1', 'layer-3', 'hat', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '6-2' }, 'layer-1', 'layer-3', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '6-3' }, 'layer-1', 'layer-2', 'hat', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '6-4' }, 'layer-2', 'layer-3', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '6-4-2' }, 'layer-2', 'layer-3', 'bag', 'wallet-bag', 'shoes', 'hat', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '6-5' }, 'layer-1', 'layer-2', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '6-6' }, 'layer-2', 'layer-3', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '6-7' }, 'layer-1', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '6-8' }, 'layer-2', 'bag', 'wallet-bag', 'hat', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '6-9' }, 'layer-1', 'layer-2', 'layer-3', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'hat', 'socks', 'accessory'],
        [{ id: '6-11' }, 'layer-1', 'layer-2', 'hat', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
        [{ id: '6-12' }, 'layer-2', 'bag', 'wallet-bag', 'shoes', 'belt', 'gloves', 'socks', 'accessory'],
    ],
};

Collage.classesMap = {
    'puma-sa-1-2': {
        'layer-1': ['left-top', 'custom-left-top'],
        'layer-2': ['right-top', 'custom-right-top'],
        bottom: ['center', 'center-bottom', 'custom-bottom'],
        bag: ['bag', 'right-bottom-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
        gloves_glass: ['accessory-glasses'],
        gloves_head: ['accessory-head'],
    },
    'puma-sa-1-3': {
        'layer-1': ['left-top', 'custom-left-top'],
        'layer-3': ['right-top', 'wear', 'custom-right-top'],
        bottom: ['center', 'center-bottom', 'custom-bottom'],
        bag: ['bag', 'right-bottom-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
        gloves_glass: ['accessory-glasses'],
        gloves_head: ['accessory-head'],
    },
    'puma-sa-2-3': {
        'layer-2': ['left-top', 'custom-left-top'],
        'layer-3': ['right-top', 'wear', 'custom-right-top'],
        bottom: ['center', 'center-bottom', 'custom-bottom'],
        bag: ['bag', 'right-bottom-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
        gloves_glass: ['accessory-glasses'],
        gloves_head: ['accessory-head', 'accessory-head-2'],
    },
    'puma-sa-2': {
        'layer-2': ['right-top', 'custom-right-top'],
        bottom: ['center', 'center-bottom', 'custom-bottom'],
        bag: ['bag', 'right-bottom-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
        gloves_glass: ['accessory-glasses'],
        gloves_head: ['accessory-head', 'accessory-head-2'],
    },
    'puma-sa-1': {
        'layer-1': ['left-top', 'custom-left-top'],
        bottom: ['center', 'center-bottom', 'custom-bottom'],
        bag: ['bag', 'right-bottom-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
        gloves_glass: ['accessory-glasses', 'accessory-glasses-2'],
        gloves_head: ['accessory-head'],
    },
    'puma-sa-1-2-3': {
        'layer-1': ['left-top', 'custom-left-top'],
        'layer-2': ['right-top', 'right-top-3', 'custom-left-top'],
        'layer-3': ['right-top', 'wear', 'custom-right-top'],
        bottom: ['center', 'center-bottom', 'custom-bottom'],
        bag: ['bag', 'right-bottom-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes'],
        belt: ['belt', 'belt-2'],
        socks: ['socks'],
        gloves_glass: ['accessory-glasses'],
        gloves_head: ['accessory-head'],
        gloves: ['gloves', 'right-gloves'],
    },

    'standard-1-2': {
        'layer-1': ['left-top', 'custom-left-top'],
        'layer-2': ['right-top', 'custom-right-top'],
        bottom: ['center', 'center-bottom', 'custom-bottom'],
        bag: ['bag', 'right-bottom-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'right-gloves'],
        accessory: ['gloves', 'right-gloves'],
        socks: ['socks'],
        gloves_glass: ['accessory-glasses'],
        gloves_head: ['accessory-head'],
    },
    'standard-1-3': {
        'layer-1': ['left-top', 'custom-left-top'],
        'layer-3': ['right-top', 'wear', 'custom-right-top'],
        bottom: ['center', 'center-bottom', 'custom-bottom'],
        bag: ['bag', 'right-bottom-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'right-gloves'],
        accessory: ['gloves', 'right-gloves'],
        socks: ['socks'],
        gloves_glass: ['accessory-glasses'],
        gloves_head: ['accessory-head'],
    },
    'standard-2-3': {
        'layer-2': ['left-top', 'custom-left-top'],
        'layer-3': ['right-top', 'wear', 'custom-right-top'],
        bottom: ['center', 'center-bottom', 'custom-bottom'],
        bag: ['bag', 'right-bottom-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'right-gloves'],
        accessory: ['gloves', 'right-gloves'],
        socks: ['socks'],
        gloves_glass: ['accessory-glasses'],
        gloves_head: ['accessory-head'],
    },
    'standard-2-2-3': {
        'layer-2': ['left-top', 'custom-left-top'],
        'layer-2_vest': ['right-top', 'custom-right-top'],
        'layer-3': ['right-top', 'wear', 'custom-right-top'],
        bottom: ['center', 'center-bottom', 'custom-bottom'],
        bag: ['bag', 'right-bottom-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'right-gloves'],
        accessory: ['gloves', 'right-gloves'],
        socks: ['socks'],
        gloves_glass: ['accessory-glasses'],
        gloves_head: ['accessory-head'],
    },
    'standard-2': {
        'layer-2': ['right-top', 'custom-right-top'],
        bottom: ['center', 'center-bottom', 'custom-bottom'],
        bag: ['bag', 'right-bottom-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'right-gloves'],
        accessory: ['gloves', 'right-gloves'],
        socks: ['socks'],
        gloves_glass: ['accessory-glasses'],
        gloves_head: ['accessory-head', 'accessory-head-2'],
    },
    'standard-1': {
        'layer-1': ['left-top', 'custom-left-top'],
        bottom: ['center', 'center-bottom', 'custom-bottom'],
        bag: ['bag', 'right-bottom-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'right-gloves'],
        accessory: ['gloves', 'right-gloves'],
        socks: ['socks'],
        gloves_glass: ['accessory-glasses', 'accessory-glasses-2'],
        gloves_head: ['accessory-head'],
    },
    'standard-1-2-3': {
        'layer-1': ['left-top', 'custom-left-top'],
        'layer-2': ['right-top', 'right-top-3', 'custom-left-top'],
        'layer-3': ['right-top', 'wear', 'custom-right-top'],
        bottom: ['center', 'center-bottom', 'custom-bottom'],
        bag: ['bag', 'right-bottom-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'right-gloves'],
        accessory: ['gloves', 'right-gloves'],
        socks: ['socks'],
        gloves_glass: ['accessory-glasses'],
        gloves_head: ['accessory-head'],
    },

    'standard-1-2-full': {
        'layer-1': ['left-top', 'custom-left-top'],
        'layer-2': ['right-top', 'custom-right-top'],
        'layer-full': ['center', 'center-layer-full'],
        bag: ['bag', 'right-bottom-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'right-gloves'],
        accessory: ['gloves', 'right-gloves'],
        socks: ['socks'],
        gloves_glass: ['accessory-glasses'],
        gloves_head: ['accessory-head'],
    },
    'standard-1-3-full': {
        'layer-1': ['left-top', 'custom-left-top'],
        'layer-3': ['right-top', 'wear', 'custom-right-top'],
        'layer-full': ['center', 'center-layer-full'],
        bag: ['bag', 'right-bottom-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'right-gloves'],
        accessory: ['gloves', 'right-gloves'],
        socks: ['socks'],
        gloves_glass: ['accessory-glasses'],
        gloves_head: ['accessory-head'],
    },
    'standard-2-3-full': {
        'layer-2': ['left-top', 'custom-left-top'],
        'layer-3': ['right-top', 'wear', 'custom-right-top'],
        'layer-full': ['center', 'center-layer-full'],
        bag: ['bag', 'right-bottom-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'right-gloves'],
        accessory: ['gloves', 'right-gloves'],
        socks: ['socks'],
        gloves_glass: ['accessory-glasses'],
        gloves_head: ['accessory-head', 'accessory-head-2'],
    },
    'standard-2-full': {
        'layer-2': ['right-top', 'custom-right-top'],
        'layer-full': ['center', 'center-layer-full'],
        bag: ['bag', 'right-bottom-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'right-gloves'],
        accessory: ['gloves', 'right-gloves'],
        socks: ['socks'],
        gloves_glass: ['accessory-glasses'],
        gloves_head: ['accessory-head', 'accessory-head-2'],
    },
    'standard-1-full': {
        'layer-1': ['left-top', 'custom-left-top'],
        'layer-full': ['center', 'center-layer-full'],
        bag: ['bag', 'right-bottom-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'right-gloves'],
        accessory: ['gloves', 'right-gloves'],
        socks: ['socks'],
        gloves_glass: ['accessory-glasses', 'accessory-glasses-2'],
        gloves_head: ['accessory-head'],
    },
    'standard-1-2-3-full': {
        'layer-1': ['left-top', 'custom-left-top'],
        'layer-2': ['right-top', 'right-top-3', 'custom-left-top'],
        'layer-3': ['right-top', 'wear', 'custom-right-top'],
        'layer-full': ['center', 'center-layer-full'],
        bag: ['bag', 'right-bottom-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'right-gloves'],
        accessory: ['gloves', 'right-gloves'],
        socks: ['socks'],
        gloves_glass: ['accessory-glasses'],
        gloves_head: ['accessory-head'],
    },

    '1-1': {
        'layer-1': ['center', 'center-layer-1'],
        'layer-3': ['left-top', 'wear'],
        bottom: ['right-bottom'],
        bag: ['bag', 'right-top-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
    },
    '1-2': {
        'layer-1': ['center', 'center-layer-1'],
        'layer-2': ['left-top', 'left-top-2'],
        'layer-3': ['right-top', 'right-top-2', 'wear'],
        bottom: ['right-bottom'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
    },
    '1-3': {
        'layer-1': ['center', 'center-layer-1'],
        'layer-3': ['left-top', 'wear'],
        hat: ['hat'],
        bottom: ['right-bottom'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
    },
    '1-4': {
        'layer-1': ['center', 'center-layer-1'],
        'layer-2': ['left-top'],
        hat: ['hat'],
        bottom: ['right-bottom'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
    },
    '1-5': {
        'layer-1': ['center', 'center-layer-1'],
        bag: ['bag', 'right-top-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        hat: ['hat', 'left-hat'],
        bottom: ['right-bottom'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
    },
    '1-5-2': {
        'layer-1': ['center', 'center-layer-1'],
        bag: ['bag', 'right-top-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        hat: ['hat', 'left-hat'],
        bottom: ['right-bottom'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
    },
    '1-6': {
        'layer-1': ['center', 'center-layer-1'],
        'layer-2': ['left-top'],
        bag: ['bag', 'right-top-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        bottom: ['right-bottom'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
    },
    '1-7': {
        'layer-1': ['center', 'center-layer-1'],
        'layer-2': ['left-top'],
        'layer-3': ['right-top', 'wear'],
        bag: ['bag', 'left-center-bag'],
        wallet: ['wallet-bag', 'left-center-wallet-bag'],
        hat: ['hat', 'center-hat'],
        bottom: ['right-bottom'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'belt-3'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
    },
    '1-8': {
        'layer-1': ['center', 'center-layer-1'],
        'layer-2': ['left-top', 'left-top-2'],
        'layer-3': ['right-top', 'right-top-2', 'wear'],
        hat: ['hat', 'center-hat'],
        bottom: ['right-bottom'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
    },
    '1-9': {
        'layer-1': ['center', 'center-layer-1'],
        'layer-3': ['right-top', 'right-top-2', 'wear'],
        hat: ['hat', 'center-hat'],
        bag: ['bag', 'left-center-bag'],
        wallet: ['wallet-bag', 'left-center-wallet-bag'],
        bottom: ['right-bottom'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'belt-3'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
    },
    '1-10': {
        'layer-1': ['center', 'center-layer-1'],
        'layer-2': ['right-top', 'right-top-2'],
        hat: ['hat', 'center-hat'],
        bag: ['bag', 'left-center-bag'],
        wallet: ['wallet-bag', 'left-center-wallet-bag'],
        bottom: ['right-bottom'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'belt-3'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
    },
    '1-11': {
        'layer-1': ['center', 'center-layer-1'],
        'layer-full': ['layer-full'],
        'layer-3': ['left-top', 'wear'],
        hat: ['hat'],
        bag: ['bag', 'right-bottom-bag'],
        wallet: ['wallet-bag', 'right-bottom-wallet-bag'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'left-gloves-3'],
        socks: ['socks'],
    },
    '1-12': {
        'layer-1': ['center', 'center-layer-1'],
        'layer-full': ['layer-full'],
        'layer-2': ['left-top'],
        hat: ['hat'],
        bag: ['bag', 'right-bottom-bag'],
        wallet: ['wallet-bag', 'right-bottom-wallet-bag'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'left-gloves-3'],
        socks: ['socks'],
    },
    '2-1': {
        'layer-2': ['center', 'wear'],
        'layer-1': ['left-top'],
        bag: ['bag', 'right-top-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        bottom: ['right-bottom'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'right-gloves'],
        hat: ['hat'],
        socks: ['socks'],
    },
    '2-2': {
        'layer-2': ['center', 'wear'],
        'layer-1': ['left-top', 'left-top-2'],
        'layer-3': ['right-top', 'right-top-2', 'wear'],
        bottom: ['right-bottom'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'right-gloves-2'],
        socks: ['socks'],
    },
    '2-3': {
        'layer-2': ['center', 'wear'],
        'layer-3': ['left-top', 'wear'],
        hat: ['hat'],
        bottom: ['right-bottom'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
    },
    '2-4': {
        'layer-2': ['center', 'wear'],
        'layer-1': ['left-top'],
        hat: ['hat'],
        bottom: ['right-bottom'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
    },
    '2-5': {
        'layer-2': ['center', 'wear'],
        'layer-full': ['layer-full'],
        hat: ['hat', 'left-hat'],
        bag: ['bag', 'left-center-bag'],
        wallet: ['wallet-bag', 'left-center-wallet-bag'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'belt-3'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
    },
    '2-6': {
        'layer-2': ['center', 'wear'],
        'layer-1': ['left-top', 'left-top-2'],
        'layer-3': ['right-top', 'right-top-2', 'wear'],
        bag: ['bag', 'left-center-bag'],
        wallet: ['wallet-bag', 'left-center-wallet-bag'],
        hat: ['hat', 'center-hat'],
        bottom: ['right-bottom'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'belt-3'],
        gloves: ['gloves', 'right-gloves-2'],
        socks: ['socks'],
    },
    '2-7': {
        'layer-2': ['center', 'wear'],
        'layer-1': ['left-top'],
        'layer-3': ['right-top', 'wear'],
        hat: ['hat', 'center-hat'],
        bottom: ['right-bottom'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'right-gloves-2'],
        socks: ['socks'],
    },
    '2-8': {
        'layer-2': ['center', 'wear'],
        'layer-3': ['left-top', 'wear'],
        bag: ['bag', 'right-top-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        bottom: ['right-bottom'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
    },
    '2-8-2': {
        'layer-2': ['center'],
        bag: ['bag', 'right-top-bag'],
        wallet: ['wallet-bag', 'right-top-wallet-bag'],
        bottom: ['right-bottom'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
    },
    '2-9': {
        'layer-2': ['center', 'wear'],
        hat: ['hat'],
        bag: ['bag', 'left-center-bag'],
        wallet: ['wallet-bag', 'left-center-wallet-bag'],
        bottom: ['right-bottom'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'belt-3'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
    },
    '2-10': {
        'layer-2': ['center', 'wear'],
        'layer-3': ['left-top', 'wear'],
        'layer-full': ['layer-full'],
        hat: ['hat', 'center-hat'],
        bag: ['bag', 'right-bottom-bag'],
        wallet: ['wallet-bag', 'right-bottom-wallet-bag'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'belt-2'],
        gloves: ['gloves', 'left-gloves-3'],
        socks: ['socks'],
    },
    '3-1': {
        'layer-3': ['center', 'wear'],
        'layer-1': ['left-top', 'left-top-2'],
        'layer-2': ['right-top', 'right-top-2'],
        bottom: ['right-bottom'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
    },
    '3-2': {
        'layer-3': ['center', 'wear'],
        'layer-2': ['right-top'],
        hat: ['hat', 'left-hat'],
        bottom: ['right-bottom'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
    },
    '3-3': {
        'layer-3': ['center', 'wear'],
        'layer-2': ['right-top'],
        bottom: ['right-bottom'],
        bag: ['bag', 'left-center-bag'],
        wallet: ['wallet-bag', 'left-center-wallet-bag'],
        hat: ['hat', 'left-hat'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'belt-3'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
    },
    '3-4': {
        'layer-3': ['center', 'wear'],
        'layer-1': ['right-top'],
        bottom: ['right-bottom'],
        bag: ['bag', 'left-top-bag'],
        wallet: ['wallet-bag', 'left-top-wallet-bag'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
    },
    '3-5': {
        'layer-3': ['center', 'wear'],
        'layer-1': ['right-top'],
        bottom: ['right-bottom'],
        hat: ['hat', 'left-hat'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
    },
    '3-6': {
        'layer-3': ['center', 'wear'],
        'layer-full': ['layer-full'],
        hat: ['hat'],
        bag: ['bag', 'left-center-bag'],
        wallet: ['wallet-bag', 'left-center-wallet-bag'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'belt-3'],
        gloves: ['gloves', 'left-gloves-2'],
        socks: ['socks'],
    },
    '3-7': {
        'layer-3': ['center', 'wear'],
        'layer-full': ['layer-full'],
        'layer-2': ['left-top'],
        bag: ['bag', 'left-center-bag'],
        wallet: ['wallet-bag', 'left-center-wallet-bag'],
        hat: ['hat'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'belt-3'],
        gloves: ['gloves', 'left-gloves-3'],
        socks: ['socks'],
    },
    '3-8': {
        'layer-3': ['center', 'wear'],
        'layer-full': ['layer-full'],
        'layer-1': ['left-top'],
        bag: ['bag', 'left-center-bag'],
        wallet: ['wallet-bag', 'left-center-wallet-bag'],
        hat: ['hat'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'belt-3'],
        gloves: ['gloves', 'left-gloves-3'],
        socks: ['socks'],
    },
    '3-9': {
        'layer-3': ['center', 'wear'],
        'layer-1': ['left-top'],
        'layer-2': ['right-top'],
        bag: ['bag', 'left-center-bag'],
        wallet: ['wallet-bag', 'left-center-wallet-bag'],
        hat: ['hat', 'center-hat'],
        bottom: ['right-bottom'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'belt-3'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
    },
    '3-10': {
        'layer-3': ['center', 'wear'],
        'layer-1': ['left-top'],
        'layer-2': ['right-top'],
        hat: ['hat', 'center-hat'],
        bottom: ['right-bottom'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'right-gloves'],
        socks: ['socks'],
    },
    '4-1': {
        'layer-full': ['center', 'center-layer-full'],
        'layer-2': ['right-top'],
        hat: ['hat', 'left-hat'],
        bag: ['bag', 'left-center-bag'],
        wallet: ['wallet-bag', 'left-center-wallet-bag'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'right-belt'],
        gloves: ['gloves', 'left-gloves-4'],
        socks: ['socks'],
    },
    '4-2': {
        'layer-full': ['center', 'center-layer-full'],
        'layer-1': ['left-top'],
        'layer-2': ['right-top'],
        hat: ['hat', 'center-hat'],
        bag: ['bag', 'left-center-bag'],
        wallet: ['wallet-bag', 'left-center-wallet-bag'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'right-belt'],
        gloves: ['gloves', 'left-gloves-4'],
        socks: ['socks'],
    },
    '4-3': {
        'layer-full': ['center', 'center-layer-full'],
        'layer-2': ['left-top'],
        'layer-3': ['right-top', 'wear'],
        hat: ['hat', 'center-hat'],
        bag: ['bag', 'left-center-bag'],
        wallet: ['wallet-bag', 'left-center-wallet-bag'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'right-belt'],
        gloves: ['gloves', 'left-gloves-4'],
        socks: ['socks'],
    },
    '4-4': {
        'layer-full': ['center', 'center-layer-full'],
        'layer-1': ['left-top'],
        'layer-3': ['right-top', 'wear'],
        hat: ['hat', 'center-hat'],
        bag: ['bag', 'left-center-bag'],
        wallet: ['wallet-bag', 'left-center-wallet-bag'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'right-belt'],
        gloves: ['gloves', 'left-gloves-4'],
        socks: ['socks'],
    },
    '4-5': {
        'layer-full': ['center', 'center-layer-full'],
        'layer-2': ['left-top'],
        'layer-3': ['right-top', 'wear'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'right-belt'],
        gloves: ['gloves', 'left-gloves-4'],
        socks: ['socks'],
    },
    '4-6': {
        'layer-full': ['center', 'center-layer-full'],
        'layer-1': ['right-top'],
        bag: ['bag', 'left-center-bag'],
        wallet: ['wallet-bag', 'left-center-wallet-bag'],
        hat: ['hat', 'left-hat'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'right-belt'],
        gloves: ['gloves', 'left-gloves-4'],
        socks: ['socks'],
    },
    '4-7': {
        'layer-full': ['center', 'center-layer-full'],
        bag: ['bag', 'left-center-bag'],
        wallet: ['wallet-bag', 'left-center-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'right-belt'],
        gloves: ['gloves', 'left-gloves-4'],
        socks: ['socks'],
    },
    '4-8': {
        'layer-full': ['center', 'center-layer-full'],
        'layer-3': ['right-top', 'wear'],
        hat: ['hat', 'left-hat'],
        bag: ['bag', 'left-center-bag'],
        wallet: ['wallet-bag', 'left-center-wallet-bag'],
        shoes: ['shoes', 'bottom-center-shoes'],
        belt: ['belt', 'right-belt'],
        gloves: ['gloves', 'left-gloves-4'],
        socks: ['socks'],
    },
    '5-1': {
        bottom: ['center', 'center-bottom'],
        'layer-1': ['left-top'],
        'layer-3': ['right-top', 'wear'],
        hat: ['hat', 'center-hat'],
        bag: ['bag', 'right-center-bag'],
        wallet: ['wallet-bag', 'right-center-wallet-bag'],
        shoes: ['shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'left-gloves-5'],
        socks: ['socks'],
    },
    '5-2': {
        bottom: ['center', 'center-bottom'],
        'layer-1': ['left-top', 'left-top-2'],
        'layer-3': ['right-top', 'right-top-2', 'wear'],
        bag: ['bag', 'right-center-bag'],
        wallet: ['wallet-bag', 'right-center-wallet-bag'],
        shoes: ['shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'left-gloves-5'],
        socks: ['socks'],
    },
    '5-3': {
        bottom: ['center', 'center-bottom'],
        'layer-1': ['left-top'],
        'layer-2': ['right-top'],
        bag: ['bag', 'right-center-bag'],
        wallet: ['wallet-bag', 'right-center-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'left-gloves-5'],
        socks: ['socks'],
    },
    '5-4': {
        bottom: ['center', 'center-bottom'],
        'layer-2': ['left-top', 'left-top-2'],
        'layer-3': ['right-top', 'right-top-2', 'wear'],
        bag: ['bag', 'right-center-bag'],
        wallet: ['wallet-bag', 'right-center-wallet-bag'],
        shoes: ['shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'left-gloves-5'],
        socks: ['socks'],
    },
    '5-4-2': {
        bottom: ['center', 'center-bottom'],
        'layer-2': ['left-top'],
        'layer-3': ['right-top', 'wear'],
        bag: ['bag', 'right-center-bag'],
        wallet: ['wallet-bag', 'right-center-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'left-gloves-5'],
        socks: ['socks'],
    },
    '5-5': {
        bottom: ['center', 'center-bottom'],
        'layer-1': ['left-top'],
        'layer-2': ['right-top'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'left-gloves-5'],
        socks: ['socks'],
    },
    '5-6': {
        bottom: ['center', 'center-bottom'],
        'layer-2': ['left-top'],
        'layer-3': ['right-top', 'wear'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'left-gloves-5'],
        socks: ['socks'],
    },
    '5-7': {
        bottom: ['center', 'center-bottom'],
        'layer-1': ['left-top'],
        bag: ['bag', 'right-center-bag'],
        wallet: ['wallet-bag', 'right-center-wallet-bag'],
        hat: ['hat'],
        shoes: ['shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'left-gloves-5'],
        socks: ['socks'],
    },
    '5-8': {
        bottom: ['center', 'center-bottom'],
        'layer-2': ['left-top', 'left-top-2'],
        bag: ['bag', 'right-center-bag'],
        wallet: ['wallet-bag', 'right-center-wallet-bag'],
        hat: ['hat'],
        shoes: ['shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'left-gloves-5'],
        socks: ['socks'],
    },
    '5-9': {
        bottom: ['center', 'center-bottom'],
        'layer-1': ['left-top'],
        'layer-2': ['right-top'],
        'layer-3': ['right-top', 'right-top-3', 'wear'],
        hat: ['hat', 'center-hat'],
        bag: ['bag', 'right-bottom-bag'],
        wallet: ['wallet-bag', 'right-bottom-wallet-bag'],
        shoes: ['shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'left-gloves-5'],
        socks: ['socks'],
    },
    '5-11': {
        bottom: ['center', 'center-bottom'],
        'layer-1': ['left-top'],
        'layer-2': ['right-top'],
        bag: ['bag', 'right-center-bag'],
        wallet: ['wallet-bag', 'right-center-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'left-gloves-5'],
        socks: ['socks'],
    },
    '5-12': {
        bottom: ['center', 'center-bottom'],
        'layer-2': ['left-top', 'left-top-2'],
        bag: ['bag', 'right-center-bag'],
        wallet: ['wallet-bag', 'right-center-wallet-bag'],
        shoes: ['shoes'],
        belt: ['belt'],
        gloves: ['gloves', 'left-gloves-5'],
        socks: ['socks'],
    },
    '6-1': {
        bottom_shorts: ['center', 'center-shorts'],
        'layer-1': ['left-top', 'left-top-2'],
        'layer-3': ['right-top', 'right-top-2', 'wear'],
        hat: ['hat', 'center-hat'],
        bag: ['bag', 'right-center-bag'],
        wallet: ['wallet-bag', 'right-center-wallet-bag'],
        shoes: ['shoes', 'bottom-center-shoes-2'],
        belt: ['belt'],
        gloves: ['gloves', 'left-gloves-5'],
        socks: ['socks'],
    },
    '6-2': {
        bottom_shorts: ['center', 'center-shorts'],
        'layer-1': ['left-top', 'left-top-2'],
        'layer-3': ['right-top', 'right-top-2', 'wear'],
        bag: ['bag', 'right-center-bag'],
        wallet: ['wallet-bag', 'right-center-wallet-bag'],
        shoes: ['shoes', 'bottom-center-shoes-2'],
        belt: ['belt'],
        gloves: ['gloves', 'left-gloves-5'],
        socks: ['socks'],
    },
    '6-3': {
        bottom_shorts: ['center', 'center-shorts'],
        'layer-1': ['left-top'],
        'layer-2': ['right-top'],
        bag: ['bag', 'right-center-bag'],
        wallet: ['wallet-bag', 'right-center-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes', 'bottom-center-shoes-2'],
        belt: ['belt'],
        gloves: ['gloves', 'left-gloves-5'],
        socks: ['socks'],
    },
    '6-4': {
        bottom_shorts: ['center', 'center-shorts'],
        'layer-2': ['left-top', 'left-top-2'],
        'layer-3': ['right-top', 'right-top-2', 'wear'],
        bag: ['bag', 'right-center-bag'],
        wallet: ['wallet-bag', 'right-center-wallet-bag'],
        shoes: ['shoes', 'bottom-center-shoes-2'],
        belt: ['belt'],
        gloves: ['gloves', 'left-gloves-5'],
        socks: ['socks'],
    },
    '6-4-2': {
        bottom_shorts: ['center', 'center-shorts'],
        'layer-2': ['left-top'],
        'layer-3': ['right-top', 'wear'],
        bag: ['bag', 'right-center-bag'],
        wallet: ['wallet-bag', 'right-center-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes', 'bottom-center-shoes-2'],
        belt: ['belt'],
        gloves: ['gloves', 'left-gloves-5'],
        socks: ['socks'],
    },
    '6-5': {
        bottom_shorts: ['center', 'center-shorts'],
        'layer-1': ['left-top'],
        'layer-2': ['right-top'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes', 'bottom-center-shoes-2'],
        belt: ['belt'],
        gloves: ['gloves', 'left-gloves-5'],
        socks: ['socks'],
    },
    '6-6': {
        bottom_shorts: ['center', 'center-shorts'],
        'layer-2': ['left-top'],
        'layer-3': ['right-top', 'wear'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes', 'bottom-center-shoes-2'],
        belt: ['belt'],
        gloves: ['gloves', 'left-gloves-5'],
        socks: ['socks'],
    },
    '6-7': {
        bottom_shorts: ['center', 'center-shorts'],
        'layer-1': ['left-top'],
        bag: ['bag', 'right-center-bag'],
        wallet: ['wallet-bag', 'right-center-wallet-bag'],
        hat: ['hat'],
        shoes: ['shoes', 'bottom-center-shoes-2'],
        belt: ['belt'],
        gloves: ['gloves', 'left-gloves-5'],
        socks: ['socks'],
    },
    '6-8': {
        bottom_shorts: ['center', 'center-shorts'],
        'layer-2': ['left-top', 'left-top-2'],
        bag: ['bag', 'right-center-bag'],
        wallet: ['wallet-bag', 'right-center-wallet-bag'],
        hat: ['hat'],
        shoes: ['shoes', 'bottom-center-shoes-2'],
        belt: ['belt'],
        gloves: ['gloves', 'left-gloves-5'],
        socks: ['socks'],
    },
    '6-9': {
        bottom_shorts: ['center', 'center-shorts'],
        'layer-1': ['left-top'],
        'layer-2': ['right-top'],
        'layer-3': ['right-top', 'right-top-3', 'wear'],
        bag: ['bag', 'right-bottom-bag'],
        wallet: ['wallet-bag', 'right-bottom-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes', 'bottom-center-shoes-2'],
        belt: ['belt'],
        gloves: ['gloves', 'left-gloves-5'],
        socks: ['socks'],
    },
    '6-11': {
        bottom_shorts: ['center', 'center-shorts'],
        'layer-1': ['left-top'],
        'layer-2': ['right-top'],
        bag: ['bag', 'right-center-bag'],
        wallet: ['wallet-bag', 'right-center-wallet-bag'],
        hat: ['hat', 'center-hat'],
        shoes: ['shoes', 'bottom-center-shoes-2'],
        belt: ['belt'],
        gloves: ['gloves', 'left-gloves-5'],
        socks: ['socks'],
    },
    '6-12': {
        bottom_shorts: ['center', 'center-shorts'],
        'layer-2': ['left-top', 'left-top-2'],
        bag: ['bag', 'right-center-bag'],
        wallet: ['wallet-bag', 'right-center-wallet-bag'],
        shoes: ['shoes', 'bottom-center-shoes-2'],
        belt: ['belt'],
        gloves: ['gloves', 'left-gloves-5'],
        socks: ['socks'],
    },
};

export default Collage;
