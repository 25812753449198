import Const, { BlockShowType, Texts } from '../const';
import Slider from './slider.js';
import SliderSnap from './slider-snap.js';
//import * as Sentry from '@sentry/browser';
import JSON from 'circular-json';
import HandledPromise from './handled-promise';
import grFetch from './fetch';
import CollagesPopup from './vendors/CollagesPopup';
import LooksWithSimilarsPopup from './looks-with-similars.popup';
import Collage from './collages';
import CollageV3 from './collagesv3';
import Ecommerce from './ecommerce';
import LooksSimplePopup from './looks-simple.popup';
import ProductPopup from './product-popup.js';
import Platform from "./platform";
import InlineWidget from './inline.js';

let Page = (containerEl, type, widget, params) => {
    let self = {};

    self._widget = widget;
    self.widgetParams = params;
    self.containerEl = containerEl;
    self.feedContainerEls = {};
    self.feedContainerSliders = {};
    self.feedContainerSlidePosition = {};
    self.feedContainerSlideMaxPosition = {};
    self.feedContainerItems = {};
    self.type = type;
    self.selectedSizes = [];
    self.elementsCount = 0;
    self.isCreatorScriptLoaded = false;
    self.isBuyLookPopupScriptLoaded = false;
    self.optionsFromWidget = {};
    self.special_look_id = null;
    self.special_look_image = null;
    self.ecommerce = new Ecommerce(widget.key, self);
    self.platform = null;
    self.productsInCollage = [];

    self._paramPromise = {};
    self.response = null;

    self.getText = (label) => {
        const texts = {
            ru: {
                [Texts.LOOK_BUY_BTN]: 'Купить',
                [Texts.LOOK_GOTO_LINK]: 'Больше образов',
                [Texts.SELECT_SIZE]: 'Выбрать размер',
                [Texts.TO_CART]: 'В корзину',
                [Texts.SUBSCRIBE_BTN]: 'Подписка',
                [Texts.ALL_TO_CART]: 'В корзину',
                [Texts.ITEMS]: 'Товаров',
                [Texts.CHANGE]: 'Заменить',
                [Texts.PLEASE_SELECT_SIZE]: 'Необходимо выбрать размер',
                [Texts.PLEASE_SELECT_SIZE_ALL]: 'Необходимо выбрать хотя бы один товар!',
                [Texts.MODEL]: 'образ<br/>на модели',
                [Texts.HOW_TO_WEAR_IT]: 'С чем носить',
                [Texts.PREV_LOOK]: 'Предыдущий образ',
                [Texts.NEXT_LOOK]: 'Следующий образ',
                [Texts.TOTAL_LOOK]: 'Дополни свой образ',
                [Texts.ONE_SIZE]: 'One Size',
                [Texts.SIMILAR_ITEMS]: 'Заменить товар',
                [Texts.UNSUBSCRIBE]: 'Вы подписаны',
                [Texts.SUBSCRIBE]: 'Подписаться',
                [Texts.MORE_ARTICLES]: 'Еще статьи',
                [Texts.OPEN_CART]: 'Перейти в корзину',
                [Texts.SHOW_MORE]: 'Описание товара',
                [Texts.ADD_TO_FAVORITES]: 'В избранное',
                [Texts.UNFAVORITE]: 'Избранное',
                [Texts.VIEW_LOOK]: 'Смотреть образ',
                [Texts.SIZES]: 'Размеры',
                [Texts.PRODUCT_INFO]: 'Информация о продукте',
                [Texts.SEE_FULL_LOOK]: 'Смотреть весь образ',
                [Texts.SIZE]: 'РАЗМЕР',
                [Texts.NOT_AVAILABLE]: 'Нет в наличии',
            },
            en: {
                [Texts.LOOK_BUY_BTN]: 'Buy the look',
                [Texts.LOOK_GOTO_LINK]: 'Additional Info',
                [Texts.SELECT_SIZE]: 'Select size',
                [Texts.TO_CART]: 'Add to cart',
                [Texts.SUBSCRIBE_BTN]: 'Subscribe',
                [Texts.ALL_TO_CART]: 'Add to cart',
                [Texts.ITEMS]: 'Items',
                [Texts.CHANGE]: 'Change',
                [Texts.PLEASE_SELECT_SIZE]: 'Please select size',
                [Texts.PLEASE_SELECT_SIZE_ALL]: 'Please select at least one item',
                [Texts.MODEL]: 'shop the<br/>model look',
                [Texts.HOW_TO_WEAR_IT]: 'how to<br/>wear it',
                [Texts.PREV_LOOK]: 'Prev look',
                [Texts.NEXT_LOOK]: 'Next look',
                [Texts.TOTAL_LOOK]: 'Total look',
                [Texts.ONE_SIZE]: 'One Size',
                [Texts.SIMILAR_ITEMS]: 'Similar Items',
                [Texts.UNSUBSCRIBE]: 'Unsubscribe',
                [Texts.SUBSCRIBE]: 'Subscribe',
                [Texts.MORE_ARTICLES]: 'More Articles',
                [Texts.OPEN_CART]: 'Open Cart',
                [Texts.SHOW_MORE]: 'Show More',
                [Texts.ADD_TO_FAVORITES]: 'Add to favorites',
                [Texts.UNFAVORITE]: 'Unfavorite',
                [Texts.VIEW_LOOK]: 'View look',
                [Texts.SIZES]: 'Sizes',
                [Texts.PRODUCT_INFO]: 'Product information',
                [Texts.SEE_FULL_LOOK]: 'See the full look',
                [Texts.SIZE]: 'SIZE',
                [Texts.NOT_AVAILABLE]: 'Not available',
            },
            ar: {
                [Texts.LOOK_BUY_BTN]: 'شراء نظرة',
                [Texts.LOOK_GOTO_LINK]: 'معلومات إضافية',
                [Texts.SELECT_SIZE]: 'إختر خياراً',
                [Texts.TO_CART]: 'إضافة إلى حقيبة التسوق',
                [Texts.SUBSCRIBE_BTN]: 'Subscribe',
                [Texts.ALL_TO_CART]: 'أضف إلى السلة',
                [Texts.ITEMS]: 'أغراض',
                [Texts.CHANGE]: 'التغيير',
                [Texts.PLEASE_SELECT_SIZE]: 'الرجاء اختيار المقاس',
                [Texts.PLEASE_SELECT_SIZE_ALL]: 'Please select at least one item',
                [Texts.MODEL]: 'تسوق نظرة النموذج',
                [Texts.HOW_TO_WEAR_IT]: 'كيفية ارتداء الحجاب',
                [Texts.PREV_LOOK]: 'النظرة السابقة',
                [Texts.NEXT_LOOK]: 'النظرة التالية',
                [Texts.TOTAL_LOOK]: 'نظرة كاملة',
                [Texts.ONE_SIZE]: 'One Size',
                [Texts.SIMILAR_ITEMS]: 'منتجات مشابهة',
                [Texts.UNSUBSCRIBE]: 'إلغاء الاشتراك',
                [Texts.SUBSCRIBE]: 'يشترك',
                [Texts.MORE_ARTICLES]: 'المزيد من المقالات',
                [Texts.OPEN_CART]: 'عربة التسوق المفتوحة',
                [Texts.SHOW_MORE]: 'أظهر المزيد',
                [Texts.ADD_TO_FAVORITES]: 'اضافة الى المفضلة',
                [Texts.UNFAVORITE]: 'غير مفضل',
                [Texts.VIEW_LOOK]: 'عرض الصورة',
                [Texts.SIZES]: 'المقاسات',
                [Texts.PRODUCT_INFO]: 'معلومات المنتج',
                [Texts.SEE_FULL_LOOK]: 'شاهد الصورة كاملة',
                [Texts.SIZE]: 'مقاس',
                [Texts.NOT_AVAILABLE]: 'غير متاح',
            },
            tr: {
                [Texts.LOOK_BUY_BTN]: 'inceleyin',
                [Texts.LOOK_GOTO_LINK]: 'Additional Info',
                [Texts.SELECT_SIZE]: 'Bedeninizi Seçiniz',
                [Texts.TO_CART]: 'Sepetinize Ekleyin',
                [Texts.SUBSCRIBE_BTN]: 'Subscribe',
                [Texts.ALL_TO_CART]: 'Seçili Ürünleri Ekleyin',
                [Texts.ITEMS]: 'Öğeler',
                [Texts.CHANGE]: 'Diğer Modeller',
                [Texts.PLEASE_SELECT_SIZE]: 'Lütfen beden seçiniz',
                [Texts.PLEASE_SELECT_SIZE_ALL]: 'Please select at least one item',
                [Texts.MODEL]: 'model görünümünü<br/>alişveri̇ş yapin',
                [Texts.HOW_TO_WEAR_IT]: 'nasıl<br/>giyilir',
                [Texts.PREV_LOOK]: 'Önceki bakış',
                [Texts.NEXT_LOOK]: 'Sonraki bakış',
                [Texts.TOTAL_LOOK]: 'Kombininizi tamamlayın',
                [Texts.ONE_SIZE]: 'STD',
                [Texts.SIMILAR_ITEMS]: 'Benzer ürünler',
                [Texts.UNSUBSCRIBE]: 'Abonelikten çık',
                [Texts.SUBSCRIBE]: 'Abone',
                [Texts.MORE_ARTICLES]: 'Daha Fazla Makale',
                [Texts.OPEN_CART]: 'Sepeti Aç',
                [Texts.SHOW_MORE]: 'Daha fazla göster',
                [Texts.ADD_TO_FAVORITES]: 'Favorilere ekle',
                [Texts.UNFAVORITE]: 'Favorilerden çıkar',
                [Texts.VIEW_LOOK]: 'Resmi görüntüle',
                [Texts.SIZES]: 'Boyutlar',
                [Texts.PRODUCT_INFO]: 'Ürün Bilgileri',
                [Texts.SEE_FULL_LOOK]: 'Resmin tamamını görün',
                [Texts.SIZE]: 'BOYUT',
                [Texts.NOT_AVAILABLE]: 'Müsait değil',
            },
            es: {
                [Texts.LOOK_BUY_BTN]: 'Compra el look',
                [Texts.LOOK_GOTO_LINK]: 'Información adicional',
                [Texts.SELECT_SIZE]: 'Selecciona el tamaño',
                [Texts.TO_CART]: 'Añadir a la cesta',
                [Texts.SUBSCRIBE_BTN]: 'Subscribe',
                [Texts.ALL_TO_CART]: 'Añadir a la cesta',
                [Texts.ITEMS]: 'Elementos',
                [Texts.CHANGE]: 'Cambiar',
                [Texts.PLEASE_SELECT_SIZE]: 'Por favor seleccione un tamaño',
                [Texts.PLEASE_SELECT_SIZE_ALL]: 'Por favor seleccione al menos un elemento',
                [Texts.MODEL]: 'compra el<br/>look del modelo',
                [Texts.HOW_TO_WEAR_IT]: 'cómo<br/>usarlo',
                [Texts.PREV_LOOK]: 'Look anterior',
                [Texts.NEXT_LOOK]: 'Siguiente look',
                [Texts.TOTAL_LOOK]: 'Mirada total',
                [Texts.ONE_SIZE]: 'One Size',
                [Texts.SIMILAR_ITEMS]: 'Artículos similares',
                [Texts.UNSUBSCRIBE]: 'Darse de baja',
                [Texts.SUBSCRIBE]: 'Suscribir',
                [Texts.MORE_ARTICLES]: 'Más artículos',
                [Texts.OPEN_CART]: 'Carrito abierto',
                [Texts.SHOW_MORE]: 'Mostrar más',
                [Texts.ADD_TO_FAVORITES]: 'Agregar a los favoritos',
                [Texts.UNFAVORITE]: 'Dejar de lado los favoritos',
                [Texts.VIEW_LOOK]: 'Ver imagen',
                [Texts.SIZES]: 'Tallas',
                [Texts.PRODUCT_INFO]: 'Información del producto',
                [Texts.SEE_FULL_LOOK]: 'Ver la imagen completa',
                [Texts.SIZE]: 'TAMAÑO',
                [Texts.NOT_AVAILABLE]: 'No disponible',
            },
        };

        const langCode = window.localStorage.getItem(Const.widgetLangCode);

        if (self.getWidgetParam('texts')) {
            let paramsText = self.getWidgetParam('texts');

            for (const lang in paramsText) {
                if (paramsText.hasOwnProperty(lang)) {

                    if (!texts.hasOwnProperty(lang)) {
                        texts[lang] = {};
                    }

                    for (const key in paramsText[lang]) {
                        if (paramsText[lang].hasOwnProperty(key)) {
                            texts[lang][key] = paramsText[lang][key];
                        }
                    }
                }
            }
        }

        if (langCode)
            return texts[langCode][label] || label;
        else
            return texts[self.getWidgetParam('language') || 'ru'][label] || label;
    };

    self.isMiuz = () => {
        return window.location.hostname.indexOf('miuz') !== -1;
    };

    self.getProductById = (block, productId) => {
        for (let i=0; i < block.products.length; i++) {
            if (block.products[i].wareId == productId)
                return block.products[i];
        }
        return null;
    }

    self.isPuma = () => {
        return window.location.hostname.indexOf('puma') !== -1;
    };

    self.init = (options) => {
        self.optionsFromWidget = options;

        return new HandledPromise((_, reject) => {
            new HandledPromise((resolve) => {
                switch (self.type) {
                    case 'favorites':
                        if (options.hasOwnProperty('product_ids')) {
                            resolve(options);
                        } else throw Error('You must provide product_ids to track this page as favorites page');
                        break;
                    case 'custom':
                        if (options.hasOwnProperty('code')) {
                            resolve(options);
                        } else throw Error('You must provide code to track this page as custom page');
                        break;
                    case 'brand':
                        if (options.hasOwnProperty('brand')) {
                            resolve(options);
                        } else throw Error('You must provide brand to track this page as brand page');
                        break;
                    case 'category':
                        if (options.hasOwnProperty('category_id')) {
                            options.yml_id = options.category_id;
                            resolve(options);
                        } else throw Error('You must provide category_id to track this page as category page');
                        break;
                    case 'product':
                        if (options.hasOwnProperty('product_id')) {
                            options.yml_id = options.product_id;

                            self._widget
                                .getSourceForYmlId(options.product_id)
                                .then((s) => {
                                    if (s && s.hasOwnProperty('source_page_type')) {
                                        options.source_block_type = s.source_block_type;
                                        options.source_page_type = s.source_page_type;
                                    }
                                    resolve(options);
                                })
                                .catch(() => { });
                        } else throw Error('You must provide product_id to track this page as product page');
                        break;
                    default:
                        resolve(options);
                        break;
                }

                /*Sentry.addBreadcrumb({
                    category: 'init',
                    message:
                        'Page init: ' +
                        JSON.stringify({
                            dom_element: !!self.containerEl,
                            type: self.type,
                            options: options,
                        }),
                    level: Sentry.Severity.Info,
                });*/
            })
                .then((options) => {
                    let timerId = 0;
                    if (options.fallback) {
                        timerId = options.fallback();
                    }
                    self.redraw(options)
                        .then((blocks) => {
                            if (timerId) {
                                clearTimeout(timerId);
                            }

                            if (blocks && typeof blocks.map === 'function') {
                                blocks.map((block) => {
                                    //BIND ANALYTICS
                                    try {
                                        const itemsObserver = new IntersectionObserver((entries, observer) => {
                                            entries.forEach(entry => {
                                                if (entry.isIntersecting) {
                                                    const item = entry.target;
                                                    let blockType = block.type;
                                                    if (block.type == 'looks')
                                                        blockType = 'looks_horizontal';
                                                    let product = self.getProductById(block, item.getAttribute('data-target-id'));

                                                    self._widget.analytics.sendEventViewProducts(blockType, self._widget.getPageSource(), [product], block.look_id, self.response);
                                                    observer.unobserve(entry.target);
                                                }
                                            });
                                        }, {
                                            root: null,
                                            rootMargin: '0px',
                                            threshold: 0.7
                                        });

                                        if (block && block.el) {
                                            let looksWidgets = block.el.querySelectorAll('.garderobo-widget-feed-item');
                                            looksWidgets.forEach(item => {
                                                if (item.getAttribute('data-target-id'))
                                                    itemsObserver.observe(item);
                                            });
                                        }  
                                    } catch (e) {
                                        console.error(e);
                                    }

                                    let sendData = (currentItems) => {
                                        const isCollagesWidget = currentItems && self.isCollagesWidget(currentItems.map((el) => el.data));

                                        if (isCollagesWidget) {
                                            const looks = currentItems.map((item) => item.data);
                                            const items = currentItems.map((item) => item.data.products).flat();
                                            self.ecommerce.impressionsLook(looks, items, self._widget.getPageSource(), block.type);
                                            self._widget.analytics.sendEventView(block.type, self._widget.getPageSource(), looks, undefined, self.response);
                                        } else {
                                            if (!block.look_id && !self.alreadySendViewEventHorizontal) {
                                                self._widget.analytics.sendEventView(block.type, self._widget.getPageSource(), block.look_id, undefined, self.response);
                                                self.alreadySendViewEventHorizontal = true;
                                            } else if (block.look_id) {
                                                self._widget.analytics.sendEventView('looks_horizontal', self._widget.getPageSource(), [{"look_id": block.look_id}], undefined, self.response);
                                            }

                                            //self.ecommerce.impressions(data.products, self._widget.getPageSource(), block.type, sentIds, block.look_id);
                                            //if (self._widget._callbacks.hasOwnProperty('showBlock')) {
                                            //    self._widget._callbacks.showBlock.apply(null, [data]);
                                            //}
                                        }
                                    };

                                    if (self.feedContainerSliders.hasOwnProperty(block.type))
                                        self.feedContainerSliders[block.type].registerSlideCallback((c) => sendData(c));

                                    let scrollListener = () => {
                                        if (!self.feedContainerSliders.hasOwnProperty(block.type)) return;
                                        let currentItems = self.feedContainerSliders[block.type].getCurrentItems();
                                        if (block && block.el) {
                                            let isInViewport =
                                                block.el.getBoundingClientRect().top < window.innerHeight &&
                                                block.el.getBoundingClientRect().top + block.el.clientHeight > 0;
                                            if (isInViewport) {
                                                sendData(currentItems);
                                                window.removeEventListener('scroll', scrollListener);
                                            }   
                                        }
                                    };
                                    window.addEventListener('scroll', scrollListener);
                                    scrollListener();
                                });

                                if (self._widget._callbacks.hasOwnProperty('pageInit')) {
                                    self._widget._callbacks.pageInit.apply(null, []);
                                }
                            }
                        })
                        .catch(reject);
                })
                .catch(reject);
        });
    };

    self.render = () => {
        self.widgetContainer = document.createElement('div');
        self.widgetContainer.classList.add(Const.widgetContainerClass);

        return self.widgetContainer;
    };

    self.getWidgetParam = (param) => {
        if (!self.widgetParams) {
            if (param == 'language')
                return 'ru';
            else if (param == 'slider_enabled')
                return true;
            else if (param == 'currency_sign')
                return ' ₽';
            return null;
        }
        return self.widgetParams.hasOwnProperty(param) ? self.widgetParams[param] : false;
    };

    self.addElementToWidgetContainer = (block, childElement, isMultipleLooks) => {
        let widgetElement = self.widgetContainer;
        //block.block_id = 'looks_block';   // for test

        if (block && block.block_id) {
            const customContainer = document.getElementById(block.block_id);
            if (customContainer) {
                const customWidgetContainer = document.createElement('div');
                customWidgetContainer.classList.add(Const.widgetContainerClass);
                customContainer.appendChild(customWidgetContainer);
                widgetElement = customWidgetContainer;
            }
        }

        if (isMultipleLooks) {
            const multipleAlreadyCreated = document.querySelector('.garderobo-multiple');
            if (multipleAlreadyCreated) {
                multipleAlreadyCreated.appendChild(childElement);
            } else {
                const multipleLooksContainer = document.createElement('div');
                multipleLooksContainer.classList.add('garderobo-multiple');
                widgetElement.appendChild(multipleLooksContainer);
                multipleLooksContainer.appendChild(childElement);
            }
        } else {
            widgetElement.appendChild(childElement);
        }
    };

    self.getHeaderHtml = (headerText) => {
        let stickyHeader = '';

        if (self.special_look_image === null) {
            stickyHeader = 'not_sticky';
        } else {
            stickyHeader = 'header_sticky';
        }

        let containerHeader = document.createElement('header');
        containerHeader.classList.add(Const.widgetFeedHeaderClass, stickyHeader);
        containerHeader.innerHTML = headerText;

        if (self.getWidgetParam('show_counters')) {
            containerHeader.classList.add(Const.widgetFeedHeaderWithCounterClass);
            const counterEl = document.createElement('div');
            counterEl.classList.add(Const.widgetFeedItemsCounterClass);

            const btnPrev = document.createElement('button');
            btnPrev.classList.add(Const.widgetFeedItemsCounterBtnClass);
            btnPrev.classList.add(Const.widgetFeedItemsCounterBtnPrevClass);

            const textEl = document.createElement('p');
            textEl.classList.add(Const.widgetFeedItemsCounterTextClass);
            const textCurrEl = document.createElement('span');
            textCurrEl.classList.add(Const.widgetFeedItemsCounterTextCurrentClass);
            const textTotalEl = document.createElement('span');
            textTotalEl.classList.add(Const.widgetFeedItemsCounterTextTotalClass);
            textEl.append(textCurrEl, ' / ', textTotalEl);

            const btnNext = document.createElement('button');
            btnNext.classList.add(Const.widgetFeedItemsCounterBtnClass);
            btnNext.classList.add(Const.widgetFeedItemsCounterBtnNextClass);

            counterEl.append(btnPrev, textEl, btnNext);

            containerHeader.appendChild(counterEl);
        }

        return containerHeader;
    };

    /** новый виджет со скроллом */
    self.drawScrollWidgetFeedItems = (name, type, items, per_page, block, additionalClasses) => {
        if (!self.feedContainerEls[type]) {
            self.feedContainerEls[type] = document.createElement('div');
            self.feedContainerEls[type].classList.add(Const.widgetFeedContainerClass);
            self.feedContainerEls[type].classList.add('garderobo-shopthemodellook');
            self.feedContainerEls[type].setAttribute('data-id', 'garderobo-shop-the-model');            
            self.addElementToWidgetContainer(block, self.feedContainerEls[type]);

            self.feedContainerEls[type].appendChild(self.getHeaderHtml(name));

            let containerItems = document.createElement('div');
            containerItems.classList.add(Const.widgetFeedItemContainerClass);

            self.feedContainerItems[type] = document.createElement('div');
            self.feedContainerItems[type].classList.add(Const.widgetFeedItems);

            containerItems.appendChild(self.feedContainerItems[type]);

            self.feedContainerEls[type].appendChild(containerItems);
        }

        // Items cleanup - todo
        Array.prototype.map.call(self.feedContainerItems[type].children, (child) => {
            if (child.classList.contains(Const.widgetItemClass)) self.feedContainerItems[type].removeChild(child);
        });


        const mainEl = document.createElement('div');
        mainEl.classList.add('garderobo-shopthemodellook__main-block');
        const mainElimage = document.createElement('img');
        mainElimage.classList.add('garderobo-shopthemodellook__image');
        mainElimage.src = self.special_look_image;
        mainEl.appendChild(mainElimage);
        const restEls = document.createElement('div');
        restEls.classList.add('garderobo-shopthemodellook__rest-block');
        self.feedContainerItems[type].appendChild(mainEl);
        self.feedContainerItems[type].appendChild(restEls);

        let renderedItems = self.renderFeedItems(type, items, false, block).map((item) => {
            // Hide Main Product from product list(right part of sticky)

            //if (!item.data.is_main_product) {
                restEls.appendChild(item.el);
                return item;
            //}
        });

        self.ecommerce.impressionsLook([block], items, 'product', block.type);
        self._widget.analytics.sendEventView(block.type, self._widget.getPageSource(), [block], undefined, self.response);

        return self.feedContainerEls[type];
    };

    self.modatechShopTheModel = (name, items, block) => {
        let container = document.querySelector('#modatech-shop-the-model');
        
        if (container) {
            let infoBlock = document.createElement('div');
            infoBlock.classList.add('modatech-shop-the-model-info');
    
            let title = document.createElement('h3');
            title.classList.add('modatech-shop-the-model-info-title');
            title.innerHTML = name;
    
            let productsCount = document.createElement('p');
            productsCount.classList.add('modatech-shop-the-model-info-products-count');
            productsCount.innerHTML =
                items.length + ' ' + 
                (items.length === 1 
                    ? 'товар' 
                    : (items.length >= 2 && items.length <= 4) 
                        ? 'товара' 
                        : 'товаров');
    
            let products = document.createElement('div');
            products.classList.add('modatech-shop-the-model-products');
    
            items.forEach(item => {
                let productBlock = document.createElement('div');
                productBlock.classList.add('modatech-shop-the-model-products-block');
                products.appendChild(productBlock);
    
                let productPic = document.createElement('img');
                productPic.classList.add('modatech-shop-the-model-products-block-img');
                productPic.src = item.picture;
    
                productBlock.appendChild(productPic)
            })
    
            let rightArrow = document.createElement('div');
            rightArrow.classList.add('modatech-shop-the-model-products-right-arrow');
            products.appendChild(rightArrow);
    
            container.appendChild(infoBlock);
            container.appendChild(products);
    
            infoBlock.appendChild(title);
            infoBlock.appendChild(productsCount);
    
    
            const popup = new LooksSimplePopup(block, self, true);
            self.widgetContainer.appendChild(popup.drawLooksPopup());
    
            container.addEventListener('click', () => {
                popup.openPopup();
            });
    
            return container;  
        }
    }

    self.drawFeedItems = (per_page, block, additionalClasses, isMultipleLooks, allBlocks, currentMultipleBlockIndex = 0) => {
        let currentSlider = null;
        let snapSlider = null;
        const name = block.name;
        const type = block.type;
        const items = block.products;

        if (!self.feedContainerEls[type]) {
            self.feedContainerEls[type] = document.createElement('div');
            self.feedContainerEls[type].classList.add(Const.widgetFeedContainerClass);
            self.feedContainerEls[type].classList.add(Const.widgetFeedContainerClass + '-' + type);
            self.addElementToWidgetContainer(block, self.feedContainerEls[type], isMultipleLooks);

            if (type === 'similar') {
                self.feedContainerEls[type].setAttribute('data-id', 'garderobo-similar-items');
            }

            self.feedContainerEls[type].appendChild(self.getHeaderHtml(name));

            let containerItems = document.createElement('div');
            containerItems.classList.add(Const.widgetFeedItemContainerClass);

            if (block.mobile_type === 'ver2') {
                containerItems.classList.add(Const.widgetFeedItemContainerMobileClass);
            }

            self.feedContainerItems[type] = document.createElement('div');
            self.feedContainerItems[type].classList.add(Const.widgetFeedItems);
            if (block.luxxy_style === 1) {
                self.feedContainerItems[type].classList.add('garderobo-widget-feed-items--luxxy-user');
            }

            // Additional class less-than-per-page
            if (items.length < per_page) {
                self.feedContainerItems[type].classList.add(Const.widgetFeedItemsLessThanPerPage);
            }

            containerItems.appendChild(self.feedContainerItems[type]);

            self.feedContainerEls[type].appendChild(containerItems);

            // Slider setup
            if (self.getWidgetParam('slider_enabled') && !self.getWidgetParam('snap_slider')) {
                currentSlider = self.feedContainerSliders[type] = new Slider(
                    self.feedContainerItems[type],
                    containerItems,
                    block.slider_type,
                    self.getWidgetParam('slider_infinite'),
                    self._widget.initOptions.lang_code,
                    self
                );

                self.feedContainerEls[type].appendChild(currentSlider.leftControl);
                self.feedContainerEls[type].appendChild(currentSlider.rightControl);

                if ((block.slider_type == 'look') && (allBlocks.length == 1)) {
                    const counterElement = document.querySelector('.garderobo-widget-feed-items-counter');
                    if (counterElement) {
                        counterElement.style.display = 'none';
                    }
                }
            }

            if (self.getWidgetParam('snap_slider')) {
                snapSlider = self.feedContainerSliders[type] = new SliderSnap(containerItems);
                self.feedContainerEls[type].appendChild(snapSlider.leftControl);
                self.feedContainerEls[type].appendChild(snapSlider.rightControl);
            }

            // set/unset slider for desktop/mobile
            if (block.mobile_type === 'ver2' && !self.getWidgetParam('snap_slider')) {
                const removeArrows = () => {
                    if (currentSlider.leftControl && currentSlider.leftControl.parentNode) {
                        currentSlider.leftControl.parentNode.removeChild(currentSlider.leftControl);
                    }
                    if (currentSlider.rightControl && currentSlider.rightControl.parentNode) {
                        currentSlider.rightControl.parentNode.removeChild(currentSlider.rightControl);
                    }
                };

                if (window.screen.width < 1024 && currentSlider) {
                    currentSlider.turnOff();
                    removeArrows();
                }

                window.addEventListener('resize', (e) => {
                    if (e.target.outerWidth >= 1024) {
                        if (currentSlider) {
                            currentSlider.turnOn();
                            if (currentSlider.leftControl && !currentSlider.leftControl.parentNode) {
                                self.feedContainerEls[type].appendChild(currentSlider.leftControl);
                            }
                            if (currentSlider.rightControl && !currentSlider.rightControl.parentNode) {
                                self.feedContainerEls[type].appendChild(currentSlider.rightControl);
                            }
                        }
                    } else {
                        if (currentSlider) {
                            currentSlider.turnOff();
                            removeArrows();
                        }
                    }
                });
            }
        }

        // Items cleanup
        Array.prototype.map.call(self.feedContainerItems[type].children, (child) => {
            if (child.classList.contains(Const.widgetItemClass)) self.feedContainerItems[type].removeChild(child);
        });

        let renderedItems = self.renderFeedItems(type, items, false, block).map((item) => {
            if (item && item.el)
                self.feedContainerItems[type].appendChild(item.el);
            return item;
        });

        // Slider init
        if (self.getWidgetParam('slider_enabled') && !self.getWidgetParam('snap_slider')) {
            currentSlider.init(renderedItems);
        }


        if (self.getWidgetParam('snap_slider')) {
            snapSlider.sliderInit(renderedItems);
        }

        if (block.show_type !== BlockShowType.COLLAGE_BY_TEMPLATES && allBlocks.length > 1) {
            const bottomEl = document.createElement('div');

            const btnPrevLook = document.createElement('button');
            btnPrevLook.type = 'button';
            btnPrevLook.innerHTML = self.getText(Texts.PREV_LOOK);
            btnPrevLook.classList.add('garderobo-multiple-btn');
            btnPrevLook.classList.add('garderobo-multiple-btn-prev');
            btnPrevLook.addEventListener('click', () => {
                self.feedContainerEls[type].remove();
                self.feedContainerEls[type] = null;
                let block = allBlocks[currentMultipleBlockIndex - 1] || allBlocks[allBlocks.length - 1];
                self.drawFeedItems(
                    per_page,
                    block,
                    additionalClasses,
                    isMultipleLooks,
                    allBlocks,
                    allBlocks[currentMultipleBlockIndex - 1] ? currentMultipleBlockIndex - 1 : allBlocks.length - 1
                );

                self._widget.analytics.sendEventView('looks_horizontal', self._widget.getPageSource(), [{"look_id": block.look_id}], undefined, self.response);
            });

            const btnNextLook = document.createElement('button');
            btnNextLook.type = 'button';
            btnNextLook.innerHTML = self.getText(Texts.NEXT_LOOK);
            btnNextLook.classList.add('garderobo-multiple-btn');
            btnNextLook.classList.add('garderobo-multiple-btn-next');
            btnNextLook.addEventListener('click', () => {
                self.feedContainerEls[type].remove();
                self.feedContainerEls[type] = null;
                let block = allBlocks[currentMultipleBlockIndex + 1] || allBlocks[0];
                self.drawFeedItems(
                    per_page,
                    block,
                    additionalClasses,
                    isMultipleLooks,
                    allBlocks,
                    allBlocks[currentMultipleBlockIndex + 1] ? currentMultipleBlockIndex + 1 : 0
                );

                self._widget.analytics.sendEventView('looks_horizontal', self._widget.getPageSource(), [{"look_id": block.look_id}], undefined, self.response);
            });

            bottomEl.classList.add('garderobo-multiple-controls');
            bottomEl.appendChild(btnPrevLook);
            bottomEl.appendChild(btnNextLook);

            self.feedContainerEls[type].appendChild(bottomEl);
        }

        return self.feedContainerEls[type];
    };

    /** @param block - 1st block from data.blocks */

    self.drawFeedItemsWithCollages = (_items, type, per_page, isForPopup, block, data, title) => {
        const items = [];
        let currentSlider = null;
        let snapSlider = null;
        let containerItems = document.createElement('div');
        containerItems.classList.add(Const.widgetFeedItemContainerClass);
        containerItems.classList.add('garderobo-widget-feed-item-look');

        const preparedItems = _items
            .map((item, i) => self.renderFeedItemWithCollage(item, false, {}, isForPopup, i === 0 && data && data.special_look_id))
            .filter((item, i) => {
                if (item) {
                    items.push(_items[i]);
                }
                return !!item;
            });

        self.feedContainerEls[type] = undefined;

        if (preparedItems.length <= 5) {
            containerItems.classList.add('garderobo-widget-feed-item-look-centered')
        }


        if (!self.feedContainerEls[type]) {
            self.feedContainerEls[type] = document.createElement('div');
            self.feedContainerEls[type].classList.add(Const.widgetFeedContainerClass);

            if (!isForPopup) {
                self.addElementToWidgetContainer(block, self.feedContainerEls[type]);
                self.feedContainerEls[type].setAttribute('data-id', 'garderobo-total-looks');
            }


            const headerTitle = title ? title : items[0].name;  
            self.feedContainerEls[type].appendChild(self.getHeaderHtml(headerTitle));

            self.feedContainerItems[type] = document.createElement('div');
            self.feedContainerItems[type].classList.add(Const.widgetFeedItems);

            // Additional class less-than-per-page
            if (preparedItems.length < per_page) {
                self.feedContainerItems[type].classList.add(Const.widgetFeedItemsLessThanPerPage);
            }

            containerItems.appendChild(self.feedContainerItems[type]);
            self.feedContainerEls[type].appendChild(containerItems);

            // Slider setup
            if (self.getWidgetParam('slider_enabled')) {
                currentSlider = self.feedContainerSliders[type] = new Slider(
                    self.feedContainerItems[type],
                    containerItems,
                    block.slider_type,
                    isForPopup || self.getWidgetParam('slider_infinite'),
                    self._widget.initOptions.lang_code,
                    self
                );

                self.feedContainerEls[type].appendChild(currentSlider.leftControl);
                self.feedContainerEls[type].appendChild(currentSlider.rightControl);
            }            
            
            if (self.getWidgetParam('snap_slider')) {
                snapSlider = self.feedContainerSliders[type] = new SliderSnap(containerItems);
                self.feedContainerEls[type].appendChild(snapSlider.leftControl);
                self.feedContainerEls[type].appendChild(snapSlider.rightControl);        
            }

            // Slider turn off for mobile 
            if (block.mobile_type === 'ver2') {
                const removeArrows = () => {
                    if (currentSlider.leftControl && currentSlider.leftControl.parentNode) {
                        currentSlider.leftControl.parentNode.removeChild(currentSlider.leftControl);
                    }
                    
                    if (currentSlider.rightControl && currentSlider.rightControl.parentNode) {
                        currentSlider.rightControl.parentNode.removeChild(currentSlider.rightControl);
                    }
                };

                if (window.screen.width < 1024 && !isForPopup) {
                    currentSlider.turnOff();
                    removeArrows(); 
                }
            }

            // setup popup window
            let collagesPopup;
            if (self.getWidgetParam('slider_enabled'))
                collagesPopup = new CollagesPopup(items, self.feedContainerSliders[type], self, null);

            if (self.getWidgetParam('snap_slider'))
                collagesPopup = new CollagesPopup(items, null, self, self.feedContainerSliders[type]);

            self.collagesPopup = collagesPopup;
            if (!self.feedContainerItems['looks2']) {
                const newItems = self.drawFeedItemsWithCollages(items, 'looks2', 1, true, block);

                //self.widgetContainer.appendChild(collagesPopup.drawLooksPopup(newItems));

                const widgetContainerForPopup = document.createElement('div');
                widgetContainerForPopup.classList.add('garderobo-widget-container');
                widgetContainerForPopup.classList.add('garderobo-widget-container-for-popup');
                if (self.containerEl)
                    widgetContainerForPopup.setAttribute('data-container-id', self.containerEl.getAttribute('id'));
                widgetContainerForPopup.style.display = 'None';
                widgetContainerForPopup.appendChild(collagesPopup.drawLooksPopup(newItems));
                document.body.appendChild(widgetContainerForPopup);

                const productPopup = new ProductPopup(block, self);
                const widgetContainerForProductPopup = document.createElement('div');
                widgetContainerForProductPopup.classList.add('garderobo-widget-container');
                widgetContainerForProductPopup.classList.add('garderobo-widget-container-for-popup', 'garderobo-widget-container-for-product-popup');
                widgetContainerForProductPopup.style.display = 'None';

                widgetContainerForProductPopup.appendChild(productPopup.drawProductPopup());
                document.body.appendChild(widgetContainerForProductPopup);
            }
        }

        // Items cleanup
        Array.prototype.map.call(self.feedContainerItems[type].children, (child) => {
            if (child.classList.contains(Const.widgetItemClass)) self.feedContainerItems[type].removeChild(child);
        });

        let renderedItems = preparedItems.map((item) => {
            self.feedContainerItems[type].appendChild(item.el);
            return item;
        });

        if (block.mobile_type === 'ver3' && !self.getWidgetParam('snap_slider')) {
            let thumbnailWrapper = document.createElement('div');
            thumbnailWrapper.classList.add('garderobo-widget-feed-thumbnail');
            containerItems.appendChild(thumbnailWrapper);

            renderedItems.forEach(item => {
                let thumbnailItem = document.createElement('div');
                thumbnailItem.classList.add('garderobo-widget-feed-thumbnail-item');
                thumbnailItem.setAttribute('data-look', item.data.look_id);

                let sliderItem = document.createElement('div');
                sliderItem.classList.add('garderobo-widget-feet-slider-item');

                if (item.data.products && item.data.products.length) {
                    item.data.products.forEach(product => {
                        let productImg = document.createElement('img');
                        productImg.src = product.picture;
                        
                        if (product.collage_data) {
                            productImg.style.backgroundPositionX = product.collage_data.backgroundPositionX;
                            productImg.style.backgroundPositionY = product.collage_data.backgroundPositionY;
                            productImg.style.height = `${product.collage_data.height}%`;
                            productImg.style.left = `${product.collage_data.left}%`;
                            productImg.style.top = `${product.collage_data.top}%`;
                            productImg.style.width = `${product.collage_data.width}%`;
                            productImg.style.zIndex = product.collage_data.zIndex;
                        }

                        sliderItem.appendChild(productImg);
                        thumbnailItem.appendChild(sliderItem);
                    })
                }

                thumbnailWrapper.appendChild(thumbnailItem);
            })

            const allThumbnailItems = document.querySelectorAll('.garderobo-widget-feed-thumbnail-item');
            const lookItems = document.querySelector('.garderobo-widget-feed-item-look .garderobo-widget-feed-items');

            const leftBtn = document.querySelector('.garderobo-widget-control-left');
            const rightBtn = document.querySelector('.garderobo-widget-control-right:not(.garderobo-widget-feed-similar .garderobo-widget-control-right)');

            const updateThumbnails = (slideIndex) => {
                const allThumbnailItemsArray = [...allThumbnailItems];
                const thumbnailContainer = document.querySelector('.garderobo-widget-feed-thumbnail');

                allThumbnailItemsArray.forEach((thumbnail, i) => {
                    if (i === slideIndex) {
                        thumbnail.classList.add('garderobo-widget-feed-thumbnail-item-active');

                        const thumbnailContainerRect = thumbnailContainer.getBoundingClientRect();
                        const thumbnailItemRect = thumbnail.getBoundingClientRect();

                        if (thumbnailItemRect.right > thumbnailContainerRect.right) {
                            thumbnailContainer.scrollLeft += (thumbnailItemRect.right - thumbnailContainerRect.right + 15);
                        }

                        if (thumbnailItemRect.left < thumbnailContainerRect.left) {
                            thumbnailContainer.scrollLeft -= (thumbnailContainerRect.left - thumbnailItemRect.left + 15);
                        }
                    } else {
                        thumbnail.classList.remove('garderobo-widget-feed-thumbnail-item-active');
                    }
                })
            }

            rightBtn.addEventListener('click', function() {
                let slideIndex = currentSlider.slideIndex + 1;
                updateThumbnails(slideIndex);
            });

            leftBtn.addEventListener('click', function() {
                let slideIndex = currentSlider.slideIndex - 1;
                updateThumbnails(slideIndex);
            });

            document.addEventListener('swipeRight', function () {
                let slideIndex = currentSlider.slideIndex + 1;
                updateThumbnails(slideIndex);
            });

            document.addEventListener('swipeLeft', function () {
                let slideIndex = currentSlider.slideIndex - 1;
                updateThumbnails(slideIndex);
            });

            if (lookItems) {
                const lookItemsWidth = lookItems.offsetWidth;

                if (allThumbnailItems.length > 0) {
                    allThumbnailItems[0].classList.add('garderobo-widget-feed-thumbnail-item-active');
                }

                allThumbnailItems.forEach((thumbnail, index) => {
                    thumbnail.addEventListener('click',  function() {
                        allThumbnailItems.forEach(item => item.classList.remove('garderobo-widget-feed-thumbnail-item-active'));
                        this.classList.add('garderobo-widget-feed-thumbnail-item-active');

                        lookItems.style.left = `-${lookItemsWidth * index}px`;

                        if (index === 0) {
                            leftBtn.classList.add('garderobo-widget-muted')
                        } else {
                            leftBtn.classList.remove('garderobo-widget-muted')
                        }

                        currentSlider.slideIndex = index;
                        currentSlider.slidePosition = index;
                    })
                })
            }
        }

        self.elementsCount = renderedItems.length;

        // Slider init
        //"Cannot read properties of undefined (reading 'yml_id')"
        if (self.getWidgetParam('slider_enabled')) {
            self.feedContainerSliders[type].init(renderedItems);
        }

        if (self.getWidgetParam('snap_slider')) {            
            self.feedContainerSliders[type].sliderInit(renderedItems);
        }

        return self.feedContainerEls[type];
    };

    self.drawFeedItems2 = (name, type, items, per_page, block, additionalClasses) => {
        let containerItems = document.createElement('div');
        containerItems.classList.add(Const.widgetFeedItemContainerNewClass);

        if (!self.feedContainerEls[type]) {
            self.feedContainerEls[type] = document.createElement('div');
            self.feedContainerEls[type].classList.add(Const.widgetFeedContainerClass);
            self.addElementToWidgetContainer(block, self.feedContainerEls[type]);

            self.feedContainerEls[type].appendChild(self.getHeaderHtml(name));

            self.feedContainerItems[type] = document.createElement('div');
            self.feedContainerItems[type].classList.add(Const.widgetFeedItems);

            // Additional class less-than-per-page
            if (items.length < per_page) {
                self.feedContainerItems[type].classList.add(Const.widgetFeedItemsLessThanPerPage);
            }

            containerItems.appendChild(self.feedContainerItems[type]);
            self.feedContainerEls[type].appendChild(containerItems);
        }

        // Items cleanup
        Array.prototype.map.call(self.feedContainerItems[type].children, (child) => {
            if (child.classList.contains(Const.widgetItemClass)) self.feedContainerItems[type].removeChild(child);
        });

        let renderedItems = self.renderFeedItems(type, items, true, {}).map((item) => {
            self.feedContainerItems[type].appendChild(item.el);
            return item;
        });

        self.elementsCount = renderedItems.length;

        let mainRendererItem = renderedItems.find((item) => item.data.hasOwnProperty('is_main_product') && item.data.is_main_product === 1);

        if (!mainRendererItem) {
            mainRendererItem = renderedItems[0];
        }

        if (mainRendererItem) {
            const mainItemContainer = document.createElement('div');
            mainItemContainer.classList.add('garderobo-widget-feed-main-item-container');

            const mainItemImg = document.createElement('img');
            mainItemImg.src = mainRendererItem.data.picture;

            mainItemContainer.appendChild(mainItemImg);

            containerItems.prepend(mainItemContainer);
        }

        let sum = 0;

        renderedItems.map((renderedItem) => {
            sum += renderedItem.data.price;
        });

        const addAllProductWrap = document.createElement('div');
        const sumPrice = document.createElement('div');
        const addAllProductBtn = document.createElement('div');

        addAllProductWrap.classList.add(Const.widgetAddAllProductWrapClass);

        sumPrice.innerHTML = 'Сумма: ' + self.priceFormatter(sum);

        addAllProductBtn.classList.add(Const.widgetAddToCartClass);
        addAllProductBtn.classList.add('disabled');
        addAllProductBtn.setAttribute('id', 'add-all-product-btn');
        addAllProductBtn.innerHTML = 'Add all products to cart';

        addAllProductBtn.addEventListener('click', (e) => {
            if (self._widget._callbacks.hasOwnProperty('addToCartAll')) {
                self._widget._callbacks.addToCartAll.apply(null, [self.selectedSizes]);
            }
        });

        addAllProductWrap.appendChild(sumPrice);
        addAllProductWrap.appendChild(addAllProductBtn);

        self.feedContainerItems[type].appendChild(addAllProductWrap);

        return self.feedContainerEls[type];
    };

    self.renderFeedItems = (type, items, newView = false, block) => {
        if (block.max_items_count)
            items = items.slice(0, block.max_items_count);

        return items.map((item, i) => self.prepareFeedItem(type, item, newView, block, i));
    };

    self.prepareFeedItem = (type, item, newView = false, block, itemIndex) => {
        try {
            let feedItemRender = self.renderFeedItem(item, newView, block, itemIndex);

            let clickHandler = (e) => {

                if (self._widget.initOptions.spa) {
                    e.preventDefault();

                    if (self._widget._callbacks.hasOwnProperty('linkClicked')) {
                        self._widget._callbacks.linkClicked.apply(null, [item.link]);
                    }
                }
                
                new HandledPromise((_, reject) => self._widget.setSourceForItem(item, type, self.type).catch(reject));

                const parentElement = e.target.parentElement;
                const parentElements = parentElement.parentElement.children;
                const convertedElements = Array.prototype.slice.call(parentElements);
                const elementIndex = convertedElements.indexOf(parentElement) + 1;

                if (!block.look_id)
                    self._widget.analytics.sendEventWidgetClick(item.wareId, type, self._widget.getPageSource(), null, item.price, item.old_price, self.response);

                //if (block.show_type != "horizontal_with_popup")
                self.ecommerce.productClick(item, block.type, self._widget.getPageSource(), elementIndex, block.look_id);

                if (self._widget._callbacks.hasOwnProperty('productClick')) {
                    let data = {
                        product: item,
                        block_type: type,
                        page_type: self.type,
                    };
                    self._widget._callbacks.productClick.apply(null, [data]);
                    return true;
                }

                return true;
            };

            // Like & dislike handler
            if (self.getWidgetParam('like_dislike_enabled')) {
                let reaction = (type) => {
                    let productId = item.yml_id;
                    return () => {
                        if (self._widget._callbacks.hasOwnProperty('productReaction')) {
                            self._widget._callbacks.productReaction.apply(null, [{ productId: productId, reaction: type }]);
                        }

                        new HandledPromise((_, reject) => self._widget.widgetApi.productAction(productId, type).catch(reject));

                        return false;
                    };
                };

                feedItemRender.like.addEventListener('click', reaction('like'));
                feedItemRender.dislike.addEventListener('click', reaction('dislike'));
            }

            // UTM stuff
            let link = item.link;
            let template = self.getWidgetParam('utm_template');
            if (template) {
                link +=
                    (link.indexOf('?') === -1 ? '?' : '&') +
                    template.replace('{block_type}', type).replace('{page_type}', self.type).replace('{yml_id}', item.yml_id);
            }
            const url = block.url && self.isMiuz() ? block.url : link;
            feedItemRender.control.href = url;
            feedItemRender.productName.href = url;

            // #if process.env.NODE_ENV !== 'production'
//             // feedItemRender.control.title = JSON.stringify(item.debug);
            // #endif

            // Click handlers
            feedItemRender.control.addEventListener('click', clickHandler);
            feedItemRender.control.addEventListener('auxclick', clickHandler);

            feedItemRender.productName.addEventListener('click', clickHandler);
            feedItemRender.productName.addEventListener('auxclick', clickHandler);

            // Hover behavior
            if (self.getWidgetParam('hover_alt_enabled')) {
                if (item.hasOwnProperty('pictures') && item.pictures.length > 0) {
                    // Preloading picture
                    let preloadImg = document.createElement('div');
                    preloadImg.style.width = '1px';
                    preloadImg.style.height = '1px';
                    preloadImg.style.position = 'absolute';
                    preloadImg.style.background = 'url(' + item.pictures[0] + ') no-repeat -9999px -9999px';

                    feedItemRender.el.appendChild(preloadImg);

                    // Event listeners
                    feedItemRender.control.addEventListener('mouseover', () => {
                        feedItemRender.control.style.backgroundImage = 'url(' + item.pictures[0] + ')';
                    });
                    feedItemRender.control.addEventListener('mouseout', () => {
                        feedItemRender.control.style.backgroundImage = 'url(' + item.picture + ')';
                    });
                }
            }

            return { el: feedItemRender.el, data: item };
        } catch (e) {
            new HandledPromise((_, reject) => reject(e));
        }
    };

    self.priceFormatter = (price) => {
        const priceFormat = self.getWidgetParam('price_format');
        //{ format: '{{price}} rub', digits: 2, thousandsSeparator: ' ', decimalSeparator: '.' }; //
        if (priceFormat) {
            let priceFormatted = priceFormat.digits ? (+price).toFixed(priceFormat.digits) : (+price).toString();
            if (priceFormat.decimalSeparator) {
                priceFormatted = priceFormatted.replace('.', priceFormat.decimalSeparator);
            }
            if (priceFormat.thousandsSeparator) {
                priceFormatted = priceFormatted.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, `$1${priceFormat.thousandsSeparator}`);
            }

            priceFormatted = priceFormat.format.replace('{{price}}', priceFormatted);

            return priceFormatted;
        } else {
            const priceStr = self.isPuma() ? price.toFixed(2).replace('.', ',') : Number(price).toString();
            return priceStr.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1\u00A0') + self.getWidgetParam('currency_sign');
        }
    };

    self.setProductAttributes = (feedItemEl, item, itemIndex) => {
        if (self.getWidgetParam('show_in_dom')) {
            feedItemEl.setAttribute('data-id', item.wareId);
            feedItemEl.setAttribute('data-name', item.name);
            feedItemEl.setAttribute('data-price', item.price);
            feedItemEl.setAttribute('data-list', item.category_group ? 'Garderobo - product looks' : 'Garderobo - product similar');
            feedItemEl.setAttribute('data-brand', item.brand);
            feedItemEl.setAttribute('data-category', item.vendor_category && item.vendor_category.name);
            feedItemEl.setAttribute('data-position', itemIndex);
        }
    };

    /** карточка продукта (в слайдере) */
    self.renderFeedItem = (item, newView = false, block, itemIndex) => {
        const isLuxxyAvatarMode = block.luxxy_style === 1;
        let feedItem = document.createElement('div');
        let feedItemInner = document.createElement('div');
        feedItem.classList.add(Const.widgetItemClass);
        feedItem.setAttribute('data-target-id', item.wareId);
        feedItemInner.classList.add(Const.widgetItemClass + "_inner");

        if (block.type == 'similar_model') {
            if (item.wareId == self.product_id)
                feedItem.classList.add('active');
        }

        feedItem.appendChild(feedItemInner);

        self.setProductAttributes(feedItem, item, itemIndex);

        if (isLuxxyAvatarMode) {
            feedItem.classList.add('garderobo-widget-feed-item--luxxy-user');
            const avatarBlock = document.createElement(item.params.user_url ? 'a' : 'div');
            avatarBlock.classList.add('garderobo-widget-avatar-block');
            if (item.params.user_url) {
                avatarBlock.setAttribute('href', item.params.user_url);
                avatarBlock.setAttribute('target', '_blank');
            }
            feedItemInner.appendChild(avatarBlock);

            const avatarPicContainer = document.createElement('div');
            avatarPicContainer.classList.add('garderobo-widget-avatar-pic-container');
            avatarBlock.appendChild(avatarPicContainer);

            const avatarPic = document.createElement('div');
            avatarPic.classList.add('garderobo-widget-avatar-pic');
            avatarPicContainer.appendChild(avatarPic);
            avatarPic.style.backgroundImage = `url(${item.params.user_image})`;

            const starSvg = `<svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_13311_133" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="3" y="3" width="18" height="18">
                <path d="M12 17.5196L16.15 20.0296C16.91 20.4896 17.84 19.8096 17.64 18.9496L16.54 14.2296L20.21 11.0496C20.88 10.4696 20.52 9.36958 19.64 9.29958L14.81 8.88958L12.92 4.42958C12.58 3.61958 11.42 3.61958 11.08 4.42958L9.18998 8.87958L4.35998 9.28958C3.47998 9.35958 3.11998 10.4596 3.78998 11.0396L7.45998 14.2196L6.35998 18.9396C6.15998 19.7996 7.08998 20.4796 7.84998 20.0196L12 17.5196Z" fill="black"/>
                </mask>
                <g mask="url(#mask0_13311_133)">
                <rect width="24" height="24" fill="#2CBEB6"/>
                </g>
            </svg>`;

            const starAvatarSvg = `
            <svg width="100" height="80" viewBox="0 0 100 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="50" cy="26" r="10" fill="white"/>
                <g filter="url(#filter0_dd_13397_164)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M40 26C40 20.4772 44.4772 16 50 16C55.5228 16 60 20.4772 60 26C60 31.5228 55.5228 36 50 36C44.4772 36 40 31.5228 40 26ZM50 20.6667C50.2585 20.6667 50.4937 20.8161 50.6035 21.0501L51.8945 23.8005L54.7673 24.2391C55.0143 24.2768 55.2195 24.4495 55.2989 24.6865C55.3782 24.9234 55.3184 25.1848 55.1438 25.3637L53.0455 27.5143L53.5423 30.5593C53.5834 30.8113 53.477 31.0646 53.2683 31.2117C53.0596 31.3587 52.7852 31.3736 52.5618 31.2501L50 29.834L47.4382 31.2501C47.2148 31.3736 46.9405 31.3587 46.7317 31.2117C46.523 31.0646 46.4166 30.8113 46.4577 30.5593L46.9545 27.5143L44.8562 25.3637C44.6817 25.1848 44.6218 24.9234 44.7012 24.6865C44.7805 24.4495 44.9857 24.2768 45.2327 24.2391L48.1055 23.8005L49.3965 21.0501C49.5064 20.8161 49.7415 20.6667 50 20.6667Z" fill="#32B0B0"/>
                </g>
                <defs>
                <filter id="filter0_dd_13397_164" x="0" y="-20.6667" width="100" height="100" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="3.33333"/>
                <feGaussianBlur stdDeviation="20"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13397_164"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="1.66667"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow_13397_164" result="effect2_dropShadow_13397_164"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_13397_164" result="shape"/>
                </filter>
                </defs>
            </svg>
            `;

            const avatarPicStar = document.createElement('div');
            avatarPicStar.classList.add('garderobo-widget-avatar-pic-star');
            avatarPicStar.innerHTML = starAvatarSvg;
            avatarPicContainer.appendChild(avatarPicStar);

            const avatarDataBlock = document.createElement('div');
            avatarDataBlock.classList.add('garderobo-widget-avatar-data-block');
            avatarBlock.appendChild(avatarDataBlock);

            const avatarName = document.createElement('div');
            avatarName.classList.add('garderobo-widget-avatar-name');
            avatarName.innerHTML = item.params.user_name;
            avatarName.setAttribute('title', item.params.user_name);
            avatarDataBlock.appendChild(avatarName);

            const avatarStars = document.createElement('div');
            avatarStars.classList.add('garderobo-widget-avatar-stars');
            for (let i = 0; i < 5; i++) {
                const avatarStar = document.createElement('div');
                avatarStar.innerHTML = starSvg;
                avatarStars.appendChild(avatarStar);
            }
            avatarDataBlock.appendChild(avatarStars);

            const avatarStarsText = document.createElement('span');
            avatarStarsText.classList.add('garderobo-widget-avatar-stars-text');
            avatarStarsText.innerHTML = item.params.user_rating;
            avatarStars.appendChild(avatarStarsText);
        }

        if (block.no_info) {
            feedItem.classList.add(Const.widgetItemMinWidthClass);
        }

        let productHeader = document.createElement('div');
        productHeader.classList.add(Const.widgetHeaderClass);

        if (item.title) {
            let productHeaderContent = document.createElement('div');
            productHeaderContent.innerHTML = item.title;
            productHeaderContent.classList.add(Const.widgetHeaderHasContentClass);
            productHeader.appendChild(productHeaderContent);
        }

        //block.show_type = BlockShowType.HORIZONTAL_WITH_POPUP; // todo - del
        const isLooksSimplePopup = block.show_type == BlockShowType.HORIZONTAL_WITH_POPUP;
        const isLooksWithSimilarsPopup = block.show_type == BlockShowType.HORIZONTAL_WITH_POPUP_AND_SIMILARS;
        const isPopup = isLooksSimplePopup || isLooksWithSimilarsPopup;

        const isNoLink = (block.no_links && !(block.url && self.isMiuz())) || isPopup;
        let productImg = isNoLink ? document.createElement('div') : document.createElement('a');
        productImg.classList.add(Const.widgetImageClass);
        if (isPopup) {
            productImg.classList.add(`${Const.widgetImageClass}--button`);
        }

        productImg.style.backgroundImage = 'url(' + item.picture + ')';

        productImg.addEventListener('click', function(e) {
            if (self._widget.initOptions.spa) {
                e.preventDefault();
            }
        })

        /*const imageBlockTest = document.querySelector(".garderobo-widget-feed-container:not(.garderobo-widget-feed-item-look)");

        if (imageBlockTest) {
            imageBlockTest.addEventListener("click", () => {
                if (isLooksSimplePopup) {
                    const popup = new LooksSimplePopup(block, self);
                    if (!self.feedContainerItems['looks-with-replacement-popup']) {
                        self.widgetContainer.appendChild(popup.drawLooksPopup());
                    }
                    console.log('CLICK TO WIDGET 2');
                    popup.openPopup();
                }
            })
        }*/

        if (isPopup) {
            productImg.addEventListener('click', () => {
                if (isLooksWithSimilarsPopup) {
                    const popup = new LooksWithSimilarsPopup(block, self);
                    if (!self.feedContainerItems['looks-popup']) {
                        self.widgetContainer.appendChild(popup.drawLooksPopup());
                    }
                    popup.openPopup(item);
                } else if (isLooksSimplePopup) {
                    const popup = new LooksSimplePopup(block, self);
                    if (!self.feedContainerItems['looks-with-replacement-popup']) {
                        self.widgetContainer.appendChild(popup.drawLooksPopup());
                    }
                    popup.openPopup();
                }
            });
        }

        let productFooter = document.createElement('div');
        let productTitlePrice = document.createElement('div');
        productTitlePrice.classList.add('garderobo-title-price-wrap');
        productFooter.classList.add(Const.widgetFooterClass);

        let productName = isNoLink ? document.createElement('div') : document.createElement('a');
        productName.classList.add(Const.widgetNameClass);
        productName.innerHTML = item.name; // @TODO: Name here should be

        let brandName = undefined;

        if (window.location.hostname.indexOf('keng.ru') !== -1 || newView || item.brand) {
            brandName = document.createElement('div');
            brandName.classList.add(Const.widgetBrandClass);
            brandName.innerHTML = item.brand;
        }

        let priceContainer = document.createElement('div');
        priceContainer.classList.add(Const.widgetPriceContainerClass);

        if (self.getWidgetParam('use_cardprice') && self._widget.user_id && item.offers_props_by_yml_id) {
            const keys = Object.keys(item.offers_props_by_yml_id);
            if (keys.length)
                item.price = item.offers_props_by_yml_id[keys[0]][self.getWidgetParam('use_cardprice')];
        }

        if (item.price && item.old_price && item.old_price !== item.price) {
            let productOldPrice = document.createElement('div');
            productOldPrice.classList.add(Const.widgetOldPriceClass);
            productOldPrice.innerHTML = self.priceFormatter(item.old_price);

            priceContainer.appendChild(productOldPrice);

            if (self.getWidgetParam('show_discount_percent')) {
                const discountPercent = document.createElement('div');
                discountPercent.classList.add(Const.widgetDiscountPercentClass);
                discountPercent.innerHTML = `-%${Math.round(100 - (item.price * 100) / item.old_price)}`;
                priceContainer.appendChild(discountPercent);
            }
        }

        const brandAndPrice = document.createElement('div');
        brandAndPrice.classList.add(Const.widgetBrandAndPriceClass);

        let productPrice = document.createElement('div');
        productPrice.classList.add(Const.widgetPriceClass);
        productPrice.innerHTML = self.priceFormatter(item.price);
        priceContainer.appendChild(productPrice);

        if (newView) {
            brandAndPrice.appendChild(brandName);
            brandAndPrice.appendChild(priceContainer);
            productFooter.appendChild(brandAndPrice);
        }

        if (brandName && !newView) {
            productFooter.appendChild(brandName);
        }

        if (!block.no_info) {
            if (window.location.hostname.indexOf('miuz') !== -1) {
                productHeader.appendChild(productName);
            } else {
                productFooter.appendChild(productName);
            }
        }

        let likeControl = null;
        let dislikeControl = null;

        // Like & dislike render
        if (self.getWidgetParam('like_dislike_enabled')) {
            let controls = document.createElement('div');
            controls.classList.add(Const.widgetItemControlContainerClass);

            const addToCartNoSizeBtn = document.createElement('button');
            addToCartNoSizeBtn.classList.add(Const.widgetAddToCartNoSizeClass);
            addToCartNoSizeBtn.type = 'button';
            addToCartNoSizeBtn.innerHTML = 'Add to cart';
            controls.appendChild(addToCartNoSizeBtn);

            addToCartNoSizeBtn.addEventListener('click', (e) => {
                if (self._widget._callbacks.hasOwnProperty('addToCart')) {
                    self.ecommerce.addToCartClick(item, block);
                    self._widget._callbacks.addToCart.apply(null, [{ productId: item.yml_id, link: item.link }]);
                } else if (self._widget._callbacks.hasOwnProperty('addToCartEvent')) {
                    self.ecommerce.addToCartClick(item, block);
                    self._widget._callbacks.addToCartEvent.apply(null, [{ link: item.link }]);
                }
            });

            likeControl = document.createElement('a');
            likeControl.classList.add(Const.widgetItemControlLikeClass);
            likeControl.href = 'javascript:void(0)';
            likeControl.title = 'Add to favorites';

            dislikeControl = document.createElement('a');
            dislikeControl.classList.add(Const.widgetItemControlDislikeClass);
            dislikeControl.href = 'javascript:void(0)';

            controls.appendChild(likeControl);
            controls.appendChild(dislikeControl);
            productFooter.appendChild(controls);
        }

        if (!newView) {
            productFooter.appendChild(priceContainer);
        }

        if (item.sizes && item.sizes.length > 0) {
            const sizesList = document.createElement('ul');
            sizesList.classList.add(Const.widgetSizesListClass);
            item.sizes.map((size) => {
                if (size.is_available) {
                    const sizeItem = document.createElement('li');
                    sizeItem.classList.add(Const.widgetSizesListItemClass);
                    sizeItem.innerHTML = size.name;
                    sizesList.appendChild(sizeItem);
                }
            });
            productFooter.appendChild(sizesList);
        }

        if (newView || self.shopTheModelLook(block)) {

            const sizesWrap = document.createElement('div');
            sizesWrap.classList.add('garderobo-shopthemodellook__sizes_wrap');

            const sizesWrapText = document.createElement('span');
            sizesWrapText.classList.add('garderobo-shopthemodellook__sizes_text');

            //sizesWrapText.textContent = 'РАЗМЕР:';

            /* Custom Select */

            const customSelect = document.createElement('div');
            customSelect.classList.add('garderobo-custom-select');

            const customSelectSelected = document.createElement('div');
            customSelectSelected.classList.add('garderobo-custom-selected');

            customSelect.appendChild(customSelectSelected);

            let customSelectSelectedTxt;
            if (self.getWidgetParam('selected_size_with_txt')) {                
                customSelectSelectedTxt = document.createElement('div');
                customSelectSelectedTxt.classList.add('garderobo-custom-selected-txt');
                customSelectSelectedTxt.innerHTML = 'РАЗМЕР:';
                customSelectSelectedTxt.style.display = 'none';
                customSelectSelected.appendChild(customSelectSelectedTxt);
            }

            const customSelectValue = document.createElement('span');
            customSelectValue.classList.add('garderobo-custom-selected-value');
            customSelectValue.dataset.value = '';

            const customSelecticon = document.createElement('i');
            customSelecticon.classList.add('garderobo-custom-select-icon');

            customSelectSelected.appendChild(customSelectValue);
            customSelectSelected.appendChild(customSelecticon);

            const sizes = document.createElement('div');
            sizes.classList.add('garderobo-custom-select-list');

            const defaultSize = document.createElement('span');
            defaultSize.classList.add('garderobo-custom-select-list-item');
            defaultSize.dataset.value = '';

            // const sizes = document.createElement('select');
            // const defaultSize = document.createElement('option');

            sizes.classList.add(Const.widgetSizesClass);

            customSelectValue.innerText = self.getText(Texts.SELECT_SIZE);

            if (item.sizes.length === 1) {
                sizesWrap.classList.add('garderobo-disabled-select');
            } else {
                customSelect.appendChild(sizes);
            }
            //sizes.appendChild(defaultSize);

            const addToCartBtn = document.createElement('button');

            addToCartBtn.innerHTML = self.getText(Texts.TO_CART);
            addToCartBtn.type = 'button';
            addToCartBtn.classList.add(Const.widgetAddToCartClass);
            addToCartBtn.setAttribute('data-product', item.group_id);
            addToCartBtn.setAttribute('data-quantity', 1);
            addToCartBtn.setAttribute('data-offer', item.yml_id);

            if (item.sizes.length !== 1) {
                addToCartBtn.classList.add('disabled');
                addToCartBtn.setAttribute('disabled', '');
            } else {
                addToCartBtn.setAttribute('id', item.sizes[0].yml_id);
                if (customSelectSelectedTxt) {
                    customSelectSelectedTxt.style.display = 'block';
                }
            }

            addToCartBtn.addEventListener('click', (e) => {
                if (self._widget._callbacks.hasOwnProperty('addToCart')) {
                    self.ecommerce.addToCartClick(item, block, e.target.getAttribute('id'));
                    self._widget._callbacks.addToCart.apply(null, [item.id, e.target.getAttribute('id')]);
                } else if (self._widget._callbacks.hasOwnProperty('addToCartEvent')) {
                    self.ecommerce.addToCartClick(item, block, e.target.getAttribute('id'));
                    self._widget._callbacks.addToCartEvent.apply(null, [e.target.getAttribute('id'), item]);
                }
            });

            let sizeEl;

            if (item.sizes) {

                if (item.sizes.length === 1) {
                    self.selectedSizes.push(item.sizes[0].yml_id);
                    customSelectValue.dataset.value = item.sizes[0].yml_id;
                    customSelectValue.innerText = item.sizes[0].name;
                }

                item.sizes.map((size) => {
                    sizeEl = document.createElement('span');

                    sizeEl.dataset.value = size.yml_id;
                    sizeEl.innerText = size.name;
                    sizeEl.selected = item.sizes.length === 1;

                    sizeEl.addEventListener('click', (e) => {
                        let siblings = e.target.parentElement.children;
                        for (let sib of siblings) {
                            sib.classList.remove('active')
                        }
                        e.target.classList.add('active');
                        customSelectValue.dataset.value = e.target.dataset.value;
                        self.selectedSizes.push(e.target.dataset.value);
                        customSelectValue.innerText = e.target.textContent;
                        addToCartBtn.setAttribute('id', e.target.dataset.value);
                        addToCartBtn.removeAttribute('disabled');
                        addToCartBtn.setAttribute('data-offer', e.target.dataset.value);

                        if (customSelectSelectedTxt) {
                            customSelectSelectedTxt.style.display = 'block';
                        }
                    });

                    sizes.appendChild(sizeEl);
                });

                sizesWrap.addEventListener('click', (e) => {
                    e.stopPropagation();
                    if (!sizesWrap.classList.contains('garderobo-disabled-select')) {
                        customSelect.classList.toggle("opened-size-menu");
                    };
                });

                window.addEventListener('click', () => {
                    if (!sizesWrap.classList.contains('garderobo-disabled-select')) {
                        customSelect.classList.remove("opened-size-menu");
                    };
                })
            }

            //customSelectValue.dataset.value =

            // sizes.addEventListener('change', (e) => {
            //     if (e.target.value) {
            //         self.selectedSizes.push(e.target.value);
            //         addToCartBtn.classList.remove('disabled');
            //         addToCartBtn.removeAttribute('disabled');
            //         addToCartBtn.setAttribute('id', e.target.value);
            //     } else {
            //         addToCartBtn.classList.add('disabled');
            //         addToCartBtn.setAttribute('disabled', true);
            //         addToCartBtn.setAttribute('id', '');
            //     }

            //     if (self.selectedSizes.length === self.elementsCount) {
            //         document.getElementById('add-all-product-btn').classList.remove('disabled');
            //     }
            // });

            const sizesAndCartBtn = document.createElement('div');
            sizesAndCartBtn.classList.add(Const.widgetSizesAndCartBtnClass);

            item.sizes.forEach((size) => {
                if (size.name === 'UNI') {
                    sizesWrap.classList.add('garderobo-leform-uni');
                }
            });


            if (block.show_type === 'shop_the_model_look' && self.special_look_image) {
                sizesWrap.appendChild(sizesWrapText);
                sizesWrap.appendChild(customSelect);
                sizesAndCartBtn.appendChild(sizesWrap);
                sizesAndCartBtn.appendChild(addToCartBtn);

                //wrap Title and Price in one div
                productTitlePrice.appendChild(productName);
                productTitlePrice.appendChild(priceContainer);
                productFooter.appendChild(productTitlePrice);
            } else {
                sizesAndCartBtn.appendChild(sizes);
                sizesAndCartBtn.appendChild(addToCartBtn);
            }


            productFooter.appendChild(sizesAndCartBtn);
        }

        if (item.params && item.params.promotion_id) {
            let productPromoText = document.createElement('div');
            productPromoText.classList.add('garderobo-widget-product-promo');
            productPromoText.innerHTML = item.params.promotion_id;
            productFooter.appendChild(productPromoText);
        }

        if (item.title || window.location.hostname.indexOf('miuz') !== -1) {
            feedItemInner.appendChild(productHeader);
        }

        if (item.old_price && item.price && item.price < item.old_price) {
            let productSaleBadge = document.createElement('div');
            productSaleBadge.classList.add(Const.widgetSaleBadgeClass);
            productSaleBadge.innerHTML = `-${Math.round(100 - (item.price * 100) / item.old_price)}%`;
            feedItemInner.appendChild(productSaleBadge);
        }

        feedItemInner.appendChild(productImg);

        if (!block.no_info) {
            feedItemInner.appendChild(productFooter);
        }

        let out = { el: feedItem, control: productImg, productName };

        if (self.getWidgetParam('like_dislike_enabled')) {
            out = { ...out, like: likeControl, dislike: dislikeControl };
        }

        if (item.params && item.params.badge) {
            let badges = document.createElement('div');
            badges.classList.add('garderobo-widget-product-badges');

            let badge = document.createElement('div');
            badge.classList.add('garderobo-widget-product-badges-item');
            
            let formattedBadge = item.params.badge
                .split('-')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');

            badge.innerHTML = formattedBadge;
            badges.appendChild(badge);

            productFooter.appendChild(badges);
        }

        return out;
    };

    /** отрисовка лука - несколько товаров в одном боксе */
    // todo - someData ????
    self.renderFeedItemWithCollage = (look, newView = false, someData, isForPopup, hasSpecialLookBadge) => {
        let feedItem = document.createElement('div');
        feedItem.classList.add(Const.widgetItemClass);

        feedItem.classList.add(Const.widgetItemMinWidthClass);
        feedItem.setAttribute('data-look', look.look_id);

        let modatechLookElement = document.querySelector('#modatech-shop-the-model');

        if (hasSpecialLookBadge && !modatechLookElement) {
            feedItem.classList.add(`${Const.widgetItemClass}--special`);
        }

        let collageContainer = undefined;
        if (look.custom_collage_templates === 'grid-3')
            collageContainer = new CollageV3(look.products, feedItem, self, look.custom_collage_templates_code);
        else
            collageContainer = new Collage(look.products, feedItem, self, {
                useCustomTemplate: look.show_type === BlockShowType.COLLAGE_BY_TEMPLATES,
                customCssClass: look.custom_css_class,
                tuneProductSize: look.auto_scale_collages === 1,
                customTemplateCode: look.custom_collage_templates,
                lookId: look.look_id,
                customTemplatePatterns: look.custom_template_patterns
            });

        if (!(collageContainer instanceof HTMLElement)) {
            return null;
        }

        feedItem.appendChild(collageContainer);

        const btn = document.createElement('button');
        btn.classList.add('garderobo-widget-look__btn-buy');
        btn.type = 'button';
        btn.innerHTML = self.getText(Texts.LOOK_BUY_BTN);

        let viewLookBtn = document.createElement('button');
        viewLookBtn.classList.add('garderobo-widget-look__btn-view');
        viewLookBtn.style.display = 'none';
        let viewLookBtnText = document.createElement('span');
        viewLookBtnText.innerHTML = self.getText(Texts.SEE_FULL_LOOK);
        viewLookBtn.appendChild(viewLookBtnText);

        if (self.getWidgetParam('new_view_look_btn')) {
            viewLookBtnText.innerHTML = 'Смотреть';
        }
        // todo - вроде это лишнее, т/к ниже вызывается openPopup
        btn.addEventListener('click', () => {
            self.collagesPopup.openPopup(look.look_id, look);
        });
        if (!isForPopup) {
            if (self.getWidgetParam('product_popup')) {
                collageContainer.parentElement.addEventListener('click', () => {
                    self.collagesPopup.openPopup(look.look_id, look);
                });
                viewLookBtnText.addEventListener('click', () => {
                    self.collagesPopup.openPopup(look.look_id, look);
                });
            } else {
                collageContainer.parentElement.addEventListener('click', () => {
                    self.collagesPopup.openPopup(look.look_id, look);
                });
            }

        }
        collageContainer.appendChild(btn);
        collageContainer.appendChild(viewLookBtn);

        if (self.getWidgetParam('new_view_look_btn')) {
            if (collageContainer.parentElement) {
                collageContainer.parentElement.appendChild(viewLookBtn);
            }

        }

        if (hasSpecialLookBadge) {
            const badge = document.createElement('div');
            badge.classList.add('garderobo-widget-look__badge-special');
            badge.innerHTML = self.getText(Texts.MODEL);
            collageContainer.appendChild(badge);
        }

        let out = { el: feedItem, control: collageContainer, productName: null, data: look };

        return out;
    };

    self.fetchFeed = (options) => {
        return new HandledPromise((resolve, reject) => {
            if (!options) options = {};
            let uri = 'open_page/';
            let data = {};

            if (self._widget.initOptions.hasOwnProperty('lang_code')) data = { ...data, lang_code: self._widget.initOptions.lang_code };

            if (options.hasOwnProperty('product_ids')) data = { ...data, product_ids: options.product_ids };

            if (options.hasOwnProperty('yml_ids')) data = { ...data, yml_ids: options.yml_ids };

            if (options.hasOwnProperty('code')) data = { ...data, code: options.code };

            if (options.hasOwnProperty('brand')) data = { ...data, brand: options.brand };

            if (options.hasOwnProperty('type')) data = { ...data, page_type: options.type };

            if (options.hasOwnProperty('filters')) data = { ...data, filters: options.filters };

            if (options.hasOwnProperty('page_subtype')) data = { ...data, page_subtype: options.page_subtype };

            if (options.hasOwnProperty('yml_id')) data = { ...data, yml_id: options.yml_id };

            if (options.hasOwnProperty('group_id')) data = { ...data, group_id: options.group_id };

            if (options.hasOwnProperty('source_block_type'))
                data = {
                    ...data,
                    source_block_type: options.source_block_type,
                };

            if (options.hasOwnProperty('source_page_type')) data = { ...data, source_page_type: options.source_page_type };

            if (options.hasOwnProperty('fallbackTime')) {
                data = { ...data, fallbackTime: options.fallbackTime };
                if (self._widget._callbacks.hasOwnProperty('fallback')) {
                    data = {
                        ...data,
                        fallback: self._widget._callbacks.fallback,
                    };
                }
            }

            if (options.hasOwnProperty('gender')) data = { ...data, gender: options.gender };

            if (self._widget.initData.city) data = { ...data, city: self._widget.initData.city };

            /*Sentry.addBreadcrumb({
                category: 'action',
                message: 'Registered open page with type ' + data.page_type + ' with options ' + JSON.stringify(data),
                level: Sentry.Severity.Info,
            });*/

            if (document.referrer) {
                data = { ...data, referer: document.referrer };
            }

            data = { ...data, url: document.location.href };

            const urlParams = new URLSearchParams(window.location.search);
            const payload = urlParams.get('payload');

            if (payload) {
                data = {...data, payload: payload};
                uri = 'get_looks_for_products/';
            }

            if ((data['page_type'] == 'platform') && (!payload)) {
                self.platform = new Platform(self, self._widget.key, self._widget.user_id);
            } else {
                self._widget
                    .getSessionPromise()
                    .then(() => {
                        resolve(
                            self._widget.fetch(uri, {type: 'post', data: data}).then((res) => {
                                if (!res) {
                                    self._widget.widgetApi.stopSession();
                                    self._widget.reInit();
                                }

                                if (self._widget.priceTier) {
                                    for (let i=0; i < res.blocks.length; i++) {
                                        res.blocks[i].products = self.updatePricesByTiers(res.blocks[i].products);
                                    }
                                }

                                self.product_id = res['product_id'];
                                if (['product', 'custom'].includes(data['page_type']))
                                    self._widget.analytics.sendEventClick(res['product_id'], null, null, res['product_price'], res['product_old_price']);
                                return res;
                            })
                        );
                    })
                    .catch(reject);
            }
        });
    };

    self.updatePricesByTiers = (products) => {
        for (let i=0; i < products.length; i++) {
            let product = products[i];

            if (product.price_tiers) {
                let newPrice = product.price_tiers[self._widget.priceTier];
                if (newPrice) {
                    if (!product.old_price && (product.price != newPrice))
                        product.old_price = parseFloat(product.price);
                    product.price = parseFloat(newPrice);
                }
            }
        }

        return products;
    }

    self.fetchSimilars = (lookItemId, productId, settings) => {
        return new HandledPromise((resolve, reject) => {
            let uri = 'get_similar_products/';
            let data = {
                look_item_id: lookItemId,
                product_id: productId,
                use_crop: settings && settings.hasOwnProperty('useCrop') ? settings.useCrop : undefined,
            };

            self._widget
                .getSessionPromise()
                .then(() => {
                    resolve(self._widget.fetch(uri, {type: 'post', data: data}).then((res) => {
                        if (self._widget.priceTier) {
                            res.products = self.updatePricesByTiers(res.products);
                        }

                        return res;
                    }));
                    //resolve(self._widget.fetch(uri, { type: 'post', data: data }));
                })
                .catch(reject);
        });
    };

    self.fetchSimilarsForArray = (lookItemId, productIds, count = 4) => {
        return new HandledPromise((resolve, reject) => {
            let uri = 'get_similar_products/';
            let data = { products_ids: productIds, count };

            self._widget
                .getSessionPromise()
                .then(() => {
                    resolve(self._widget.fetch(uri, { type: 'post', data: data }));
                })
                .catch(reject);
        });
    };

    self.draw = () => {
        let renderResult = self.render();
        if (self.containerEl) self.containerEl.appendChild(renderResult);
    };

    self.setUpCreatorMode = (isLocalHost, options) => {
        if (self.isCreatorScriptLoaded) {
            return;
        }
        const jsFile = isLocalHost ? 'development.js' : 'production.min.js';
        const scriptReact1 = document.createElement('script');
        scriptReact1.src = 'https://unpkg.com/react@17/umd/react.' + jsFile;
        scriptReact1.setAttribute('crossorigin', true);
        document.body.appendChild(scriptReact1);

        self.isCreatorScriptLoaded = true;

        scriptReact1.onload = () => {
            const scriptReact2 = document.createElement('script');
            scriptReact2.src = 'https://unpkg.com/react-dom@17/umd/react-dom.' + jsFile;
            scriptReact2.setAttribute('crossorigin', true);
            document.body.appendChild(scriptReact2);

            scriptReact2.onload = () => {
                const script = document.createElement('script');
                script.src = (options && options.path) || '/dist/creator.js';
                document.body.appendChild(script);
            };
        };
    };

    self.setUpBuyLookPopup = () => {
        if (self.isBuyLookPopupScriptLoaded) {
            return;
        }
        const isLocal = window.location.hostname.indexOf('localhost') !== -1;

        self.isBuyLookPopupScriptLoaded = true;

        const script = document.createElement('script');
        script.src = isLocal ? '/dist/buy-look-popup.js' : 'https://widget.garderobo.ru/buy-look-popup.js';
        document.body.appendChild(script);
    };

    // self.setUpBuyLookPopup = () => {
    //     // todo - временно, у collagesPopup проблема с react
    //     // if (self.isPuma()) {
    //     //     return;
    //     // }
    //     if (self.isBuyLookPopupScriptLoaded) {
    //         return;
    //     }
    //     const isLocal = window.location.hostname.indexOf('localhost') !== -1;
    //     const jsFile = isLocal ? 'development.js' : 'production.min.js';

    //     const scriptReact1 = document.createElement('script');
    //     scriptReact1.src = 'https://unpkg.com/react@17/umd/react.' + jsFile;
    //     scriptReact1.setAttribute('crossorigin', true);
    //     document.body.appendChild(scriptReact1);

    //     self.isBuyLookPopupScriptLoaded = true;

    //     scriptReact1.onload = () => {
    //         const scriptReact2 = document.createElement('script');
    //         scriptReact2.src = 'https://unpkg.com/react-dom@17/umd/react-dom.' + jsFile;
    //         scriptReact2.setAttribute('crossorigin', true);
    //         document.body.appendChild(scriptReact2);

    //         scriptReact2.onload = () => {
    //             const script = document.createElement('script');
    //             script.src = isLocal ? '/dist/buy-look-popup.js' : 'https://widget.garderobo.ru/buy-look-popup.js';
    //             document.body.appendChild(script);
    //         };
    //     };
    // };

    //========================> for buy look btn test
    // self.counter = 1;

    // document.body.addEventListener('click', () => {
    //     console.log('-------click');
    //     self.redraw({ special_look_id: 1111, product_id: 22222, type: 'product', yml_id: 2385596747903, test: self.counter++ });
    // });

    self.redraw = (options) => {
        return new HandledPromise((resolve, reject) => {
            self.fetchFeed(options)
                .then((data) => {
                    /** // old usage of ab_test
                    if (data && data.ab_test && typeof ga === 'function') {
                        self._widget.widgetABTest
                            .get()
                            .then((ab_test) => {
                                // если ab_test приходит впервые или данные в ab_test обновились
                                if (!ab_test || ab_test.dimension_index !== data.ab_test.dimension_index || ab_test.segment !== data.ab_test.segment) {
                                    self._widget.widgetABTest.set(data.ab_test);
                                    ga('set', 'dimension' + data.ab_test.dimension_index, data.ab_test.segment);
                                }
                            })
                            .catch(() => {
                                self._widget.widgetABTest.set(data.ab_test);
                                ga('set', 'dimension' + data.ab_test.dimension_index, data.ab_test.segment);
                            });
                    }
                    */
                    //===========================================> for test
                    // if (options.test === 1) {
                    //     data.special_look_id = 58448;
                    //     data.product_id = 839386;
                    // } else if (options.test === 2) {
                    //     data.special_look_id = 1111;
                    //     data.product_id = 2222;
                    // }
                    //data.special_look_id = 58448;
                    //data.product_id = 839386;

                    const urlParams = new URLSearchParams(window.location.search);
                    let look_id = urlParams.get('look_id');
                    let currentLookIndex;
            
                    if (look_id) {                                
                        currentLookIndex =  data.blocks.findIndex(block => block.look_id == look_id);
            
                        if (currentLookIndex !== -1) {
                            const [item] = data.blocks.splice(currentLookIndex, 1);
                            data.blocks.unshift(item);                                    
                        }
                    }

                    var modatechLook;

                    if (data.special_look_id) {
                        self.special_look_id = data.special_look_id;
                        
                        if (self.getWidgetParam('is_loverepublic')) {
                            modatechLook = data.blocks[0];

                            let modatechLookElement = document.querySelector('#modatech-shop-the-model');
                            if (modatechLookElement) {
                                data.blocks.shift();
                                modatechLookElement.classList.add('modatech-shop-the-model-show');
                            }
                        }

                        const currentBuyLookBtn = document.getElementById('btn-special-look');
                        if (currentBuyLookBtn) {
                            // just update btn
                            currentBuyLookBtn.setAttribute('data-id', data.special_look_id);
                            currentBuyLookBtn.setAttribute('data-product-id', data.product_id);
                        } else {
                            // create new btn
                            const container = document.getElementById('garderobo-buy-the-look-btn');

                            if (container && self.isCollagesWidget(data.blocks)) {
                                const useBuyLookPopup = false; // временно убираем открытие попапа
                                const buyLookBtn = document.createElement('button');
                                buyLookBtn.setAttribute('id', 'btn-special-look');
                                buyLookBtn.setAttribute('type', 'button');
                                buyLookBtn.setAttribute('data-id', data.special_look_id);
                                buyLookBtn.setAttribute('data-product-id', data.product_id);
                                buyLookBtn.innerHTML = self.getText(Texts.HOW_TO_WEAR_IT) + '<span class="garderobo-btn-special-look-arrow"></span>';
                                buyLookBtn.addEventListener('click', () => {
                                    const yOffset = -160;
                                    const element = document.querySelector('.garderobo-widget-feed-item-look');
                                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                                    window.scrollTo({ top: y, behavior: 'smooth' });
                                });
                                container.appendChild(buyLookBtn);

                                if (useBuyLookPopup) {
                                    buyLookBtn.classList.add('garderobo-widget-displayed-none');
                                    self.setUpBuyLookPopup();
                                }
                            }
                        }
                    }

                    if (data.special_look_image) {
                        self.special_look_image = data.special_look_image;
                    }

                    let resolveData = [];
                    if (self._widget._callbacks.hasOwnProperty('onDataLoaded')) {
                        self._widget._callbacks.onDataLoaded.apply(null, [data]);
                    }

                    let isInlineWidgets = false;
                    if (data && data.blocks) {
                        if (self._widget.initOptions && self._widget.initOptions.is_inline) {
                            const inlineWidgets = new InlineWidget(data.blocks, self, Texts);
                            inlineWidgets.initInline(data.blocks);
                            isInlineWidgets = true;
                        }
                    }

                    if (data && data.blocks && !isInlineWidgets) {
                        const isLocal = window.location.hostname.indexOf('localhost') !== -1;
                        const isProd =
                            window.location.hostname.indexOf('backend.garderobo') !== -1 || window.location.hostname.indexOf('testbackend.garderobo') !== -1;
                        const isAuthorized = document.querySelector('.admin_product_link');

                        const isCreatorMode = (isProd && isAuthorized); //|| isLocal

                        const isCollagesWidget = self.isCollagesWidget(data.blocks);

                        if ((self.optionsFromWidget) && (self.optionsFromWidget.scroll_button)) {
                            if (data.blocks.filter((block) => block.type === 'looks').length >= 1) {
                                if (self.optionsFromWidget.scroll_button.position) {
                                    const scrollButton = document.createElement('button');
                                    scrollButton.classList.add(Const.scrollButtonClass);
                                    scrollButton.innerHTML = self.optionsFromWidget.scroll_button.caption + '<span class="garderobo-btn-special-look-arrow"></span>';
                                    self.optionsFromWidget.scroll_button.position.appendChild(scrollButton);

                                    scrollButton.addEventListener('click', function(e) {
                                        e.stopPropagation();
                                        const garderoboContainer = document.querySelector('.garderobo-widget-container');

                                        if (garderoboContainer) {
                                            let offsetPosition = garderoboContainer.offsetTop;
                                            if (!offsetPosition)
                                                offsetPosition = garderoboContainer.offsetParent.offsetTop;

                                            if (self.optionsFromWidget.scroll_button.offset)
                                                offsetPosition += self.optionsFromWidget.scroll_button.offset;

                                            let mainContainerToScroll = window;
                                            if (self.optionsFromWidget.scroll_button.mainElement)
                                                mainContainerToScroll = document.querySelector(self.optionsFromWidget.scroll_button.mainElement);

                                            mainContainerToScroll.scrollTo({
                                                top: offsetPosition,
                                                behavior: self.optionsFromWidget.scroll_button.behavior ? self.optionsFromWidget.scroll_button.behavior : 'smooth',
                                            });
                                        }

                                        self._widget.analytics.sendEventAction('click_scroll_button', undefined, true, undefined, undefined, undefined, undefined, self.response);
                                    });
                                }
                            }
                        }

                        if (isCollagesWidget) {
                            resolveData.push({
                                type: 'looks',
                                el: self.drawFeedItemsWithCollages(isCollagesWidget, 'looks', 3, false, data.blocks[0], data),
                                products: [],
                            });
                        }

                        const multipleLooks = data.blocks.filter(
                            (block) =>
                                block.show_type !== BlockShowType.COLLAGE_BY_TEMPLATES && block.type === 'looks' && block.products && block.products.length
                        );
                        const isMultipleLooks = multipleLooks.length > 1;
                        let currentMultipleLook = 0;

                        for (let bKey in data.blocks) {
                            let block = data.blocks[bKey];
                            let additionalClasses = [];

                            if (!block.hasOwnProperty('products')) continue;

                            /*Sentry.addBreadcrumb({
                                category: 'feed',
                                message: 'Received feed: ' + JSON.stringify(block.products),
                                level: Sentry.Severity.Info,
                            });*/

                            if (multipleLooks.includes(block)) {
                                if (currentMultipleLook > 0) {
                                    continue;
                                } else {
                                    currentMultipleLook++;
                                }
                            }

                            if (isCreatorMode && block.type === 'looks') {
                                self.setUpCreatorMode(isLocal, options);
                            } else {
                                let per_page = block.hasOwnProperty('per_page') ? block.per_page : false;
                                if (isCollagesWidget && block.type === 'looks') continue;

                                if (block.products.length <= 1)
                                    continue;

                                if (self.shopTheModelLook(block)) {
                                    block.products.forEach((product, index) => {
                                        let modifiedUrl =  product.picture.replace('/crop/', '/big/').replace('.png', '.jpg');
                                        
                                        block.products[index] = {
                                            ...product,
                                            picture: modifiedUrl
                                        }
                                    })
                                    
                                    resolveData.push({
                                        type: block.type,
                                        el: self.drawScrollWidgetFeedItems(
                                            block.name,
                                            block.type + block.look_id, // todo
                                            block.products,
                                            per_page,
                                            block,
                                            additionalClasses
                                        ),
                                        products: block.products,
                                    });
                                } else if (block.layout && block.type === 'looks') {
                                    resolveData.push({
                                        type: block.type,
                                        el: self.drawFeedItems2(block.name, block.type, block.products, per_page, block, additionalClasses),
                                        products: block.products,
                                    });
                                } else {
                                    resolveData.push({
                                        type: block.type,
                                        look_id: block.look_id,
                                        el: self.drawFeedItems(
                                            per_page,
                                            block,
                                            additionalClasses,
                                            multipleLooks.includes(block),
                                            multipleLooks.includes(block) ? multipleLooks : [block],
                                            0
                                        ),
                                        products: block.products,
                                    });
                                }
                            }
                        }

                        let shopTheModelLooks = data.blocks.filter(block => block.show_type == 'shop_the_model_look' && block.type == 'looks');
                        if (shopTheModelLooks.length > 1) {
                            shopTheModelLooks.shift();
                            
                            const shopTheModelLooksCollages = self.shopTheModelLooksCollages(shopTheModelLooks);                            

                            resolveData.push({
                                type: 'looks',
                                el: self.drawFeedItemsWithCollages(shopTheModelLooksCollages, 'looks', 3, false, data.blocks[0], data, data.blocks[0].additional_name),
                                products: [],
                            });
                        }

                        if (data.special_look_id) {
                            let block = modatechLook ? modatechLook : data.blocks[0];

                            resolveData.push({
                                type: block.type,
                                el: self.modatechShopTheModel(
                                    block.name,
                                    block.products,
                                    block,
                                ),
                            });
                        }
                    }
                    
                    self.response = data;
                    resolve(resolveData);
                })
                .catch(reject);
        });
    };

    /** нужно ли отрисовывать слайдер с коллажами
     * @returns возвращает массив слайдов, либо null
     */
    self.isCollagesWidget = (blocks) => {
        let looks = blocks.filter((block) => block.type === 'looks' && !self.shopTheModelLook(block));
        if (!looks.length) {
            looks = blocks.filter((block) => block.type === 'w_recommendations' && block.show_type == 'collage_by_templates');
            let counter = 1;
            for (let i=0; i<looks.length; i++) {
                looks[i].type = 'looks';
                looks[i].look_id = counter;
                counter++;
            }
        }
        return looks.length >= 1 && looks[0].show_type === BlockShowType.COLLAGE_BY_TEMPLATES ? looks : null;
    };

    self.shopTheModelLooksCollages = (blocks) => {
        blocks.forEach(block => {
            block.products.forEach((product, index) => {
                let modifiedUrl =  product.picture.replace('/big/', '/crop/').replace('.jpg', '.png');
                
                block.products[index] = {
                    ...product,
                    picture: modifiedUrl
                }
            })
        })

        return blocks.length >= 1 ? blocks : null;
    };

    self.shopTheModelLook = (block) => {
        return block.show_type === 'shop_the_model_look' && self.special_look_image;
    };

    self.fetch = () => {
        arguments[0] = self._widget.apiBaseUri + arguments[0];
        grFetch.apply(self._widget, arguments);
    };

    self.getGtmIdParam = (item) => {
        const idField = self.getWidgetParam('ga_settings') ? self.getWidgetParam('ga_settings').id_field || null : null;
        return idField ? item[idField] : null;
    };

    self.sendGtm = (ecommerce, eventAction, nonInteraction = 'False', otherOptions = {}) => {
        const settings = self.getWidgetParam('ga_settings') || {};

        window.dataLayer = window.dataLayer || [];
        const dataLayerObj = {};

        if (ecommerce) {
            dataLayerObj.ecommerce = ecommerce;
        }

        dataLayerObj.event = otherOptions.event || settings.ga_type || 'gtm-ee-event';

        const categoryLabel = settings['gtm-ee-event-category-label'] || 'gtm-ee-event-category';
        dataLayerObj[categoryLabel] = otherOptions.eventCategory || settings['gtm-ee-event-category'];

        const actionLanel = settings['gtm-ee-event-action-label'] || 'gtm-ee-event-action';
        dataLayerObj[actionLanel] = eventAction;

        if (settings['gtm-ee-event-non-interaction']) {
            dataLayerObj['gtm-ee-event-non-interaction'] = nonInteraction;
        }

        Object.assign(dataLayerObj, otherOptions);

        //console.log('ecommerce', dataLayerObj);

        dataLayer.push(dataLayerObj);
    };

    self.getCookie = (name) => {
        var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (match) return match[2];
    };

    self.getUpdatedGridSize = (product, currentSize, layerType, isMainProduct, direction, template_name = '') => {
        let position = parseInt(currentSize.split('/')[0]);
        let size = parseInt(currentSize.split('/')[1]) - parseInt(currentSize.split('/')[0]);

        if (direction == 'y') {
            if (!product.crop_wh)
                return position + '/' + (position + size);

            let koeff = product.crop_wh[0] / product.crop_wh[1];

            let deltaSize = 0;
            let deltaPosition = 0;

            if (layerType.layer == 1) {
                if (layerType.subcategory == 'top') {
                    if (koeff < 0.3) {
                        deltaSize = -10;
                        deltaPosition = -4;
                    }
                } else {
                    //deltaSize = 2;
                    /*if (koeff > 1) {
                        deltaSize = 6;
                        deltaPosition = 4;
                    } else if (koeff > 0.9) {
                       // deltaSize = 4;
                       // deltaPosition = 4;
                    } else if (koeff > 0.85) {
                        deltaSize = 3;
                        deltaPosition = 2;
                    } else if (koeff > 0.75) {
                        deltaSize = 3;
                        deltaPosition = 2;
                    } else if (!isMainProduct) {
                        deltaSize = 2;
                    }*/
                }

                if (isMainProduct && (layerType.subcategory != 'top'))
                    deltaSize = Math.round(deltaSize / 2);
                else
                    position += deltaPosition;
                size -= deltaSize;

            } else if (layerType.layer == 2) {
                if (layerType.subcategory == 'blazer') {
                    //size += 2;
                    //if (!isMainProduct)
                    //    size -= 2;
                } else if (layerType.subcategory == 'vest') {
                    //size -= 2;
                } else if (koeff > 1.35) {
                    size -= 6;
                } else if (koeff > 1.2) {
                  //  size -= 3;
                } else if (koeff < 0.75) {
                    if (template_name == 'bottom')
                        size += 2;
                }
            } else if (layerType.layer == 3) {
                if (layerType.subcategory != 'vest') {
                    if (koeff < 0.63) {
                        position -= 2;
                        size += 6;
                    } else if (koeff < 0.75) {
                        position -= 2;
                        size += 2;
                    }
                }
            } else if (layerType.category == 'bottom') {
                //if (layerType.subcategory == 'shorts') {
                //    size = Math.round(size * (1 - 0.4));
                //    if (!isMainProduct)
                //        position += 2;
                //}
            } else if (layerType.category == 'hat') {
                if (koeff < 0.55)
                    size += 3;
                else if (koeff < 0.7)
                    size += 2;
                else if (koeff < 0.73)
                    size += 1;
            }
        } else if (direction == 'x') {
            if (self.widgetParams.auto_scale_collages) {
                if (self.widgetParams.auto_scale_custom) {
                    if (self.widgetParams.auto_scale_custom[product.category_group] !== undefined) {
                        product.waist_length = self.widgetParams.auto_scale_custom[product.category_group];
                    }
                }

                if (product.waist_length) {
                    let gridSize = Math.ceil(product.crop_wh[0] / product.waist_length * self.widgetParams.auto_scale_collages);
                    size = gridSize;
                    if (size > 15)
                        size = 15;
                    else if (size < 7)
                        size = 7;

                    if (isMainProduct)
                        position = Math.round(21 - (size / 2));
                    else if (position < 10)
                        position = 18 - size;
                    else if (position < 18)
                        position = Math.round(21 - (size / 2));
                }
            }

            if ((layerType.subcategory == 'ring')) {
                size = Math.round(size / 2);
            }
        }

        if (position < 1)
            position = 1;
        return position + '/' + (position + size);
    }

    self.getLayerType = (layerName) => {
        let result = {};
        let baseLayerName = layerName;
        if (layerName.includes('_'))
            baseLayerName = layerName.split('_')[0];

        const layerTypeRegex = /layer-(\w+)/;
        const match = baseLayerName.match(layerTypeRegex);
        if (match) {
            const layerType = match[1];
            if (!isNaN(layerType)) {
                result['category'] = 'top';
                result['layer'] = parseInt(layerType);
            } else if (layerType === 'full') {
                result['category'] = 'full';
            }
        }

        if (layerName.includes('_')) {
            if (!result['category']) {
                result['category'] = layerName.split('_')[0];
            }
            result['subcategory'] = layerName.split('_')[1];
        } else if (!result['category'])
            result['category'] = layerName;

        if (result['category'] == 'gloves')
            result['category'] = 'accessory';

        return result;
    }

    return self;
};

export default Page;
