import { Texts } from '../const';

export default class ProductPopup {
    constructor(product, page) {
        this.product = product;
        this.page = page;
    }

    drawProductPopup() {
        const underlay = document.createElement('div');
        underlay.classList.add('garderobo-widget-popup-container');
        underlay.classList.add('garderobo-product-popup-container');

        const popup = document.createElement('div');
        popup.classList.add('garderobo-widget-popup');
        popup.classList.add('garderobo-product-popup');

        const popupHeader = document.createElement('div');
        popupHeader.classList.add('garderobo-product-popup-header');
        popup.appendChild(popupHeader);

        const headerTitle = document.createElement('div');
        headerTitle.classList.add('garderobo-product-popup-header-title');
        headerTitle.innerHTML = this.page.getText(Texts.PRODUCT_INFO);
        popupHeader.appendChild(headerTitle);

        const closeBtn = document.createElement('button');
        closeBtn.type = 'button';
        closeBtn.classList.add('garderobo-widget-popup__btn-close');

        closeBtn.addEventListener('click', this.closePopup);
        popupHeader.appendChild(closeBtn);

        const content = document.createElement('div');
        content.classList.add('garderobo-widget-popup-content');
        popup.appendChild(content);

        underlay.appendChild(popup);

        return underlay;
    }

    openPopup() {
        const popupEl = document.querySelector('.garderobo-widget-container-for-product-popup .garderobo-widget-popup-container');
        popupEl.setAttribute('data-scroll', document.documentElement.style.overflow);
        document.documentElement.style.overflow = 'hidden';

        if (popupEl) {
            const closeHandler = this.closePopup;
            const onClosePopup = function (e) {
                e.stopImmediatePropagation();
                if (e.target !== this) return;

                popupEl.removeEventListener('click', onClosePopup);
                closeHandler();
            };
            popupEl.addEventListener('click', onClosePopup);
            popupEl.classList.add('garderobo-widget-popup-container--opened');

            this.drawProductInfo(this.product);
        }
    }

    closePopup() {
        const popupEl = document.querySelector('.garderobo-widget-popup-container.garderobo-widget-popup-container--opened');
        if (popupEl) {
            popupEl.classList.remove('garderobo-widget-popup-container--opened');
        }

        let initBodyScroll = popupEl.getAttribute('data-scroll');
        if (initBodyScroll)
            document.documentElement.style.overflow = initBodyScroll;
        else
            document.documentElement.style.removeProperty('overflow');
    }

    drawProductInfo(product) {
        let self = this;

        const popupContent = document.querySelector('.garderobo-widget-popup-container--opened .garderobo-widget-popup-content');
        popupContent.innerHTML = null;

        let picturesBlock = document.createElement('div');
        picturesBlock.classList.add('garderobo-widget-popup-content-pictures');
        popupContent.appendChild(picturesBlock);

        let picItem = document.createElement('div');
        picItem.classList.add('garderobo-widget-popup-content-pictures-item');

        let img = document.createElement('img');
        img.loading = 'lazy';
        img.src = product.picture.replace('/crop/', '/medium/').replace('.png', '.jpg');

        picItem.appendChild(img);
        picturesBlock.appendChild(picItem);
        
        product.pictures.forEach(pic => {
            let picItem = document.createElement('div');
            picItem.classList.add('garderobo-widget-popup-content-pictures-item');

            let img = document.createElement('img');
            img.loading = 'lazy';
            img.src = pic;

            picItem.appendChild(img);
            picturesBlock.appendChild(picItem);
        });

        let infoBlock = document.createElement('div');
        infoBlock.classList.add('garderobo-widget-popup-content-product-info');
        popupContent.appendChild(infoBlock);

        let productBrand = document.createElement('h3');
        productBrand.classList.add('garderobo-widget-popup-content-product-brand');
        productBrand.innerHTML = product.brand;
        infoBlock.appendChild(productBrand);

        let productName = document.createElement('h3');
        productName.classList.add('garderobo-widget-popup-content-product-name');
        productName.innerHTML = product.name;
        infoBlock.appendChild(productName);


        let productPrice = document.createElement('div');
        productPrice.classList.add('garderobo-widget-popup-content-product-price');
        infoBlock.appendChild(productPrice);

        if (!product.old_price) {
            productPrice.innerHTML = self.page.priceFormatter(product.price);
        }

        if (product.old_price && product.price !== product.old_price) {
            let productPriceNew = document.createElement('div');
            productPriceNew.classList.add('garderobo-widget-popup-content-product-price-new');
            productPriceNew.innerHTML = self.page.priceFormatter(product.price);
            productPrice.appendChild(productPriceNew);
            
            let productPriceOld = document.createElement('div');
            productPriceOld.classList.add('garderobo-widget-popup-content-product-price-old');
            productPriceOld.innerHTML = self.page.priceFormatter(product.old_price);
            productPrice.appendChild(productPriceOld);
        }

        let productSizesBlock = document.createElement('div');
        productSizesBlock.classList.add('garderobo-widget-popup-content-product-sizes-block');
        infoBlock.appendChild(productSizesBlock);

        let productSizesText = document.createElement('span');
        productSizesText.innerHTML = self.page.getText(Texts.SIZES);

        productSizesBlock.appendChild(productSizesText);

        let productSizes = document.createElement('div');
        productSizes.classList.add('garderobo-widget-popup-content-product-sizes');
        productSizesBlock.appendChild(productSizes);

        if (product.sizes && product.sizes.length) {
            product.sizes.forEach(size => {
                let sizeElement = document.createElement('div');
                sizeElement.classList.add('garderobo-widget-popup-content-product-sizes-item');
                sizeElement.innerHTML = size.name;
                sizeElement.setAttribute('data-id', size.yml_id);

                productSizes.appendChild(sizeElement);

                sizeElement.addEventListener('click', () => {
                    let activeSize = document.querySelector('.garderobo-widget-popup-content-product-sizes-item-selected');
                    if (activeSize) {
                        activeSize.classList.remove('garderobo-widget-popup-content-product-sizes-item-selected');
                    }

                    sizeElement.classList.add('garderobo-widget-popup-content-product-sizes-item-selected');

                    addToCartBtn.classList.remove('garderobo-widget-popup-content-product-add-to-cart-btn-disabled');
                    addToCartBtn.setAttribute('data-id', size.yml_id);
                    errorMsg.style.display = 'none';
                })
            })
        }

        let addToCartBtn = document.createElement('button');
        addToCartBtn.classList.add('garderobo-widget-popup-content-product-add-to-cart-btn', 'garderobo-widget-popup-content-product-add-to-cart-btn-disabled');
        addToCartBtn.innerHTML = self.page.getText(Texts.ALL_TO_CART);
        addToCartBtn.setAttribute('data-id', '');

        if (product.sizes && product.sizes.length === 1) {
            let sizeElement = document.querySelector('.garderobo-widget-popup-content-product-sizes-item');
            sizeElement.classList.add('garderobo-widget-popup-content-product-sizes-item-selected');

            let ymlId = sizeElement.getAttribute('data-id');
            addToCartBtn.classList.remove('garderobo-widget-popup-content-product-add-to-cart-btn-disabled');
            addToCartBtn.setAttribute('data-id', ymlId);
        }

        let productButtons = document.createElement('div');
        productButtons.classList.add('garderobo-widget-popup-content-product-buttons');
        infoBlock.appendChild(productButtons);

        productButtons.appendChild(addToCartBtn);

        let errorMsg = document.createElement('p');
        errorMsg.classList.add('garderobo-widget-popup-content-product-add-to-cart-error-msg');
        errorMsg.innerHTML = self.page.getText(Texts.PLEASE_SELECT_SIZE);
        errorMsg.style.display = 'none';
        infoBlock.appendChild(errorMsg);

        addToCartBtn.addEventListener('click', () => {
            if (addToCartBtn.getAttribute('data-link')) {
                document.location.href = addToCartBtn.getAttribute('data-link');
                return;
            }
            
            let selectedSizeId = addToCartBtn.getAttribute('data-id');

            let offersPropsByYmlId;
            if (selectedSizeId) {
                offersPropsByYmlId = selectedSizeId
            }

            if (!selectedSizeId) {
                errorMsg.style.display = 'block';
            } else if (self.page._widget._callbacks.hasOwnProperty('addToCart')) {
                self.page._widget._callbacks.addToCart.apply(null, [product.id, offersPropsByYmlId]);
                errorMsg.style.display = 'none';
            }  else if(this.page._widget._callbacks.hasOwnProperty('addToCartEvent')) {
                self.page._widget._callbacks.addToCartEvent.apply(null, [offersPropsByYmlId, product]);
            }

            let sizeValue = null;
            for (let j = 0; j < product.sizes.length; j++) {
                if (product.sizes[j].yml_id === selectedSizeId) {
                    sizeValue = product.sizes[j].name;

                    if (self.page._widget.cartProductsIds) {
                        if (self.page._widget.cartProductsIds.includes(product.sizes[j].yml_id + '')) {
                            let btnAlreadyInCartCaption = self.page.getText(Texts.OPEN_CART);
                            addToCartBtn.classList.add('garderobo-widget-popup-list-item-text-cart-btn-link-state');
                            if (addToCartBtn.innerHTML != btnAlreadyInCartCaption)
                                addToCartBtn.setAttribute('data-text', addToCartBtn.innerHTML);
                            addToCartBtn.innerHTML = btnAlreadyInCartCaption;
                            if (self.page._widget.cartLink)
                                addToCartBtn.setAttribute('data-link', self.page._widget.cartLink);
                        } else {
                            if (addToCartBtn.getAttribute('data-text'))
                                addToCartBtn.innerHTML = btn.getAttribute('data-text');
                            addToCartBtn.removeAttribute('data-link');
                            addToCartBtn.classList.remove('garderobo-widget-popup-list-item-text-cart-btn-link-state');
                        }
                    }
                    break;
                }
            }
        })

        let likeButton = document.createElement('div');
        likeButton.classList.add('garderobo-widget-popup-list-item-like-button');
        productButtons.appendChild(likeButton);
        this.setLikedState(product, likeButton, product.look_id);
    }

    getCustomOfferProps(product, propName) {
        for (const key in product.offers_props_by_yml_id) {
            const value = product.offers_props_by_yml_id[key];
            if (value && typeof value === "object") {
                const keys = Object.keys(value);
                if (keys.length > 0) {
                    if (propName)
                        return value[propName]
                    else
                        return value[keys[0]];
                }
            }
        }
    }

    setLikedState(product, likeButton, lookId) {
        let self = this;
        if (this.page._widget.favoritesProductsIds) {
            let productFinded = false;

            if (this.page._widget.favoritesProductsIds.includes(product.group_id + '') || this.page._widget.favoritesProductsIds.includes(product.id + '')) {
                productFinded = true;
                likeButton.classList.add('garderobo-like-button-liked-state');
            } else if (product.offers_props_by_yml_id) {
                let productOfferPropsId = self.getCustomOfferProps(product, 'product_id');
                if (productOfferPropsId && this.page._widget.favoritesProductsIds.includes(productOfferPropsId + '')) {
                    likeButton.classList.add('garderobo-like-button-liked-state');
                    productFinded = true;
                }
            }

            if (!productFinded) {
                if (product.sizes && product.sizes.length) {
                    for (let i = 0; i < product.sizes.length; i++) {
                        if (this.page._widget.favoritesProductsIds.includes(product.sizes[i].yml_id + '')) {
                            likeButton.classList.add('garderobo-like-button-liked-state');
                            break;
                        }
                    }
                } else if (this.page._widget.favoritesProductsIds.includes(product.yml_id + ''))
                    likeButton.classList.add('garderobo-like-button-liked-state');
            }
        }

        likeButton.addEventListener('click', (event) => {
            event.stopPropagation();

            let state = true;
            if (event.target.classList.contains('garderobo-like-button-liked-state')) {
                state = false;
                event.target.classList.remove('garderobo-like-button-liked-state');
                let index = this.page._widget.favoritesProductsIds.indexOf(product.group_id + '');

                if (product.group_id && index !== -1) {
                    this.page._widget.favoritesProductsIds.splice(index, 1);
                }

                if (this.page.getWidgetParam('favorites_by_offers') && likeButton.hasAttribute('data-offer-id')) {
                    let offerId = likeButton.getAttribute('data-offer-id');
                    let index = this.page._widget.favoritesProductsIds.indexOf(offerId);

                    if (index !== -1) {
                        this.page._widget.favoritesProductsIds.splice(index, 1);
                    }
                }
            } else {
                event.target.classList.add('garderobo-like-button-liked-state');
                if (!this.page._widget.favoritesProductsIds.includes(product.group_id + '')) {
                    if (product.group_id) {
                        this.page._widget.favoritesProductsIds.push(product.group_id + '');
                    } else if (this.page.getWidgetParam('favorites_by_offers') && likeButton.hasAttribute('data-offer-id')) {
                        let productId = likeButton.getAttribute('data-offer-id');
                        this.page._widget.favoritesProductsIds.push(productId + '');
                    }
                }
            }

            let productId = product.group_id;
            if ((product.offers_props_by_yml_id) && this.page.getWidgetParam('favorites_by_offers')) {
                for (const key in product.offers_props_by_yml_id) {
                    productId = key;
                    break;

                    const value = product.offers_props_by_yml_id[key];
                    if (value && typeof value === "object") {
                        const keys = Object.keys(value);
                        if (keys.length > 0) {
                            productId = value[keys[0]]; // Получаем первое значение
                            break;
                        }
                    }
                }
            }

            if (this.page.getWidgetParam('favorites_by_offers'))
                productId = likeButton.getAttribute('data-offer-id');

            self.page._widget._callbacks.addToFavorites.apply(null, [productId, state, product]);
            if (state)
                self.page._widget.analytics.sendEventAction('add_to_favorites', product.wareId, lookId, self.widgetType, self.page._widget.getPageSource(), product.price, product.old_price, self.page.response);
            else
                self.page._widget.analytics.sendEventAction('remove_from_favorites', product.wareId, lookId, self.widgetType, self.page._widget.getPageSource(), product.price, product.old_price, self.page.response);
        });
    }
}
