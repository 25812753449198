import Const from '../const';

export default class SliderSnap {
    constructor(container) {
        this.container = container;
        this.items = [];
        this.leftControl = document.createElement('div');
        this.rightControl = document.createElement('div');
        this.slidePosition = null;
        this.currentIndex = null;

        // bind functions to keep the same reference
        this.boundSlideRight = this.slideRight.bind(this);
        this.boundSlideLeft = this.slideLeft.bind(this);
    }

    sliderReInit() {
        if (this.items) {        
            this.rightControl.removeEventListener('click', this.slideRight);
            this.leftControl.removeEventListener('click', this.slideLeft);
            
            this.sliderInit(this.items);
        }
    }

    sliderInit(items) {
        this.items = items;

        this.leftControl.classList.add(Const.widgetLeftButtonClass, Const.widgetHiddenClass);
        this.rightControl.classList.add(Const.widgetRightButtonClass);

        this.feedItems = this.container.querySelector('.garderobo-widget-feed-items');

        this.currentPosition = this.slidePosition || 0;

        this.feedItems.scrollTo({
            left: this.currentPosition,
            behavior: 'smooth',
        });        

        const computedStyle = window.getComputedStyle(this.feedItems);
        this.gap = parseFloat(computedStyle.gap);

        this.rightControl.removeEventListener('click', this.boundSlideRight);
        this.leftControl.removeEventListener('click', this.boundSlideLeft);

        this.rightControl.addEventListener('click', this.boundSlideRight);
        this.leftControl.addEventListener('click', this.boundSlideLeft);

        this.checkControlsVisibility();

        this.feedItems.addEventListener('scroll', () => {
            this.checkControlsVisibility();

            this.currentPosition = this.feedItems.scrollLeft;

            this.updateCurrentIndex();
        });

        const counterEl = this.container.parentElement.querySelector('.' + Const.widgetFeedItemsCounterClass);
        if (counterEl) {
            this.counterEl = counterEl;
            this.counterBtnPrev = counterEl.querySelector('.' + Const.widgetFeedItemsCounterBtnPrevClass);
            this.counterTextCurr = counterEl.querySelector('.' + Const.widgetFeedItemsCounterTextCurrentClass);
            this.counterTextCurr.innerHTML = 1;
            this.counterTextTotal = counterEl.querySelector('.' + Const.widgetFeedItemsCounterTextTotalClass);
            this.counterTextTotal.innerHTML = this.items.length;
            this.counterBtnNext = counterEl.querySelector('.' + Const.widgetFeedItemsCounterBtnNextClass);

            if (this.items.length === 4) {
                this.counterEl.classList.add("showItems4");
            } else {
                this.counterEl.classList.remove("showItems4");
            }

            if (this.items.length === 3) {
                this.counterEl.classList.add("showItems3");
            } else {
                this.counterEl.classList.remove("showItems3");
            }

            if (this.items.length === 2) {
                this.counterEl.classList.add("showItems2");
            } else {
                this.counterEl.classList.remove("showItems2");
            }

            this.counterBtnPrev.addEventListener('click', this.boundSlideLeft);
            this.counterBtnNext.addEventListener('click', this.boundSlideRight);
        }

        this.feedItems.addEventListener('scrollend', () => {
            this.updateCurrentIndex();

            if (counterEl) {
                if (this.currentIndex === 0) {
                    this.counterTextCurr.innerHTML = '1';
                } else {
                    this.counterTextCurr.innerHTML = this.currentIndex + 1;
                }   
            }
        });
    }

    updateCurrentIndex() {
        let itemWidth = this.feedItems.querySelector('.garderobo-widget-feed-item').offsetWidth;
        this.currentIndex = Math.round(this.feedItems.scrollLeft / (itemWidth + this.gap));        
    }

    checkControlsVisibility() {
        let maxScroll = this.feedItems.scrollWidth - this.feedItems.offsetWidth;
            
        if (this.feedItems.scrollLeft === 0) {
            this.leftControl.classList.add(Const.widgetHiddenClass);
        } else {
            this.leftControl.classList.remove(Const.widgetHiddenClass);
        }

        if ((Math.ceil(this.feedItems.scrollLeft) >= maxScroll) || (Math.ceil(this.feedItems.scrollLeft) >= maxScroll - 1)) {
            this.rightControl.classList.add(Const.widgetHiddenClass);
        } else {
            this.rightControl.classList.remove(Const.widgetHiddenClass);
        }           
    }

    calculateSlideWidth() {
        let itemWidth = this.feedItems.querySelector('.garderobo-widget-feed-item').offsetWidth;            
        let visibleItems = Math.floor(this.feedItems.offsetWidth / (itemWidth));

        return visibleItems * (itemWidth + this.gap);
    }

    slideRight() {
        let maxScroll = this.feedItems.scrollWidth - this.feedItems.offsetWidth;
        let slideWidth = this.calculateSlideWidth();
        
        this.currentPosition = Math.min(this.currentPosition + slideWidth, maxScroll);
        
        this.feedItems.scrollTo({
            left: this.currentPosition,
            behavior: 'smooth',
        });

        this.updateCurrentIndex();
    }

    slideLeft() {
        let slideWidth = this.calculateSlideWidth();
        this.currentPosition = Math.max(this.currentPosition - slideWidth, 0);
    
        this.feedItems.scrollTo({
            left: this.currentPosition,
            behavior: 'smooth',
        });
    
        this.updateCurrentIndex();
    
        if (this.currentPosition == 0) {
            this.leftControl.classList.add(Const.widgetHiddenClass);
        }

        this.rightControl.classList.remove(Const.widgetHiddenClass);    
    }

    getCurrentIndex() {        
        return this.currentIndex;
    }
}